import React, { useState, useEffect, useRef } from "react";
import { urls } from "./config.dev";
import FetalHeartGraph from "./FetalHeartGraph";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingSpinner from './LoadingSpinner'; // Adjust path as necessary
import VEgraph from './VEgraph'; // Import VEprompt at the beginning of the file
import MothersVitalsGraph from './MothersVitalsGraph';
import UrineAnalysisGraph from './UrineAnalysisGraph';
import GeneralMotherAssessmentPrompt from './GeneralMotherAssessmentPrompt';
import TreatmentChatModalMaternity from './TreatmentChatModalMaternity';
import PrescriptionPrompt  from './PrescriptionPrompt';
import LoadingSpinner2 from './LoadingSpinner2'; // Importing the renamed component
import SuccessMessage from './SuccessMessage';

const LaborProgressTracker = ({
  first_name,
  last_name,
  age,
  address,
  phone_number,
  clinicName,
  employeeName,
  maternity_id,
  status,
  tokenFromUrl,
  onClose
}) => {
  const [laborData, setLaborData] = useState({
    fetalMonitoring: {
      entries: [],  // Array to store heart rate entries
      date: '',      // This will store the selected date in YYYY-MM-DD format
      time: '',      // This will store the fetal heart rate entry time in HH:MM format
      heartRate: '', // Heart rate entry
    },
    cervicalDilation: {
      entries: [], // Array to store cervical dilation entries
      time: '',
      dilation: '', // Dilation in cm
      descent: '',  // Descent of the head
      membranes: '', // "Intact" or "Raptured"
      liquor: '', // Liquor comment if membranes are raptured
      moulding: '', // Moulding comment if membranes are raptured
    },
 contractions: {
    entries: [], // Array to store contraction entries
    date: '', // Date of the contraction entry (initially empty)
    time: '', // Time of contraction
    duration: '', // Duration of contraction in seconds
    intensity: '', // Intensity of contraction
  },
    mothersVitals: {
      entries: [], // Array to store mother's vitals entries
      time: '',
      bloodPressure: '',
      spo2: '',
      pulse: '',
      temperature: '',
      date: '', // Added date field to store date
    },
  });
  const [urineData, setUrineData] = useState({
    entries: [], // Array to store urine analysis entries
    time: '', // Time of entry
    volume: '', // Volume of urine (in ml)
    color: '', // Color of the urine
    odor: '', // Odor of the urine
    remarks: '', // Additional remarks about the urine
    date: '', // Date of entry
  });
  const [maternalData, setMaternalData] = useState({
    name: '',
    age: '',
    lnmp: '',
    dateOfAdmission: '',
    timeOfAdmission: '',
    gravida: '',
    para: '',
    weeksOfGestation: '',
    membranesRupturedAt: '',
    riskFactors: '',
  });
  
  const handleInputChange = (section, key, value) => {
    setLaborData((prevState) => ({
      ...prevState,
      [section]: {
        ...prevState[section],
        [key]: value,
      },
    }));
  };
  const [showAddEntryTools, setShowAddEntryTools] = useState(false);
  const [showAddVitalsTools, setShowAddVitalsTools] = useState(false);
  const [showFetalMonitoringEntryTools, setShowFetalMonitoringEntryTools] = useState(false);
  const [showGraphModal, setShowGraphModal] = useState(false); // Add this line
  const [showVEGraphModal, setShowVEGraphModal] = useState(false); // Add this line
  const [treatmentPlan, setTreatmentPlan] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const [showMotherVitalsGraph, setShowMotherVitalsGraph] = useState(false);
  const [showUrineAnalysisGraph, setShowUrineAnalysisGraph] = useState(false);
  const [showFetalHeartGraph, setShowFetalHeartGraph] = useState(false);
  const [showMothersVitalsGraph,  setShowMothersVitalsGraph] = useState(false);
  const [treatmentData, setTreatmentData] = useState([])
  const [showPrompt, setShowPrompt] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [prescriptions, setPrescriptions] = useState([]);
  
  const [showPrescriptionPrompt, setShowPrescriptionPrompt] = useState(false);
  const [isEditingComments, setIsEditingComments] = useState(false);
  const [comments, setComments] = useState([]);
  const handleTextareaClick = () => {
    if (!isEditingComments) {
      setIsEditingComments(true);
    }
  };
  
  const openPrompt = () => {
    setShowPrompt(true); // Open the prompt
  };

  const closePrompt = () => {
    setShowPrompt(false); // Close the prompt
  };
  const [showTreatmentChatModal, setShowTreatmentChatModal] = useState(false);
    const treatmentChatModalRef = useRef(null);

    const handleOpenTreatmentChatModalMaternity = () => {
        setShowTreatmentChatModal(true);
    };

    const handleCloseTreatmentChatModalMaternity = () => {
        setShowTreatmentChatModal(false);
    };

  const [addEntryState, setAddEntryState] = useState({
    isVisible: false,
    time: '',
    numContractions: 1,
    contractions: [
      { duration: '', strength: '' },
      { duration: '', strength: '' },
      { duration: '', strength: '' },
      { duration: '', strength: '' },
    ],
  });

  // Fetch labor data when the component mounts
 
 
  useEffect(() => {
    const fetchFetalVitals = async () => {
      try {
        setLoading(true); // Set loading to true at the start of data fetch
        const payload = { maternity_id, token: tokenFromUrl };

        const response = await fetch(urls.fetchLabourFetalVitals, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        });

        if (response.ok) {
          const result = await response.json();
          if (result && result.entries) {
            // Add high and low heart rate flags
            const processedEntries = result.entries.map((entry) => {
              const heartRate = Number(entry.heartRate);
              return {
                ...entry,
                lowHeartRate: heartRate < 110, // Adjust threshold as per requirements
                highHeartRate: heartRate > 160, // Adjust threshold as per requirements
              };
            });

            setLaborData((prevState) => ({
              ...prevState,
              fetalMonitoring: {
                ...prevState.fetalMonitoring,
                entries: processedEntries,
              },
            }));
          }
        } else {
          throw new Error('Failed to fetch fetal vitals.');
        }
      } catch (error) {
        console.error('Error fetching fetal vitals:', error);
      } finally {
        setLoading(false); // Set loading to false once data fetching is complete
      }
    };

    fetchFetalVitals();
  }, [maternity_id, tokenFromUrl, urls, setLaborData]);

  

// Fetch cervical dilation data
useEffect(() => {
  const fetchCervicalDilationData = async () => {
    try {
      const payload = { maternity_id, token: tokenFromUrl };

      const response = await fetch(urls.fetchlabourdilatationdata, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const data = await response.json();
        if (data && data.entries) {
          setLaborData((prevState) => ({
            ...prevState,
            cervicalDilation: {
              ...prevState.cervicalDilation,
              entries: data.entries,
            },
          }));
        }
      } else {
        throw new Error('Failed to fetch cervical dilation data.');
      }
    } catch (error) {
      console.error('Error fetching cervical dilation data:', error);
    }
  };

  fetchCervicalDilationData();
}, [maternity_id, tokenFromUrl]);

useEffect(() => {
  const fetchContractions = async () => {
    try {
      const payload = { maternity_id, token: tokenFromUrl };

      const response = await fetch(urls.fetchlabourcontractionsdata, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const data = await response.json();

        if (data && data.entries) {
          const transformedEntries = data.entries.map((entry) => ({
            date: entry.date,
            time: entry.time,
            numberOfContractions: entry.num_contractions,
            contractions: [
              { duration: entry.duration1, intensity: entry.strength1 },
              { duration: entry.duration2, intensity: entry.strength2 },
              { duration: entry.duration3, intensity: entry.strength3 },
              { duration: entry.duration4, intensity: entry.strength4 },
            ].filter((contraction) => contraction.duration && contraction.intensity),
          }));

          setLaborData((prevState) => ({
            ...prevState,
            contractions: { ...prevState.contractions, entries: transformedEntries },
          }));
        }
      } else {
        throw new Error("Failed to fetch contractions data.");
      }
    } catch (error) {
      console.error("Error fetching contractions data:", error);
    } finally {
      setLoading(false);  // Ensure loading state is set to false after fetch
    }
  };

  fetchContractions();
}, [maternity_id, tokenFromUrl, urls.fetchlabourcontractionsdata]);

// Fetch mother's vitals
useEffect(() => {
  const fetchMothersVitals = async () => {
    try {
      const payload = { maternity_id, token: tokenFromUrl };

      const response = await fetch(urls.fetchlabourmothersvitals, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const data = await response.json();
        if (data && data.entries) {
          setLaborData((prevState) => ({
            ...prevState,
            mothersVitals: {
              ...prevState.mothersVitals,
              entries: data.entries,
            },
          }));
        }
      } else {
        throw new Error('Failed to fetch mother\'s vitals.');
      }
    } catch (error) {
      console.error('Error fetching mother\'s vitals:', error);
    } finally {
      setLoading(false);
    }
  };

  fetchMothersVitals();
}, [maternity_id, tokenFromUrl, urls]);
const addFetalHeartRateEntry = async () => {
  setIsLoading(true);
  const { date, time, heartRate } = laborData.fetalMonitoring;

  // Check the heart rate status (e.g., low or high heart rate)
  const lowHeartRate = heartRate < 110;
  const highHeartRate = heartRate > 160;

  const newEntry = {
    date,
    time,
    heartRate,
    lowHeartRate,
    highHeartRate,
  };

  try {
    const payload = {
      maternity_id,
      token: tokenFromUrl,
      date,
      time,
      heartRate,
    };

    const response = await fetch(urls.AddFetalHeart, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error('Failed to add fetal heart rate entry.');
    }

    const result = await response.json();

    setLaborData((prevState) => ({
      ...prevState,
      fetalMonitoring: {
        ...prevState.fetalMonitoring,
        entries: [...prevState.fetalMonitoring.entries, newEntry],
      },
    }));

    // Show success message
    setIsSuccess(true);
    setIsLoading(false);

    // Hide the success message after 3 seconds
    setTimeout(() => {
      setIsSuccess(false);
    }, 3000);  // 3000ms = 3 seconds

    console.log('Entry added successfully:', result);
  } catch (error) {
    console.error('Error adding fetal heart rate entry:', error);
    alert('Failed to add entry. Please try again.');
    setIsLoading(false);
  }
};

const addUrineEntry = async () => {
  setIsLoading(true);
  const { date, time, volume, color, odor, remarks } = addEntryState;

  // Additional checks for volume if needed
  const lowVolume = volume < 100;
  const highVolume = volume > 1500;

  // Construct the new entry
  const newEntry = {
    date,
    time,
    volume,
    color,
    odor,
    remarks,
    lowVolume,
    highVolume,
  };

  try {
    // Check that all fields are populated before sending
    if (!date || !time || !volume || !color || !odor || !remarks) {
      throw new Error('All fields must be filled out.');
    }

    // Ensure volume is within a reasonable range
    if (volume < 0 || volume > 5000) {
      throw new Error('Invalid volume. Please enter a volume between 0 and 5000.');
    }

    // Prepare the payload
    const payload = {
      maternity_id,
      token: tokenFromUrl,
      date,
      time,
      urineVolume: volume,
      urineColor: color,
      urineOdor: odor,
      remarks,
    };

    // Make the API call to add the urine entry
    const response = await fetch(urls.AddUrineData, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error('Failed to add urine entry. Check the date and time for the new data are later than the previous data, or check your network connection.');
    }

    const result = await response.json();

    // Check for specific error message in the response body
    if (result.error && result.error === "New data must be later than the existing entry") {
      alert('The new data must be later than the existing entry. Please check the date and time.');
      return;  // Stop further processing
    }

    // Update the state with the new urine entry upon success
    setUrineData((prevState) => ({
      ...prevState,
      entries: [...prevState.entries, newEntry], // Append the new entry to the existing entries
    }));

    // Show success message
    setIsSuccess(true);
    setIsLoading(false);

    // Hide the success message after 3 seconds
    setTimeout(() => {
      setIsSuccess(false);
    }, 3000);  // 3000ms = 3 seconds

    console.log('Entry added successfully:', result);
  } catch (error) {
    console.error('Error adding urine entry:', error);
    alert(error.message || 'Failed to add entry. Please try again.');
    setIsLoading(false);
  }
};

  useEffect(() => {
    const fetchUrineData = async () => {
      try {
        const payload = { maternity_id, token: tokenFromUrl };
  
        const response = await fetch(urls.fetchurinedata, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });
  
        if (response.ok) {
          const data = await response.json();
          if (data && data.entries) {
            // Map response fields to table format
            const mappedEntries = data.entries.map((entry) => ({
              date: new Date(entry.date).toLocaleDateString(), // Format the date
              time: entry.time,
              volume: entry.urineVolume,
              color: entry.urineColor,
              odor: entry.urineOdor,
              remarks: entry.remarks || "N/A",
            }));
            
            // Update urineData state
            setUrineData((prevState) => ({
              ...prevState,
              entries: mappedEntries,
            }));
          }
        } else {
          throw new Error("Failed to fetch urine data.");
        }
    
      } catch (error) {
        console.error("Error fetching urine data:", error);
      }
    };
  
    fetchUrineData();
  }, [maternity_id, tokenFromUrl]);
  

  const addCervicalDilationEntry = async (
    date,  // The user-input date (in YYYY-MM-DD format)
    time,  // The user-input time (in HH:mm format)
    dilation,
    descent,
    membranes,
    liquor,
    moulding
  ) => {
    setIsLoading(true);  // Set loading state to true when starting the process
    setIsSuccess(false);  // Reset success state before each attempt
  
    // Convert dilation and descent to numbers
    dilation = Number(dilation);
    descent = Number(descent);
  
    // Check that dilation and descent are valid whole numbers and within the allowed ranges
    if (!Number.isInteger(dilation) || dilation < 0 || dilation > 10) {
      alert('Dilation must be a whole number between 0 and 10.');
      setIsLoading(false);  // Stop loading if validation fails
      return;
    }
  
    if (!Number.isInteger(descent) || descent < 0 || descent > 7) {
      alert('Descent must be a whole number between 0 and 7.');
      setIsLoading(false);  // Stop loading if validation fails
      return;
    }
  
    const newEntry = {
      date,      // Use the user-provided date
      time,      // Use the user-provided time
      dilation,
      descent,
      membranes,
      liquor,
      moulding,
    };
  
    try {
      // Prepare the payload with date and time sent separately
      const payload = {
        maternity_id,
        token: tokenFromUrl,
        date,      // Include date in the payload
        time,      // Include time in the payload
        dilation,
        descent,
        membranes,
        liquor,
        moulding,
      };
  
      // Make the API call
      const response = await fetch(urls.AddCervicalDilation, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
  
      if (!response.ok) {
        throw new Error('Failed to add cervical dilation entry.');
      }
  
      const result = await response.json();
  
      // Update the state with the new entry upon success
      setLaborData((prevState) => ({
        ...prevState,
        cervicalDilation: {
          ...prevState.cervicalDilation,
          entries: [...prevState.cervicalDilation.entries, newEntry],
        },
      }));
  
      // Set success state and stop loading
      setIsSuccess(true);
      setIsLoading(false);
  
      // Show success message for 3 seconds
      setTimeout(() => {
        setIsSuccess(false);
      }, 3000);
  
      console.log('Cervical dilation entry added successfully:', result);
    } catch (error) {
      console.error('Error adding cervical dilation entry:', error);
      alert('Failed to add entry. Please try again.');
      setIsLoading(false);  // Stop loading on error
    }
  };

  const addContractionEntry = async (date, time, numberOfContractions, entryDetails) => {
    setIsLoading(true);  // Set loading state to true when starting the process
    setIsSuccess(false);  // Reset success state before each attempt
  
    // Prepare the contractions data based on the number of contractions and durations
    const contractions = Array.from({ length: 4 }, (_, index) => {
      if (index < numberOfContractions) {
        return {
          duration: entryDetails[`duration${index + 1}`], // Duration for the contraction
          intensity: entryDetails[`strength${index + 1}`], // Intensity for the contraction
        };
      }
      return null; // Fill remaining contractions with null if they are not provided
    });
  
    // Create the new entry object
    const newEntry = {
      date, // Date of the contraction entry (input by the user)
      time, // Time of the contraction entry
      numberOfContractions, // Number of contractions entered
      contractions, // Array of contraction details (duration and intensity)
    };
  
    // Prepare the payload for submission
    const payload = {
      maternity_id, // Assuming maternity_id is defined elsewhere in the code
      token: tokenFromUrl, // Assuming the token is fetched from the URL query parameters
      ...newEntry, // Spread the new entry object into the payload
    };
  
    try {
      // Send the data to the backend using fetch
      const response = await fetch(urls.AddContraction, {
        method: "POST",
        headers: {
          "Content-Type": "application/json", // Set the content type as JSON
        },
        body: JSON.stringify(payload), // Convert the payload to JSON format and send it in the request body
      });
  
      // Check if the response is successful
      if (response.ok) {
        console.log("Entry added successfully.");
  
        // Update the state to reflect the new entry in the table
        setLaborData((prevData) => ({
          ...prevData,
          contractions: {
            ...prevData.contractions,
            entries: [...prevData.contractions.entries, newEntry], // Append the new entry to the existing ones
          },
        }));
  
        // Set success state and stop loading
        setIsSuccess(true);
        setIsLoading(false);
  
        // Show success message for 3 seconds
        setTimeout(() => {
          setIsSuccess(false);
        }, 3000);
      } else {
        // Log the error if the response is not successful and show an alert
        const errorMessage = await response.text();
        console.error("Failed to add entry:", errorMessage);
        alert(`Failed to add entry: ${errorMessage}`); // Display alert with the error message
        setIsLoading(false);  // Stop loading on error
      }
    } catch (error) {
      // Catch any network or other errors and log them
      console.error("Error:", error);
      alert(`Error: ${error.message}`); // Display alert with the error message
      setIsLoading(false);  // Stop loading on error
    }
  };
  
  const addMothersVitalsEntry = async (date, time, bloodPressure, spo2, pulse, temperature) => {
    setIsLoading(true);  // Set loading state to true when starting the process
    setIsSuccess(false);  // Reset success state before each attempt
  
    // Check for missing fields
    const missingFields = [];
    if (!date) missingFields.push('Date');
    if (!time) missingFields.push('Time');
    if (!bloodPressure) missingFields.push('Blood Pressure');
    if (!spo2) missingFields.push('SpO2');
    if (!pulse) missingFields.push('Pulse');
    if (!temperature) missingFields.push('Temperature');
  
    // If there are missing fields, alert the user
    if (missingFields.length > 0) {
      const missingFieldsList = missingFields.join(', ');
      const continueResponse = window.confirm(`The following fields are missing: ${missingFieldsList}. Do you still want to continue?`);
  
      if (!continueResponse) {
        alert('Entry not added. Please fill in the missing fields.');
        setIsLoading(false);  // Stop loading when the user cancels
        return;
      }
    }
  
    // Validate the date format (optional, ensure YYYY-MM-DD format)
    const isValidDate = /^\d{4}-\d{2}-\d{2}$/.test(date);
    if (!isValidDate) {
      alert('Invalid date format. Please use YYYY-MM-DD.');
      setIsLoading(false);  // Stop loading if the date is invalid
      return;
    }
  
    const newEntry = {
      date,           // Use the user-provided date
      time,
      bloodPressure,
      spo2,
      pulse,
      temperature,
      dateTime: `${date}T${time}`, // Combine user-provided date and time for record
    };
  
    try {
      // Prepare the payload with user-provided date and time
      const payload = {
        maternity_id,    // Ensure maternity_id is defined elsewhere in your code
        token: tokenFromUrl, // Ensure tokenFromUrl is extracted elsewhere in your code
        date,            // User-provided date
        time,            // User-provided time
        bloodPressure,
        spo2,
        pulse,
        temperature,
      };
  
      // Make the API call
      const response = await fetch(urls.AddMothersVitals, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
  
      if (!response.ok) {
        throw new Error('Failed to add mother\'s vitals entry.');
      }
  
      const result = await response.json();
  
      // Update the state with the new entry upon success
      setLaborData((prevState) => ({
        ...prevState,
        mothersVitals: {
          ...prevState.mothersVitals,
          entries: [...prevState.mothersVitals.entries, newEntry],
        },
      }));
  
      console.log('Mother\'s vitals entry added successfully:', result);
      setIsSuccess(true);  // Set success state to true upon successful submission
  
      // Reset success state after 3 seconds
      setTimeout(() => {
        setIsSuccess(false);
      }, 3000);
  
    } catch (error) {
      console.error('Error adding mother\'s vitals entry:', error);
      alert('Failed to add entry. Make sure the blood pressure is in the format 120/80. Also, confirm that the date and time in your data are not past the last entries of date and time. If that fails, check your internet connection. Please try again.');
    } finally {
      setIsLoading(false);  // Stop loading once the operation is complete, whether success or failure
    }
  };
  
  const getMotherVitalStyle = (vital, value) => {
    const styles = {
      padding: "10px",
      border: "1px solid #ddd",
    };
  
    switch (vital) {
      case "bloodPressure": {
        const [systolic, diastolic] = value.split("/").map(Number);
        if (systolic < 90 || diastolic < 60) return { ...styles, backgroundColor: "#ffcccc" }; // Low
        if (systolic > 140 || diastolic > 90) return { ...styles, backgroundColor: "#ffcc00" }; // High
        return { ...styles, backgroundColor: "#e0ffe0" }; // Normal
      }
      case "spo2":
        if (value < 90) return { ...styles, backgroundColor: "#ffcccc" }; // Low
        return { ...styles, backgroundColor: "#e0ffe0" }; // Normal
      case "pulse":
        if (value < 60) return { ...styles, backgroundColor: "#ffcccc" }; // Low
        if (value > 100) return { ...styles, backgroundColor: "#ffcc00" }; // High
        return { ...styles, backgroundColor: "#e0ffe0" }; // Normal
      case "temperature":
        if (value < 36) return { ...styles, backgroundColor: "#ffcccc" }; // Low
        if (value > 37.5) return { ...styles, backgroundColor: "#ffcc00" }; // High
        return { ...styles, backgroundColor: "#e0ffe0" }; // Normal
      default:
        return styles; // Default style
    }
  };
  

  const getHeartRateStatusStyle = (low, high) => {
    const backgroundColor = "black";
    if (low) return { backgroundColor, color: "red" };
    if (high) return { backgroundColor, color: "green" };
    return { backgroundColor, color: "yellow" };
  };
 
  const handleDurationChange = (index, event) => {
    const updatedContractions = [...addEntryState.contractions];
    updatedContractions[index].duration = event.target.value;
    setAddEntryState({
      ...addEntryState,
      contractions: updatedContractions,
    });
  };

  const handleStrengthChange = (index, event) => {
    const updatedContractions = [...addEntryState.contractions];
    updatedContractions[index].strength = event.target.value;
    setAddEntryState({
      ...addEntryState,
      contractions: updatedContractions,
    });
  };


  const closeButtonStyle = {
    fontSize: '14px',
    padding: '10px 20px',
    backgroundColor: '#f44336',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    marginLeft: 'auto',  // This will push it to the right
    marginRight: '0',    // Optional: ensures it sticks to the right
  };
  

 // Utility function to determine intensity based on duration
const getIntensity = (duration) => {
  if (duration < 20) {
    return "Mild";
  } else if (duration >= 20 && duration <= 40) {
    return "Moderate";
  } else {
    return "Strong";
  }
};
 // Fetch Data with useEffect
useEffect(() => {
  const fetchTreatmentData = async () => {
    setLoading(true); // Ensure spinner is shown during fetching
    try {
      const payload = { maternity_id, token: tokenFromUrl };

      const response = await fetch(urls.fetchMaternityTreatment, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const data = await response.json();
        if (data && data.entries) {
          const mappedEntries = data.entries.map((entry) => ({
            date: new Date(entry.date).toLocaleDateString(),
            treatment: entry.treatment || "N/A",
            remarks: entry.remarks || "N/A",
          }));
          setTreatmentData(mappedEntries);
        } else {
          setTreatmentData([]); // No entries
        }
      } else {
        throw new Error("Failed to fetch treatment data.");
      }
    } catch (error) {
      console.error("Error fetching treatment data:", error);
      setError("Unable to fetch treatment data.");
    } finally {
      setLoading(false); // Stop spinner
    }
  };

  fetchTreatmentData();
}, [maternity_id, tokenFromUrl]);
  useEffect(() => {
    const fetchComments = async () => {
      try {
        setLoading(true); // Start loading spinner
        const response = await fetch(urls.fetchmaternitycomments, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ maternity_id }),
        });
        if (!response.ok) throw new Error('Failed to fetch comments');
        const data = await response.json();
        if (data && data.comments && data.comments.length > 0) {
          setComments(data.comments[0].comments); // Assuming only one comment object
        } else {
          setComments(''); // No comments available
        }
      } catch (error) {
        console.error('Error fetching comments:', error);
      } finally {
        setLoading(false); // Stop loading spinner
      }
    };

   
    const fetchPrescriptions = async () => {
      try {
        const response = await fetch(urls.fetchmaternityprescriptions, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ maternity_id }),
        });

        if (!response.ok) throw new Error("Failed to fetch prescriptions");

        const data = await response.json();
        if (data && data.treatmentplans && data.treatmentplans.length > 0) {
          const formattedPlans = data.treatmentplans.map(plan =>
            plan.replace(/\u2022/g, "•")
          );
          setPrescriptions(formattedPlans);
        } else {
          setPrescriptions([]); // Handle no prescriptions
        }
      } catch (error) {
        console.error("Error fetching prescriptions:", error);
        setPrescriptions([]); // Handle error as no prescriptions
      } finally {
        setLoading(false); // Loading is complete
      }
    };
  
    const fetchTreatmentPlan = async () => {
      try {
        const response = await fetch(urls.fetchMaternityTreatment, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ maternity_id }),
        });
        if (!response.ok) throw new Error("Failed to fetch treatment plan");
        const data = await response.json();
        if (data && data.treatment_plan) setTreatmentPlan(data.treatment_plan);
      } catch (error) {
        console.error("Error fetching treatment plan:", error);
      }
    };
  
    fetchComments();
    fetchPrescriptions();
    fetchTreatmentPlan();
  }, [maternity_id, urls]);
  
  
  useEffect(() => {
    const fetchMaternalDetails = async () => {
      try {
      
  
        const response = await fetch(urls.fetchlabourdetails, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ maternity_id }), // Include maternity_id in the payload
        });
  
        const data = await response.json();
  
        if (data) {
          setMaternalData({
            name: data.name || '',
            age: data.age || '',
            lnmp: data.lnmp || '',
            dateOfAdmission: data.dateOfAdmission || '',
            timeOfAdmission: data.timeOfAdmission || '',
            gravida: data.gravida || '',
            para: data.para || '',
            weeksOfGestation: data.weeksOfGestation || '',
            membranesRupturedAt: data.membranesRupturedAt || '',
            riskFactors: data.riskFactors || '',
          });
        }
      } catch (error) {
        console.error('Error fetching maternal details:', error);
      }
    };
  
    fetchMaternalDetails();
  }, []);
  
  const handleSubmit = async () => {
  setIsLoading(true);  // Set loading state to true when starting the submission process
  setIsSuccess(false);  // Reset success state before each attempt

  // Add the maternity_id to the maternalData object
  const dataToSend = { 
    ...maternalData, 
    maternity_id: maternity_id  // Include the maternity_id here
  };

  try {
    const response = await fetch(urls.labourdetails, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(dataToSend),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const result = await response.json();

    // Check the message field instead of success
    if (result.message === 'Data inserted successfully') {
      alert('Maternal details confirmed!');
      setIsSuccess(true);  // Set success state to true upon successful submission

      // Reset success state after 3 seconds
      setTimeout(() => {
        setIsSuccess(false);
      }, 3000);
    } else {
      alert('Failed to submit maternal details.');
    }
  } catch (error) {
    console.error('Error submitting maternal details:', error);
    alert('Error submitting maternal details. Please try again.');
  } finally {
    setIsLoading(false);  // Stop loading once the operation is complete, whether success or failure
  }
};

  



  const handleUpdateComments = async () => {
    setIsLoading(true);  // Set loading state to true when starting the update process
    setIsSuccess(false);  // Reset success state before each attempt
    const response = await fetch(urls.updatematernitycomments, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('token')}` },
      body: JSON.stringify({ maternity_id, comments })
    });
  
    const data = await response.json();
    console.log(data); // Log the response to check its structure
  
    if (data.message === "Comment added successfully") {
      setIsEditingComments(false); // Turn off editing
      setIsLoading(false);
      setIsSuccess(true);  // Set success state to true upon successful update

      // Reset success state after 3 seconds
      setTimeout(() => {
        setIsSuccess(false);
      }, 3000);
    } else {
      // Handle error case
      setIsLoading(false);
      alert('Error updating comments');
    }
 
    const confirmMaternalDetails = () => {
      // Ensure `maternity_id` and `urls.labourdetails` are properly defined and accessible here
      fetch(urls.labourdetails, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          maternity_id, // Add the maternity ID to the payload
          ...maternalData, // Spread the maternalData to include all other details
        }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then((data) => {
          console.log('Maternal details updated:', data);
          alert('Maternal details successfully updated!');
        })
        .catch((error) => {
          console.error('Error updating maternal details:', error);
          alert('Failed to update maternal details. Please try again.');
        });
    };
   

   
  };
 return (
    <div style={styles.overlay}>
      <div style={styles.modal}>
      {isLoading && <LoadingSpinner2 />}
      {isSuccess && <SuccessMessage />}
      <button
          onClick={onClose}
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            padding: "5px 10px",
            backgroundColor: "transparent",
            color: "#dc3545",
            border: "none",
            fontSize: "20px",
            cursor: "pointer",
          }}
        >
          &times;
        </button>
        <h1 style={{ fontSize: '35px', textAlign: 'center', fontWeight: 'bold', textTransform: 'uppercase' }}>
  Labor Progress Tracker for {first_name} {last_name}
</h1>

      <section
      className="section maternal-info-section"
      style={{
        padding: '20px',
        margin: '20px 0',
        border: '1px solid #ddd',
        borderRadius: '5px',
        backgroundColor: '#f9f9f9',
        position: 'relative',
      }}
    >
      <h2  style={{
    padding: '20px',
    margin: '20px 0',
    border: '1px solid #ddd',
    borderRadius: '5px',
    backgroundColor: '#f9f9f9',
    textAlign: 'center',
    fontSize: '30px',
    position: 'relative',
    borderLeft: "5px solid #FF6666", // Left border added
    borderRight: "5px solid #FF6666", // Right border added
  }}>
        MATERNAL INFORMATION
      </h2>

      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
        {['name', 'age', 'lnmp', 'dateOfAdmission', 'timeOfAdmission', 'gravida', 'para', 'weeksOfGestation', 'membranesRupturedAt'].map((field, index) => (
          <div key={index} style={{ flex: '1 1 45%' }}>
            <label htmlFor={field} style={{ display: 'block', fontSize: '14px', marginBottom: '5px', color: '#555' }}>
              {field.charAt(0).toUpperCase() + field.slice(1).replace(/([A-Z])/g, ' $1')}
            </label>
            <input
              type="text"
              id={field}
              placeholder={`Enter ${field.replace(/([A-Z])/g, ' $1')}`}
              value={maternalData[field]}
              onChange={(e) => setMaternalData({ ...maternalData, [field]: e.target.value })}
              style={{
                width: '100%',
                padding: '10px',
                border: '1px solid #ddd',
                borderRadius: '5px',
                marginBottom: '10px',
              }}
            />
          </div>
        ))}
        
        <div style={{ flex: '1 1 100%' }}>
  <label
    htmlFor="riskFactors"
    style={{
      display: 'block',
      fontSize: '14px',
      marginBottom: '5px',
      color: '#555',
      fontFamily: '"Roboto", "Arial", sans-serif', // Professional font
    }}
  >
    Risk Factors
  </label>
  <textarea
    id="riskFactors"
    placeholder="Enter Risk Factors"
    value={maternalData.riskFactors}
    onChange={(e) => setMaternalData({ ...maternalData, riskFactors: e.target.value })}
    style={{
      width: '100%',
      padding: '10px',
      border: '1px solid #ddd',
      borderRadius: '5px',
      marginBottom: '10px',
      resize: 'vertical',
      fontFamily: '"Roboto", "Arial", sans-serif', // Consistent font for textarea content
      fontSize: '14px', // Ensuring the font size is professional and consistent
    }}
  />
</div>

      </div>

      <button
        onClick={handleSubmit}
        style={{
          padding: '10px 15px',
          fontSize: '14px',
          backgroundColor: '#007bff',
          color: '#fff',
          border: 'none',
          cursor: 'pointer',
          borderRadius: '5px',
          marginTop: '10px',
        }}
      >
        Confirm Maternal Details
      </button>
    </section>

{/* Fetal Monitoring Section */}
<section
  className="section fetal-monitoring-section"
  style={{
    padding: '20px',
    margin: '20px 0',
    border: '1px solid #ddd',
    borderRadius: '5px',
    backgroundColor: '#f9f9f9',
    position: 'relative',
    borderLeft: "5px solid #007BFF", // Left border added
    borderRight: "5px solid #007BFF", // Right border added
  }}
>
<h2 style={{ textAlign: 'center', fontSize: '30px', color: '#333', marginBottom: '20px' }}>
 FETAL MONITORING
</h2>

{loading ? (
  // Use the reusable spinner component
  <LoadingSpinner />
) : laborData.fetalMonitoring.entries.length > 0 ? (
  // Display table if data exists
  <table
    style={{
      width: '100%',
      borderCollapse: 'collapse',
      marginBottom: '20px',
    }}
  >
    <thead>
      <tr>
        {/* Table headers */}
        <th
          style={{
            padding: '10px',
            textAlign: 'left',
            border: '1px solid #ddd',
            backgroundColor: '#f1f1f1',
          }}
        >
          Date
        </th>
        <th
          style={{
            padding: '10px',
            textAlign: 'left',
            border: '1px solid #ddd',
            backgroundColor: '#f1f1f1',
          }}
        >
          Time
        </th>
        <th
          style={{
            padding: '10px',
            textAlign: 'left',
            border: '1px solid #ddd',
            backgroundColor: '#f1f1f1',
          }}
        >
          Fetal Heart Rate
        </th>
        <th
          style={{
            padding: '10px',
            textAlign: 'left',
            border: '1px solid #ddd',
            backgroundColor: '#f1f1f1',
          }}
        >
          Heart Rate Status
        </th>
      </tr>
    </thead>
    <tbody>
      {/* Render entries */}
      {laborData.fetalMonitoring.entries.map((entry, index) => (
        <tr
          key={index}
          style={{
            backgroundColor: index % 2 === 0 ? '#fafafa' : 'transparent',
          }}
        >
          <td
            style={{
              padding: '10px',
              border: '1px solid #ddd',
            }}
          >
            {new Date(entry.date).toLocaleDateString('en-US')}
          </td>
          <td
            style={{
              padding: '10px',
              border: '1px solid #ddd',
            }}
          >
            {entry.time}
          </td>
          <td
            style={{
              padding: '10px',
              border: '1px solid #ddd',
            }}
          >
            {entry.heartRate}
          </td>
          <td
            style={{
              padding: '10px',
              border: '1px solid #ddd',
              backgroundColor: entry.lowHeartRate
                ? '#ffcccc' // Red for low heart rate
                : entry.highHeartRate
                ? '#ffcc00' // Yellow for high heart rate
                : '#e0ffe0', // Green for normal heart rate
            }}
          >
            {entry.lowHeartRate
              ? 'Low'
              : entry.highHeartRate
              ? 'High'
              : 'Normal'}
          </td>
        </tr>
      ))}
    </tbody>
  </table>
) : (
  // Fallback if no data
  <p
    style={{
      textAlign: 'center',
      marginTop: '20px',
      fontSize: '16px',
      color: '#555',
    }}
  >
    No fetal heart rate data
  </p>
)}

  {/* Add Entry Section */}
  <div className="fetal-monitoring-input" style={{ marginTop: '20px' }}>
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '10px',
      }}
    >
      <button
        onClick={() => setShowFetalMonitoringEntryTools(!showFetalMonitoringEntryTools)}
        style={{
          padding: '10px 15px',
          fontSize: '14px',
          backgroundColor: '#007bff',
          color: '#fff',
          border: 'none',
          cursor: 'pointer',
          borderRadius: '5px',
        }}
      >
        {showFetalMonitoringEntryTools ? 'Hide FHR Addition' : '+ Add FHR'}
      </button>

      <button
        style={{
          padding: '10px 15px',
          fontSize: '14px',
          backgroundColor: '#28a745',
          color: '#fff',
          border: 'none',
          cursor: 'pointer',
          borderRadius: '5px',
        }}
        onClick={() => setShowGraphModal(true)}
      >
        📊 Open Graph
      </button>
    </div>

    {showFetalMonitoringEntryTools && (
      <>
        {/* Date Picker Input */}
        <input
          type="date"
          value={laborData.fetalMonitoring.date}
          onChange={(e) => handleInputChange('fetalMonitoring', 'date', e.target.value)}
          style={{
            width: '100%',
            padding: '10px',
            marginBottom: '10px',
            border: '1px solid #ddd',
            borderRadius: '5px',
          }}
        />
        <input
          type="time"
          placeholder="Time"
          value={laborData.fetalMonitoring.time}
          onChange={(e) => handleInputChange('fetalMonitoring', 'time', e.target.value)}
          style={{
            width: '100%',
            padding: '10px',
            marginBottom: '10px',
            border: '1px solid #ddd',
            borderRadius: '5px',
          }}
        />
        <input
          type="number"
          placeholder="Fetal Heart Rate"
          value={laborData.fetalMonitoring.heartRate}
          onChange={(e) => handleInputChange('fetalMonitoring', 'heartRate', e.target.value)}
          style={{
            width: '100%',
            padding: '10px',
            marginBottom: '10px',
            border: '1px solid #ddd',
            borderRadius: '5px',
          }}
        />
        <button
          onClick={() => {
            addFetalHeartRateEntry(
              laborData.fetalMonitoring.date,
              laborData.fetalMonitoring.time,
              laborData.fetalMonitoring.heartRate
            );
            setLaborData((prevState) => ({
              ...prevState,
              fetalMonitoring: {
                ...prevState.fetalMonitoring,
                date: '',
                time: '',
                heartRate: '',
              },
            }));
            setShowFetalMonitoringEntryTools(false);
          }}
          style={{
            padding: '10px 15px',
            fontSize: '14px',
            backgroundColor: '#28a745',
            color: '#fff',
            border: 'none',
            cursor: 'pointer',
            borderRadius: '5px',
            marginTop: '10px',
          }}
        >
          Add Entry
        </button>
      </>
    )}

    {/* Graph Modal */}
    {showGraphModal && (
      <FetalHeartGraph
        entries={laborData.fetalMonitoring.entries}
        onClose={() => setShowGraphModal(false)}
      />
    )}
  </div>
</section>


<section className="section"  style={{
    padding: '20px',
    margin: '20px 0',
    border: '1px solid #ddd',
    borderRadius: '5px',
    backgroundColor: '#f9f9f9',
    position: 'relative',
    borderLeft: "5px solid #FF7F00", // Left border added
    borderRight: "5px solid #FF7F00", // Right border added
  }}>
    <h2 style={{ textAlign: 'center', fontSize: '30px', color: '#333', marginBottom: '20px' }}>CERVICAL DILATION</h2>

    {/* Show LoadingSpinner while fetching data */}
    {loading ? (
      <div style={{ textAlign: 'center' }}>
        <LoadingSpinner /> {/* Display the spinner */}
      </div>
    ) : laborData.cervicalDilation.entries.length === 0 ? (
      <p style={{ textAlign: 'center', fontSize: '18px', color: '#888' }}>No cervical dilation data yet</p>
    ) : (


      <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '20px' }}>
        <thead>
          <tr>
            <th style={{ padding: '10px', textAlign: 'left', border: '1px solid #ddd', backgroundColor: '#f1f1f1' }}>Date</th>
            <th style={{ padding: '10px', textAlign: 'left', border: '1px solid #ddd', backgroundColor: '#f1f1f1' }}>Time</th>
            <th style={{ padding: '10px', textAlign: 'left', border: '1px solid #ddd', backgroundColor: '#f1f1f1' }}>Cervical Dilation (cm)</th>
            <th style={{ padding: '10px', textAlign: 'left', border: '1px solid #ddd', backgroundColor: '#f1f1f1' }}>Descent of the Head</th>
            <th style={{ padding: '10px', textAlign: 'left', border: '1px solid #ddd', backgroundColor: '#f1f1f1' }}>Membranes</th>
            <th style={{ padding: '10px', textAlign: 'left', border: '1px solid #ddd', backgroundColor: '#f1f1f1' }}>Liquor</th>
            <th style={{ padding: '10px', textAlign: 'left', border: '1px solid #ddd', backgroundColor: '#f1f1f1' }}>Moulding</th>
          </tr>
        </thead>
        <tbody>
          {laborData.cervicalDilation.entries.map((entry, index) => (
            <tr key={index} style={{ backgroundColor: index % 2 === 0 ? '#fafafa' : 'transparent' }}>
              <td style={{ padding: '10px', border: '1px solid #ddd' }}>
                {new Date(entry.date).toISOString().split('T')[0]} {/* Format date */}
              </td>
              <td style={{ padding: '10px', border: '1px solid #ddd' }}>{entry.time}</td>
              <td style={{ padding: '10px', border: '1px solid #ddd' }}>{entry.dilation} cm</td>
              <td style={{ padding: '10px', border: '1px solid #ddd' }}>{entry.descent}</td>
              <td style={{ padding: '10px', border: '1px solid #ddd' }}>{entry.membranes}</td>
              <td style={{ padding: '10px', border: '1px solid #ddd' }}>
                {entry.membranes === "Raptured" ? entry.liquor : '-'}
              </td>
              <td style={{ padding: '10px', border: '1px solid #ddd' }}>
                {entry.membranes === "Raptured" ? entry.moulding : '-'}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    )}

    <div className="cervical-dilation-input" style={{ marginTop: '20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <button
        onClick={() => setShowAddEntryTools(!showAddEntryTools)}
        style={{
          padding: '10px 15px',
          fontSize: '14px',
          backgroundColor: '#007bff',
          color: '#fff',
          border: 'none',
          cursor: 'pointer',
          borderRadius: '5px',
        }}
      >
        {showAddEntryTools ? "Hide Addition of VE" : "+ Add VE Results"}
      </button>

  <button
    onClick={() => setShowVEGraphModal(true)}
    style={{
      padding: '10px 15px',
      fontSize: '14px',
      backgroundColor: '#28a745', // Updated to the new green color
      color: '#fff',
      border: 'none',
      cursor: 'pointer',
      borderRadius: '5px',
      marginLeft: '20px', // Adds space between the button and the other elements
    }}
  >
    📊 Open Graph
  </button>
</div>

  {showAddEntryTools && (
    <>
   <input
  type="date"
  placeholder="date"
  value={laborData.cervicalDilation.date ? laborData.cervicalDilation.date.split('T')[0] : ''} // Format the date
  onChange={(e) => handleInputChange("cervicalDilation", "date", e.target.value)}
  style={{
    width: '100%',
    padding: '10px',
    marginBottom: '10px',
    border: '1px solid #ddd',
    borderRadius: '5px',
  }}
/>

      <input
    
    type="time"
        placeholder="Time"
        value={laborData.cervicalDilation.time}
        onChange={(e) => handleInputChange("cervicalDilation", "time", e.target.value)}
        style={{
          width: '100%',
          padding: '10px',
          marginBottom: '10px',
          border: '1px solid #ddd',
          borderRadius: '5px',
        }}
      />
      <input
        type="number"
        placeholder="Cervical Dilation (cm)"
        value={laborData.cervicalDilation.dilation}
        onChange={(e) => handleInputChange("cervicalDilation", "dilation", e.target.value)}
        style={{
          width: '100%',
          padding: '10px',
          marginBottom: '10px',
          border: '1px solid #ddd',
          borderRadius: '5px',
        }}
      />
      <input
        type="text"
        placeholder="Descent of the Head"
        value={laborData.cervicalDilation.descent}
        onChange={(e) => handleInputChange("cervicalDilation", "descent", e.target.value)}
        style={{
          width: '100%',
          padding: '10px',
          marginBottom: '10px',
          border: '1px solid #ddd',
          borderRadius: '5px',
        }}
      />
      <select
        value={laborData.cervicalDilation.membranes}
        onChange={(e) => handleInputChange("cervicalDilation", "membranes", e.target.value)}
        style={{
          width: '100%',
          padding: '10px',
          marginBottom: '10px',
          border: '1px solid #ddd',
          borderRadius: '5px',
        }}
      >
        <option value="">Select Membranes</option>
        <option value="Intact">Intact</option>
        <option value="Raptured">Raptured</option>
      </select>

      {laborData.cervicalDilation.membranes === "Raptured" && (
        <>
          <input
            type="text"
            placeholder="Liquor"
            value={laborData.cervicalDilation.liquor}
            onChange={(e) => handleInputChange("cervicalDilation", "liquor", e.target.value)}
            style={{
              width: '100%',
              padding: '10px',
              marginBottom: '10px',
              border: '1px solid #ddd',
              borderRadius: '5px',
            }}
          />
          <input
            type="text"
            placeholder="Moulding"
            value={laborData.cervicalDilation.moulding}
            onChange={(e) => handleInputChange("cervicalDilation", "moulding", e.target.value)}
            style={{
              width: '100%',
              padding: '10px',
              marginBottom: '10px',
              border: '1px solid #ddd',
              borderRadius: '5px',
            }}
          />
        </>
      )}

      <button
        onClick={() => {
          addCervicalDilationEntry(
            laborData.cervicalDilation.date,
            laborData.cervicalDilation.time,
            laborData.cervicalDilation.dilation,
            laborData.cervicalDilation.descent,
            laborData.cervicalDilation.membranes,
            laborData.cervicalDilation.liquor,
            laborData.cervicalDilation.moulding
          );
          // Clear inputs after adding entry
          setLaborData((prevState) => ({
            ...prevState,
            cervicalDilation: {
              ...prevState.cervicalDilation,
              time: '',
              dilation: '',
              descent: '',
              membranes: '',
              liquor: '',
              moulding: '',
            },
          }));
          // Hide input fields
          setShowAddEntryTools(false);
        }}
        style={{
          padding: '10px 15px',
          fontSize: '14px',
          backgroundColor: '#28a745',
          color: '#fff',
          border: 'none',
          cursor: 'pointer',
          borderRadius: '5px',
          marginTop: '10px',
        }}
      >
        Add Entry
      </button>
    </>
  )}

{/* Graph Modal */}
{showVEGraphModal && (
  <>
    {console.log(
      'Data sent to VEgraph:',
      laborData.cervicalDilation.entries.map((entry) => ({
        ...entry,
        date: new Date(entry.date).toISOString().split('T')[0], // Format date
      }))
    )}
    <VEgraph
      entries={laborData.cervicalDilation.entries.map((entry) => ({
        ...entry,
        date: new Date(entry.date).toISOString().split('T')[0], // Format date
      }))} // Pass accurate data with formatted dates
      onClose={() => setShowVEGraphModal(false)}
    />
  </>
)}


</section>
<section
  className="section"
  style={{
    padding: '20px',
    margin: '20px 0',
    border: '1px solid #ddd',
    borderRadius: '5px',
    backgroundColor: '#f9f9f9',
    position: 'relative',
    borderLeft: "5px solid #007BFF", // Left border added
    borderRight: "5px solid #007BFF", // Right border added
  }}
>
  <h2
    style={{
      textAlign: "center",
      fontSize: "30px",
      color: "#333",
      marginBottom: "20px",
    }}
  >
    CONTRACTIONS
  </h2>

  {loading ? (
    <LoadingSpinner /> // Show loading spinner if loading
  ) : laborData.contractions.entries.length === 0 ? (
    <p style={{ textAlign: "center", color: "#888" }}>No contractions data yet</p> // Show no data message if there are no entries
  ) : (
    <table
      style={{
        width: "100%",
        borderCollapse: "collapse",
        marginBottom: "20px",
      }}
    >
      <thead>
        <tr>
          <th
            style={{
              padding: "10px",
              textAlign: "left",
              border: "1px solid #ddd",
              backgroundColor: "#f1f1f1",
            }}
          >
            Date
          </th>
          <th
            style={{
              padding: "10px",
              textAlign: "left",
              border: "1px solid #ddd",
              backgroundColor: "#f1f1f1",
            }}
          >
            Time
          </th>
          <th
            style={{
              padding: "10px",
              textAlign: "left",
              border: "1px solid #ddd",
              backgroundColor: "#f1f1f1",
            }}
          >
            No. of Contractions
          </th>
          <th
            colSpan={4}
            style={{
              padding: "10px",
              textAlign: "left",
              border: "1px solid #ddd",
              backgroundColor: "#f1f1f1",
            }}
          >
            Contraction Details
          </th>
        </tr>
      </thead>
      <tbody>
        {laborData.contractions.entries.map((entry, index) => {
          // Get the time and add 10 minutes
          const entryTime = new Date(`1970-01-01T${entry.time}:00Z`); // Assume entry.time is in HH:MM format
          const newTime = new Date(entryTime.getTime() + 10 * 60 * 1000); // Add 10 minutes
          
          // Format the time back to HH:MM (24-hour format)
          const formatTime24 = (time) => {
            let hours = time.getHours();
            let minutes = time.getMinutes();
            minutes = minutes < 10 ? '0' + minutes : minutes;
            return `${hours}:${minutes}`; // 24-hour format
          };

          return (
            <tr
              key={index}
              style={{
                backgroundColor: index % 2 === 0 ? "#fafafa" : "transparent",
              }}
            >
              <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                {entry.date}
              </td>
              <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                {formatTime24(entryTime)} to {formatTime24(newTime)} {/* Time range in 24-hour format */}
              </td>
              <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                {entry.numberOfContractions} {entry.numberOfContractions === 1 ? "contraction" : "contractions"} {/* Singular/plural */}
              </td>
              {Array.from({ length: 4 }, (_, contractionIndex) => {
                const contraction = entry.contractions[contractionIndex];
                if (contraction) {
                  // Define color based on intensity
                  let intensityColor = "";
                  switch (contraction.intensity.toLowerCase()) {
                    case "mild":
                      intensityColor = "#a8d08d"; // light green for mild
                      break;
                    case "moderate":
                      intensityColor = "#ffeb3b"; // yellow for moderate
                      break;
                    case "strong":
                      intensityColor = "#f44336"; // red for strong
                      break;
                    default:
                      intensityColor = "transparent";
                      break;
                  }

                  return (
                    <td
                      key={contractionIndex}
                      style={{
                        padding: "10px",
                        border: "1px solid #ddd",
                        backgroundColor: intensityColor,
                      }}
                    >
                      <strong>Contraction {contractionIndex + 1}:</strong><br />
                      <strong>Duration:</strong> {contraction.duration}s <br />
                      <strong>Intensity:</strong> {contraction.intensity}
                    </td>
                  );
                }
                return (
                  <td
                    key={contractionIndex}
                    style={{
                      padding: "10px",
                      border: "1px solid #ddd",
                      backgroundColor: "#000",
                    }}
                  />
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  )}

<div className="contraction-input" style={{ marginTop: "20px" }}>
  <button
    onClick={() =>
      setAddEntryState((prevState) => ({
        ...prevState,
        isVisible: !prevState.isVisible, // This toggles the visibility
      }))
    }
    style={{
      padding: "10px 15px",
      fontSize: "14px",
      backgroundColor: "#007bff",
      color: "#fff",
      border: "none",
      cursor: "pointer",
      borderRadius: "5px",
    }}
  >
    {addEntryState.isVisible ? "Hide Add Entry" : "+ Add New Entry"}
  </button>

  {addEntryState.isVisible && (
    <>
      <input
        type="date"
        value={addEntryState.date}
        onChange={(e) =>
          setAddEntryState((prevState) => ({
            ...prevState,
            date: e.target.value,
          }))
        }
        style={{
          width: "100%",
          padding: "10px",
          marginBottom: "10px",
          border: "1px solid #ddd",
          borderRadius: "5px",
        }}
      />
      <input
        type="time"
        placeholder="Time"
        value={addEntryState.time}
        onChange={(e) =>
          setAddEntryState((prevState) => ({
            ...prevState,
            time: e.target.value,
          }))
        }
        style={{
          width: "100%",
          padding: "10px",
          marginBottom: "10px",
          border: "1px solid #ddd",
          borderRadius: "5px",
        }}
      />
      <select
        value={addEntryState.numberOfContractions}
        onChange={(e) =>
          setAddEntryState((prevState) => ({
            ...prevState,
            numberOfContractions: e.target.value,
          }))
        }
        style={{
          width: "100%",
          padding: "10px",
          marginBottom: "10px",
          border: "1px solid #ddd",
          borderRadius: "5px",
        }}
      >
        <option value="">Select the number of contractions</option>
        <option value="1">1 Contraction</option>
        <option value="2">2 Contractions</option>
        <option value="3">3 Contractions</option>
        <option value="4">4 Contractions</option>
      </select>

      {Array.from({ length: addEntryState.numberOfContractions || 0 }).map(
        (_, contractionIndex) => (
          <div key={contractionIndex}>
            <input
              type="number"
              placeholder={`Contraction ${contractionIndex + 1} Duration`}
              value={addEntryState[`duration${contractionIndex + 1}`]}
              onChange={(e) => {
                const duration = e.target.value;
                const intensity = getIntensity(duration);
                setAddEntryState((prevState) => ({
                  ...prevState,
                  [`duration${contractionIndex + 1}`]: duration,
                  [`strength${contractionIndex + 1}`]: intensity,
                }));
              }}
              style={{
                width: "45%",
                padding: "10px",
                marginBottom: "10px",
                border: "1px solid #ddd",
                borderRadius: "5px",
              }}
            />
            <select
              value={addEntryState[`strength${contractionIndex + 1}`]}
              onChange={(e) =>
                setAddEntryState((prevState) => ({
                  ...prevState,
                  [`strength${contractionIndex + 1}`]: e.target.value,
                }))
              }
              style={{
                width: "45%",
                padding: "10px",
                marginBottom: "10px",
                border: "1px solid #ddd",
                borderRadius: "5px",
                pointerEvents: "none", // Disable manual change
              }}
            >
              <option value="Mild">Mild (less than 20 sec)</option>
              <option value="Moderate">Moderate (20 to 40 sec)</option>
              <option value="Strong">Strong (more than 40 sec)</option>
            </select>
          </div>
        )
      )}

<button
        onClick={() => {
          addContractionEntry(
            addEntryState.date,
            addEntryState.time,
            addEntryState.numberOfContractions,
            addEntryState
          );
          setAddEntryState((prevState) => ({
            ...prevState,
            isVisible: false, // Hide the entry tools after saving
          }));
        }}
        style={{
          padding: "10px 15px",
          fontSize: "14px",
          backgroundColor: "#28a745",
          color: "#fff",
          border: "none",
          cursor: "pointer",
          borderRadius: "5px",
        }}
      >
        Save Contractions data
      </button>
    </>
  )}
</div>

</section>


{/* Mother's Vitals Section */}
{/* Mother's Vitals Section */}
<section
  className="section"
  style={{
    padding: '20px',
    margin: '20px 0',
    border: '1px solid #ddd',
    borderRadius: '5px',
    backgroundColor: '#f9f9f9',
    position: 'relative',
    borderLeft: "5px solid #FF7F00", // Left border added
    borderRight: "5px solid #FF7F00", // Right border added
  }}
>
  <h2
    style={{
      textAlign: 'center',
      fontSize: '30px',
      color: '#333',
      marginBottom: '20px',
    }}
  >
    MOTHER'S VITALS
  </h2>

  {/* Show loading spinner if data is loading */}
  {loading ? (
    <LoadingSpinner />
  ) : laborData.mothersVitals.entries.length === 0 ? (
    <div style={{ textAlign: 'center', fontSize: '18px', color: '#888' }}>
      No mother's vitals measured yet.
    </div>
  ) : (
    // Vitals Table
    <table
      style={{
        width: '100%',
        borderCollapse: 'collapse',
        marginBottom: '20px',
      }}
    >
      <thead>
        <tr>
          <th
            style={{
              padding: '10px',
              textAlign: 'left',
              border: '1px solid #ddd',
              backgroundColor: '#f1f1f1',
            }}
          >
            Date
          </th>
          <th
            style={{
              padding: '10px',
              textAlign: 'left',
              border: '1px solid #ddd',
              backgroundColor: '#f1f1f1',
            }}
          >
            Time
          </th>
          <th
            style={{
              padding: '10px',
              textAlign: 'left',
              border: '1px solid #ddd',
              backgroundColor: '#f1f1f1',
            }}
          >
            Blood Pressure
          </th>
          <th
            style={{
              padding: '10px',
              textAlign: 'left',
              border: '1px solid #ddd',
              backgroundColor: '#f1f1f1',
            }}
          >
            SpO2
          </th>
          <th
            style={{
              padding: '10px',
              textAlign: 'left',
              border: '1px solid #ddd',
              backgroundColor: '#f1f1f1',
            }}
          >
            Pulse
          </th>
          <th
            style={{
              padding: '10px',
              textAlign: 'left',
              border: '1px solid #ddd',
              backgroundColor: '#f1f1f1',
            }}
          >
            Temperature
          </th>
        </tr>
      </thead>
      <tbody>
        {laborData.mothersVitals.entries.map((entry, index) => (
          <tr
            key={index}
            style={{
              backgroundColor: index % 2 === 0 ? '#fafafa' : 'transparent',
            }}
          >
            <td>{entry.date}</td>
            <td style={getMotherVitalStyle('time', entry.time)}>{entry.time}</td>
            <td style={getMotherVitalStyle('bloodPressure', entry.bloodPressure)}>{entry.bloodPressure}</td>
            <td style={getMotherVitalStyle('spo2', entry.spo2)}>{entry.spo2}</td>
            <td style={getMotherVitalStyle('pulse', entry.pulse)}>{entry.pulse}</td>
            <td style={getMotherVitalStyle('temperature', entry.temperature)}>{entry.temperature}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )}

  {/* Add Entry Section */}
  <div className="mothers-vitals-input" style={{ marginTop: '20px' }}>
    <button
      onClick={() => setShowAddVitalsTools(!showAddVitalsTools)}
      style={{
        padding: '10px 15px',
        fontSize: '14px',
        backgroundColor: '#007bff',
        color: '#fff',
        border: 'none',
        cursor: 'pointer',
        borderRadius: '5px',
      }}
    >
      {showAddVitalsTools ? 'Hide Vitals Addition' : '+ Add New Vitals'}
    </button>

    {/* Add Entry Form (Show when toggled) */}
    {showAddVitalsTools && (
      <>
        {/* Date Input */}
        <input
          type="date"
          placeholder="Date"
          value={laborData.mothersVitals.date}
          onChange={(e) => handleInputChange('mothersVitals', 'date', e.target.value)}
          style={{
            width: '100%',
            padding: '10px',
            marginBottom: '10px',
            border: '1px solid #ddd',
            borderRadius: '5px',
          }}
        />
        {/* Time Input */}
        <input
          type="time"
          placeholder="Time"
          value={laborData.mothersVitals.time}
          onChange={(e) => handleInputChange('mothersVitals', 'time', e.target.value)}
          style={{
            width: '100%',
            padding: '10px',
            marginBottom: '10px',
            border: '1px solid #ddd',
            borderRadius: '5px',
          }}
        />
        <input
          type="text"
          placeholder="Blood Pressure"
          value={laborData.mothersVitals.bloodPressure}
          onChange={(e) => handleInputChange('mothersVitals', 'bloodPressure', e.target.value)}
          style={{
            width: '100%',
            padding: '10px',
            marginBottom: '10px',
            border: '1px solid #ddd',
            borderRadius: '5px',
          }}
        />
        <input
          type="text"
          placeholder="SpO2"
          value={laborData.mothersVitals.spo2}
          onChange={(e) => handleInputChange('mothersVitals', 'spo2', e.target.value)}
          style={{
            width: '100%',
            padding: '10px',
            marginBottom: '10px',
            border: '1px solid #ddd',
            borderRadius: '5px',
          }}
        />
        <input
          type="number"
          placeholder="Pulse"
          value={laborData.mothersVitals.pulse}
          onChange={(e) => handleInputChange('mothersVitals', 'pulse', e.target.value)}
          style={{
            width: '100%',
            padding: '10px',
            marginBottom: '10px',
            border: '1px solid #ddd',
            borderRadius: '5px',
          }}
        />
        <input
          type="number"
          placeholder="Temperature"
          value={laborData.mothersVitals.temperature}
          onChange={(e) => handleInputChange('mothersVitals', 'temperature', e.target.value)}
          style={{
            width: '100%',
            padding: '10px',
            marginBottom: '10px',
            border: '1px solid #ddd',
            borderRadius: '5px',
          }}
        />

        {/* Add Entry Button */}
        <button
          onClick={() => {
            addMothersVitalsEntry(
              laborData.mothersVitals.date,
              laborData.mothersVitals.time,
              laborData.mothersVitals.bloodPressure,
              laborData.mothersVitals.spo2,
              laborData.mothersVitals.pulse,
              laborData.mothersVitals.temperature
            );
            // Clear inputs after adding entry
            setLaborData((prevState) => ({
              ...prevState,
              mothersVitals: {
                ...prevState.mothersVitals,
                date: '',
                time: '',
                bloodPressure: '',
                spo2: '',
                pulse: '',
                temperature: '',
              },
            }));
            // Hide input fields
            setShowAddVitalsTools(false);
          }}
          style={{
            padding: '10px 15px',
            fontSize: '14px',
            backgroundColor: '#28a745',
            color: '#fff',
            border: 'none',
            cursor: 'pointer',
            borderRadius: '5px',
            marginTop: '10px',
          }}
        >
          Add Mother Vital
        </button>
      </>
    )}
  </div>

  {/* Graph Icon */}
  <div
    style={{
      position: 'absolute',
      bottom: '10px',
      right: '10px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      gap: '10px',
    }}
  >
    <button
      style={{
        padding: '10px 15px',
        fontSize: '14px',
        backgroundColor: '#28a745',
        color: '#fff',
        border: 'none',
        cursor: 'pointer',
        borderRadius: '5px',
      }}
      onClick={() => {
        console.log('Opening graph with data:', laborData.mothersVitals.entries);
        setShowMothersVitalsGraph(true);
      }}
    >
      📊 Open Graph
    </button>
  </div>

  {/* Graph Modal */}
  {showMothersVitalsGraph && (
    <MothersVitalsGraph
      entries={laborData.mothersVitals.entries}
      onClose={() => setShowMothersVitalsGraph(false)}
    />
  )}
</section>

<section
  className="section"
  style={{
    padding: '20px',
    margin: '20px 0',
    border: '1px solid #ddd',
    borderRadius: '5px',
    backgroundColor: '#f9f9f9',
    position: 'relative',
    borderLeft: "5px solid #007BFF", // Left border added
    borderRight: "5px solid #007BFF", // Right border added
  }}
>
  <h2
    style={{
      textAlign: "center",
      fontSize: "30px",
      color: "#333",
      marginBottom: "20px",
      fontWeight: "bold", // Added to make the text bold
      
    }}
  >
    URINE OUTPUT AND ANALYSIS
  </h2>

  {loading ? (
  // Display loading spinner while fetching data
  <div style={{ textAlign: "center", margin: "20px 0" }}>
    <LoadingSpinner />
  </div>
) : (
  // Display the table after data is fetched
  <table
    style={{
      width: "100%",
      borderCollapse: "collapse",
      marginBottom: "20px",
    }}
  >
    {urineData?.entries?.length > 0 ? (
      <>
        <thead>
          <tr>
            <th
              style={{
                padding: "10px",
                textAlign: "left",
                border: "1px solid #ddd",
                backgroundColor: "#f1f1f1",
              }}
            >
              Date
            </th>
            <th
              style={{
                padding: "10px",
                textAlign: "left",
                border: "1px solid #ddd",
                backgroundColor: "#f1f1f1",
              }}
            >
              Time
            </th>
            <th
              style={{
                padding: "10px",
                textAlign: "left",
                border: "1px solid #ddd",
                backgroundColor: "#f1f1f1",
              }}
            >
              Volume (ml)
            </th>
            <th
              style={{
                padding: "10px",
                textAlign: "left",
                border: "1px solid #ddd",
                backgroundColor: "#f1f1f1",
              }}
            >
              Color
            </th>
            <th
              style={{
                padding: "10px",
                textAlign: "left",
                border: "1px solid #ddd",
                backgroundColor: "#f1f1f1",
              }}
            >
              Odor
            </th>
            <th
              style={{
                padding: "10px",
                textAlign: "left",
                border: "1px solid #ddd",
                backgroundColor: "#f1f1f1",
              }}
            >
              Remarks
            </th>
          </tr>
        </thead>
        <tbody>
          {urineData.entries.map((entry, index) => {
            const startTime = new Date(`1970-01-01T${entry.time}Z`);
            const formattedTime = startTime.toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            });

            const getColorBackground = (color) => {
              switch (color.toLowerCase()) {
                case "red":
                  return "#ff4d4d";
                case "amber":
                  return "#ffbf00";
                case "dark yellow":
                  return "#cc9900";
                case "pale yellow":
                  return "#ffff66";
                case "clear":
                  return "#ffffff";
                default:
                  return "#fafafa";
              }
            };

            return (
              <tr
                key={index}
                style={{
                  backgroundColor:
                    index % 2 === 0 ? "#fafafa" : "transparent",
                }}
              >
                <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                  {entry.date}
                </td>
                <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                  {formattedTime}
                </td>
                <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                  {entry.volume} ml
                </td>
                <td
                  style={{
                    padding: "10px",
                    border: "1px solid #ddd",
                    backgroundColor: getColorBackground(entry.color),
                  }}
                >
                  {entry.color}
                </td>
                <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                  {entry.odor}
                </td>
                <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                  {entry.remarks}
                </td>
              </tr>
            );
          })}
        </tbody>
      </>
    ) : (
      <tbody>
        <tr>
          <td colSpan="6" style={{ textAlign: "center", padding: "10px" }}>
            No data available
          </td>
        </tr>
      </tbody>
    )}
  </table>
)}

  

  
<div className="urine-input" style={{ marginTop: "20px" }}>
    <button
      onClick={() =>
        setAddEntryState((prevState) => ({
          ...prevState,
          isVisible: !prevState.isVisible,
        }))
      }
      style={{
        padding: "10px 15px",
        fontSize: "14px",
        backgroundColor: "#007bff",

        color: "#fff",
        border: "none",
        cursor: "pointer",
        borderRadius: "5px",
        
      }}
    >
      {addEntryState.isVisible ? "Hide Add Entry" : "+ Add New Entry"}
    </button>

    {addEntryState.isVisible && (
      <>
        <input
          type="date"
          placeholder="Date"
          value={addEntryState.date}
          onChange={(e) =>
            setAddEntryState((prevState) => ({
              ...prevState,
              date: e.target.value,
            }))
          }
          style={{
            width: "100%",
            padding: "10px",
            marginBottom: "10px",
            border: "1px solid #ddd",
           
          }}
        />
        <input
          type="time"
          placeholder="Time"
          value={addEntryState.time}
          onChange={(e) =>
            setAddEntryState((prevState) => ({
              ...prevState,
              time: e.target.value,
            }))
          }
          style={{
            width: "100%",
            padding: "10px",
            marginBottom: "10px",
            border: "1px solid #ddd",
            borderRadius: "5px",
          }}
        />
        <input
          type="number"
          placeholder="Volume (ml)"
          value={addEntryState.volume}
          onChange={(e) =>
            setAddEntryState((prevState) => ({
              ...prevState,
              volume: e.target.value,
            }))
          }
          style={{
            width: "100%",
            padding: "10px",
            marginBottom: "10px",
            border: "1px solid #ddd",
            borderRadius: "5px",
          }}
        />

        {/* Color Dropdown */}
        <select
          value={addEntryState.color}
          onChange={(e) =>
            setAddEntryState((prevState) => ({
              ...prevState,
              color: e.target.value,
            }))
          }
          style={{
            width: "100%",
            padding: "10px",
            marginBottom: "10px",
            border: "1px solid #ddd",
            borderRadius: "5px",
          }}
        >
          <option value="">Select Color</option>
          <option value="Clear">Clear</option>
          <option value="Pale Yellow">Pale Yellow</option>

          <option value="Amber">Amber</option>
          <option value="Dark Yellow">Dark Yellow</option>
          <option value="Red">Red (Possible Blood)</option>
        </select>

        
        {/* Odor Dropdown */}
        <select
          value={addEntryState.odor}
          onChange={(e) =>
           
            setAddEntryState((prevState) => ({
              ...prevState,
              odor: e.target.value,
            }))
          }
          style={{
            width: "100%",
            padding: "10px",
            marginBottom: "10px",
            border: "1px solid #ddd",
            borderRadius: "5px",
          }}
        >
          <option value="">Select Odor</option>
          <option value="Normal">Normal</option>
          <option value="Strong">Strong</option>
          <option value="Sweet">Sweet (Possible Diabetes)</option>
          <option value="Ammonia">Ammonia (Possible Dehydration)</option>
        </select>

        {/* Remarks Textarea */}
        <textarea
          placeholder="Enter any abnormal findings or notes (e.g., dehydration signs, protein in urine, ketones in urine , leucocytes in urine , etc.)"
          value={addEntryState.remarks}
          onChange={(e) =>
            setAddEntryState((prevState) => ({
              ...prevState,
              remarks: e.target.value,
            }))
          }
         
          style={{
            width: "100%",
            
            padding: "10px",
            marginBottom: "10px",
            border: "1px solid #ddd",
            borderRadius: "5px",
          }}
        />

<button
  onClick={() => {
    // Call the addUrineEntry function
    addUrineEntry(
      addEntryState.date,
      addEntryState.time,
      addEntryState.volume,
      addEntryState.color,
      addEntryState.odor,
      addEntryState.remarks
    );

    // After adding the entry, hide the form and reset the state
    setAddEntryState({
      isVisible: false,
      date: '',
      time: '',
      volume: '',
      color: '',
      odor: '',
      remarks: '',
    });
  }}
  style={{
    padding: "10px 15px",
    fontSize: "14px",
    backgroundColor: "#28a745",
    color: "#fff",
    border: "none",
    cursor: "pointer",
    borderRadius: "5px",
  }}
>
  Add Urine Entry
</button>

      </>
    )}
     </div>
   {/* Graph Icon */}
   <div
    style={{
      bottom: '10px',
      right: '10px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      gap: '10px',
    }}
  >
    <button
      style={{
        padding: '10px 15px',
        fontSize: '14px',
        backgroundColor: '#28a745',
        color: '#fff',
        border: 'none',
        cursor: 'pointer',
        borderRadius: '5px',
      }}
      onClick={() => {
        console.log('Opening urine graph with data:', urineData.entries);
        setShowUrineAnalysisGraph(true);
      }}
    >
      📊 Open Urine Graph
    </button>
  </div>

  {/* Graph Modal */}
  {showUrineAnalysisGraph && (
    <>
      {console.log('Data sent to UrineAnalysisGraph:', urineData.entries)}
      <UrineAnalysisGraph
        entries={urineData.entries}
        onClose={() => setShowUrineAnalysisGraph(false)}
      />
    </>
  )}
</section>

<section
  className="section"
  style={{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflowY: "auto",
  }}
>
  <div
    style={{
      fontFamily: "Arial, sans-serif",
      margin: "0",
      padding: "30px",
      backgroundColor: "#fafafa",
      borderRadius: "10px",
      boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
      width: "100%",
      maxWidth: "100vw",
      overflowX: "hidden",
      borderLeft: "5px solid #FF7F00",
      borderRight: "5px solid #FF7F00",
    }}
  >
    <h3
      style={{
        marginBottom: "10px",
        color: "#333",
        fontSize: "30px",
        textAlign: "center",
        textTransform: "uppercase",
      }}
    >
      NOTES
    </h3>

    {loading ? (
      // Display the loading spinner while fetching data
      <div style={{ textAlign: "center", padding: "20px" }}>
        <LoadingSpinner />
      </div>
    ) : (
      // Always display the textarea
      <div style={{ marginBottom: "15px" }}>
        <textarea
          value={comments || ""} // Default to an empty string
          onChange={(e) => setComments(e.target.value)} // Allow editing
          disabled={!isEditingComments} // Disable editing unless in edit mode
          placeholder="No notes about this mother yet." // Display placeholder text
          style={{
            width: "100%",
            height: "150px",
            padding: "10px",
            borderRadius: "5px",
            border: "1px solid #ccc",
            fontSize: "16px",
            resize: "none",
            backgroundColor: isEditingComments ? "#fff" : "#f0f0f0",
            color: "#333",
            fontFamily: "Roboto, Arial, Helvetica, sans-serif",
          }}
        />
      </div>
    )}

    <div>
      {!isEditingComments ? (
        <button
          onClick={() => setIsEditingComments(true)} // Enable editing mode
          style={{
            padding: "8px 16px",
            backgroundColor: "#007BFF",
            color: "#fff",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            fontSize: "14px",
            marginTop: "10px",
          }}
        >
          Edit/Add Notes
        </button>
      ) : (
        <>
          <button
            onClick={handleUpdateComments} // Save the updated comments
            style={{
              padding: "8px 16px",
              backgroundColor: "#28a745",
              color: "#fff",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
              fontSize: "14px",
              marginTop: "10px",
              marginRight: "10px",
            }}
          >
            Update Notes
          </button>
          <button
            onClick={() => {
              setIsEditingComments(false);
              if (!comments) setComments(""); // Ensure an empty editable state
            }}
            style={{
              padding: "8px 16px",
              backgroundColor: "#dc3545",
              color: "#fff",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
              fontSize: "14px",
              marginTop: "10px",
            }}
          >
            Cancel
          </button>
        </>
      )}
    </div>
  </div>
</section>

<section
  className="treatment-section"
  style={{
    padding: '20px',
    margin: '20px 0',
    border: '1px solid #ddd',
    borderRadius: '10px',
    backgroundColor: '#f9f9f9',
    borderLeft: "5px solid #007BFF", // Left border added
    borderRight: "5px solid #007BFF", // Right border added
    position: 'relative', // Prevent overlap
    zIndex: 1,
  }}
>
  <h2
    style={{
      textAlign: 'center',
      fontSize: '30px',
      color: '#333',
      fontWeight: 'bold',
      marginBottom: '20px',
    }}
  >
    DRUGS GIVEN DURING LABOUR
  </h2>

  {/* Loading State */}
  {loading && (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100px',
      }}
    >
      <LoadingSpinner />
    </div>
  )}

  {/* Error State */}
  {error && (
    <p
      style={{
        textAlign: 'center',
        fontSize: '16px',
        color: 'red',
      }}
    >
      {error}
    </p>
  )}

  {/* Data Table */}
  {!loading && !error && treatmentData.length > 0 && (
    <table
      style={{
        width: '100%',
        borderCollapse: 'collapse',
      }}
    >
      <thead>
        <tr>
          <th
            style={{
              padding: '10px',
              textAlign: 'left',
              border: '1px solid #ddd',
              backgroundColor: '#f1f1f1',
            }}
          >
            Date
          </th>
          <th
            style={{
              padding: '10px',
              textAlign: 'left',
              border: '1px solid #ddd',
              backgroundColor: '#f1f1f1',
            }}
          >
            Treatment
          </th>
        </tr>
      </thead>
      <tbody>
        {treatmentData.map((entry, index) => (
          <tr
            key={index}
            style={{
              backgroundColor: index % 2 === 0 ? '#fafafa' : 'transparent',
            }}
          >
            <td style={{ padding: '10px', border: '1px solid #ddd' }}>
              {entry.date}
            </td>
            <td style={{ padding: '10px', border: '1px solid #ddd' }}>
              {entry.treatment}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )}

  {/* No Data State */}
  {!loading && !error && treatmentData.length === 0 && (
    <p
      style={{
        textAlign: 'center',
        fontSize: '16px',
        color: '#888',
      }}
    >
      No drugs given to this mother yet.
    </p>
  )}

  {/* Button for Chat Drugs Given */}
  <div
    style={{
      textAlign: 'center',
      marginTop: '20px',
    }}
  >
    <button
      style={{
        padding: '10px 20px',
        backgroundColor: '#007bff',
        color: '#fff',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        fontWeight: 'bold',
      }}
      onClick={handleOpenTreatmentChatModalMaternity}
    >
      Chat Drugs Given
    </button>
  </div>

  {/* Modal */}
  {showTreatmentChatModal && (
    <div
      ref={treatmentChatModalRef}
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
      }}
    >
      <div
        style={{
          backgroundColor: '#fff',
          padding: '20px',
          borderRadius: '8px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
          width: '90%',
          maxWidth: '500px',
        }}
      >
        <TreatmentChatModalMaternity
          maternityId={maternity_id}
          firstName={first_name}
          lastName={last_name}
          age={age}
          clinicName={clinicName}
          employeeName={employeeName}
          token={tokenFromUrl}
          onClose={handleCloseTreatmentChatModalMaternity}
        />
      </div>
    </div>
  )}
</section>



<section
  style={{
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    height: "auto", // Adjusted height to fit content
    overflowY: "auto",
    marginTop: "20px", // Proper spacing between sections
    padding: "10px",
  }}
>
  <div
    style={{
      fontFamily: "Arial, sans-serif",
      margin: "10px 0",
      padding: "20px",
      backgroundColor: "#fafafa",
      borderRadius: "10px",
      boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
      width: "98%",
      maxWidth: "100vw",
      overflowX: "hidden",
      borderLeft: "5px solid #FF7F00",
      borderRight: "5px solid #FF7F00",
    }}
  >
    <h2
      style={{
        textAlign: "center",
        color: "#444",
        fontSize: "28px",
        fontWeight: "600",
        marginBottom: "15px",
        textTransform: "uppercase",
        letterSpacing: "1px",
      }}
    >
      Prescription
    </h2>

    {loading ? (
      <div style={{ textAlign: "center", margin: "10px 0" }}>
        <LoadingSpinner />
      </div>
    ) : prescriptions.length > 0 ? (
      <div
        style={{
          backgroundColor: "#fff",
          padding: "15px",
          borderRadius: "8px",
          border: "1px solid #ddd",
          width: "100%",
        }}
      >
        <h3
          style={{
            color: "#333",
            fontSize: "20px",
            marginBottom: "10px",
            fontWeight: "500",
          }}
        >
          Prescribed Plans
        </h3>
        <ul
          style={{
            fontSize: "14px",
            color: "#555",
            listStyleType: "none",
            padding: "0",
            margin: "0",
            lineHeight: "1.5",
          }}
        >
          {prescriptions.map((plan, index) => (
            <li
              key={index}
              style={{
                marginBottom: "10px",
                fontFamily: "'Roboto', sans-serif",
                backgroundColor: "transparent",
                border: "none",
              }}
            >
              {plan}
            </li>
          ))}
        </ul>
      </div>
    ) : (
      <div
        style={{
          backgroundColor: "#fff",
          textAlign: "center",
          fontSize: "14px",
          color: "#888",
          padding: "15px",
          borderRadius: "8px",
        }}
      >
        No prescriptions for this mother.
      </div>
    )}

    <div style={{ textAlign: "center", marginTop: "20px" }}>
      <button
        style={{
          padding: "10px 20px",
          backgroundColor: "#007bff",
          color: "#fff",
          border: "none",
          borderRadius: "8px",
          cursor: "pointer",
          fontSize: "14px",
          fontWeight: "bold",
          transition: "background-color 0.3s, transform 0.2s",
          
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        }}
        onClick={() => setShowPrescriptionPrompt(true)}
        onMouseOver={(e) => (e.target.style.backgroundColor = "#0056b3")}
        onMouseOut={(e) => (e.target.style.backgroundColor = "#007bff")}
      >
        Make a Prescription
      </button>
    </div>

    {showPrescriptionPrompt && (
      <PrescriptionPrompt
        maternityId={maternity_id}
        firstName={first_name}
        lastName={last_name}
        age={age}
        clinicName={clinicName}
        employeeName={employeeName}
        token={tokenFromUrl}
        onClose={() => setShowPrescriptionPrompt(false)}
      />
    )}
  </div>



</section>


<div>
  <button 
  onClick={openPrompt}
  style={{
    backgroundColor: '#4CAF50', // Green background color
    color: 'white',              // White text color
    border: 'none',              // No border
    padding: '10px 20px',        // Padding for button size
    textAlign: 'right',          // Right-aligned text
    textDecoration: 'none',      // No underline
    display: 'inline-block',     // Align button inline
    fontSize: '16px',            // Font size
    fontWeight: 'bold',          // Bold text
    margin: '10px 0',            // Margin around button
    cursor: 'pointer',          // Pointer cursor on hover
    borderRadius: '5px',        // Rounded corners
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Subtle shadow effect
    width: 'auto'                // Auto width to fit content
  }}
>
  General  Mother Assessment By Pro Artificial Intelligence 
</button>


    {showPrompt && (
      <GeneralMotherAssessmentPrompt
        maternityId={maternity_id}  // Pass the maternity ID
        firstName={first_name}      // Pass the first name
      
        lastName={last_name}        // Pass the last name
        age={age}                   // Pass the age
        clinicName={clinicName}     // Pass the clinic name
        onClose={closePrompt}       // Pass the close handler
      />
    )}
  </div>
<button 
  type="button" 
  style={{
    backgroundColor: 'red', 
    color: 'white', 
    border: 'none', 
    padding: '10px 20px', 
    fontSize: '16px', 
    cursor: 'pointer', 
    position: 'absolute', 
    top: '10px', 
    right: '10px'
  }} 
  onClick={onClose}
>
  Close
</button>

      </div>
    </div>
  );
};

const styles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)", // semi-transparent background
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000, // Make sure it's on top
  },
  modal: {
    backgroundColor: "white",
    padding: "20px",
    borderRadius: "8px",
    width: "1000%",
    height: "100%",
    overflowY: "auto", // Allow scrolling if content overflows
  },
};

export default LaborProgressTracker;


