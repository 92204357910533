import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useParams, useNavigate } from 'react-router-dom';
import { urls } from './config.dev';
import LoadingSpinner from './LoadingSpinner'; // Import the spinner component

function SuggestionBox() {
  const { token } = useParams(); // Get the token from the URL params
  const navigate = useNavigate(); // Get the navigate function to redirect if needed
  const [clinicName, setClinicName] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false); // State to track loading status

  // Fetch clinic name using token
  const fetchClinicName = async () => {
    setIsLoading(true); // Set loading to true before making the request
    try {
      const response = await fetch(`${urls.fetchclinicname}?token=${token}`);
      const data = await response.json();
      if (response.ok) {
        setClinicName(data.clinic_name);
      } else if (data.error === "Session expired") {
        navigate('/login');
      } else {
        throw new Error('Failed to fetch clinic name');
      }
    } catch (error) {
      console.error('Error fetching clinic name:', error);
      toast.error('Error fetching clinic name');
    } finally {
      setIsLoading(false); // Set loading to false after the request completes
    }
  };

  // Fetch suggestions from the suggestion box using clinic name
  const fetchSuggestions = async () => {
    if (!clinicName) return;
    setIsLoading(true); // Set loading to true before making the request

    try {
      const response = await fetch(urls.fetchsuggestionbox, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ clinic_name: clinicName }),
      });

      const data = await response.json();
      if (response.ok) {
        setSuggestions(data || []);
      } else {
        throw new Error('Failed to fetch suggestions');
      }
    } catch (error) {
      console.error('Error fetching suggestions:', error);
      toast.error('Error fetching suggestions');
    } finally {
      setIsLoading(false); // Set loading to false after the request completes
    }
  };

  useEffect(() => {
    if (token) {
      fetchClinicName();
    } else {
      toast.error('Token is missing');
      navigate('/login');
    }
  }, [token, navigate]);

  useEffect(() => {
    if (clinicName) {
      fetchSuggestions();
    }
  }, [clinicName]);

  // Navigate to the suggestion box QR code creation page
  const navigateToQRCodePage = () => {
    const qrCodeUrl = `http://clinicprosystem.com:80/suggestionbox/${token}`;
    window.location.href = qrCodeUrl;  // Directly navigate to the full URL
  };

  return (
    <div style={styles.container}>
      <ToastContainer />

      {/* Display the clinic name if available */}
      {clinicName && <h2 style={styles.heading}>Welcome to {clinicName} Suggestion Box</h2>}

      {/* Show loading spinner while fetching data */}
      {isLoading ? (
        <div style={styles.spinnerContainer}>
          <LoadingSpinner />
        </div>
      ) : (
        <div>
          {/* Display the suggestions if available */}
          <h3 style={styles.subheading}>Suggestions:</h3>
          {suggestions.length > 0 ? (
            <div style={styles.scrollableListContainer}>
              <ul style={styles.suggestionList}>
                {suggestions.map((suggestion) => (
                  <li key={suggestion.id} style={styles.suggestionItem}>
                    <div style={styles.suggestionText}>
                      <p><strong>Feedback:</strong> {suggestion.suggestion}</p>
                      <p><strong>Identity:</strong> {suggestion.identity}</p>
                    </div>
                    <div style={styles.suggestionDate}>
                      <p><strong>Date:</strong> {new Date(suggestion.feedback_date).toLocaleString()}</p>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            <p style={styles.noSuggestions}>No suggestions available.</p>
          )}
        </div>
      )}

      {/* Link to navigate for QR Code creation */}
      <div style={styles.linkContainer}>
        <p style={styles.linkText}>
          If you have not yet created a QR code for your suggestion box,{' '}
          <button onClick={navigateToQRCodePage} style={styles.linkButton}>
            click here
          </button>.
        </p>
      </div>
    </div>
  );
}

const styles = {
  container: {
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
    backgroundColor: '#f4f4f4',
    borderRadius: '0px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    maxWidth: '800px',
    margin: '20px auto',
  },
  heading: {
    color: '#333',
    textAlign: 'center',
    marginBottom: '20px',
  },
  subheading: {
    fontSize: '18px',
    color: '#444',
    marginBottom: '15px',
  },
  suggestionList: {
    listStyleType: 'none',
    padding: '0',
    margin: '0',
  },
  suggestionItem: {
    backgroundColor: '#fff',
    padding: '15px',
    border: '1px solid #ddd',
    borderRadius: '0px',
    marginBottom: '10px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
  },
  suggestionText: {
    marginBottom: '10px',
  },
  suggestionDate: {
    fontSize: '12px',
    color: '#888',
  },
  noSuggestions: {
    fontStyle: 'italic',
    color: '#777',
  },
  linkContainer: {
    marginTop: '20px',
    textAlign: 'center',
  },
  linkText: {
    fontSize: '14px',
    color: '#555',
  },
  linkButton: {
    backgroundColor: '#4CAF50',
    color: 'white',
    padding: '8px 16px',
    border: 'none',
    borderRadius: '0px',
    cursor: 'pointer',
    textDecoration: 'none',
  },
  spinnerContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
  },
  scrollableListContainer: {
    maxHeight: '300px', // Adjust height as needed
    overflowY: 'auto',  // Enables vertical scrolling
  },
};

export default SuggestionBox;
