import React, { useState, useEffect } from 'react';
import { urls } from './config.dev';
import './selldrugs.css';
import { Link } from 'react-router-dom'; 
import cpsImage from './images/cps.PNG'; // Correctly import the image
import { useNavigate } from 'react-router-dom';
import Topbar from './Topbar'; // Import the Topbar component
import MissingDrugs from './MissingDrugs'; 
import printJS from 'print-js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart, faPrint, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
// Import Font Awesome CSS
import 'font-awesome/css/font-awesome.min.css';
import ReceiptModal from './ReceiptModal'; // Adjust the import path accordingly


function Selldrugs() {
  const [stockData, setStockData] = useState([]);
  const [selectedDrug, setSelectedDrug] = useState(null);
  const [quantityToSell, setQuantityToSell] = useState(0);
  const [sellItems, setSellItems] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [showTransactionPrompt, setShowTransactionPrompt] = useState(false);
  const [employeeName, setEmployeeName] = useState('');
  const [clinicName, setClinicName] = useState('');
  const [isSellButtonActive, setIsSellButtonActive] = useState(false); // New state for button activation

  const [responseMessage, setResponseMessage] = useState(null);
  const [confirmClicked, setConfirmClicked] = useState(false);
  const [confirmButtonText, setConfirmButtonText] = useState('Confirm Transaction');
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
const tokenFromUrl = params.get('token');
  const urlToken = params.get('token'); // Define urlToken here
  // Add these missing state variables
 
  const [showReceiptModal, setShowReceiptModal] = useState(false);
  const [receiptDetails, setReceiptDetails] = useState({
    clinicName: '',
    district: '',
    town: '',
    ownersContact: '',
    drugsSold: [], // Ensuring this is initialized as an array
    totalAmount: 0,
    employeeName: '',
    date: '',
    time: '',
  });
  

  const performSecurityCheck = async (token) => {
  try {
    const securityResponse = await fetch(urls.security, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token }),
    });

    if (securityResponse.ok) {
      const securityData = await securityResponse.json();
      if (securityData.message === 'Session valid') {
        setEmployeeName(securityData.employee_name);
        setClinicName(securityData.clinic); // Set the clinic name
        return true; // Security check passed
      } else if (securityData.error === 'Session expired') {
        navigate(`/dashboard?token=${securityData.clinic_session_token}`);
        return false; // Security check failed due to session expired
      } else {
        navigate('/login');
        return false; // Security check failed
      }
    } else {
      throw new Error('Failed to perform security check');
    }
  } catch (error) {
    console.error('Error performing security check:', error);
    navigate('/login');
    return false; // Security check failed
  }
};

  useEffect(() => {
    const fetchDispensaryStock = async (token) => {
      try {
        const response = await fetch(urls.fetchdispensary, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token }), // Include token in the request body
        });
        const data = await response.json();
        setStockData(data);
      } catch (error) {
        console.error('Error fetching shelves stock:', error);
      }
    };
  
    const fetchTokenAndCheckSecurity = async () => {
      try {
        const params = new URLSearchParams(window.location.search);
        const tokenFromUrl = params.get('token');
        if (!tokenFromUrl) {
          throw new Error('Token not found in URL');
        }
  
        const securityPassed = await performSecurityCheck(tokenFromUrl);
  
        if (securityPassed) {
          fetchDispensaryStock(tokenFromUrl);
          // Set interval to fetch stock data every 5 seconds with the token
          const intervalId = setInterval(() => fetchDispensaryStock(tokenFromUrl), 5000);
          // Cleanup interval on component unmount
          return () => clearInterval(intervalId);
        }
      } catch (error) {
        console.error('Error fetching token and checking security:', error);
        navigate('/login');
      }
    };
  
    fetchTokenAndCheckSecurity();
  }, [navigate]);
  
  
// Modify the handleDrugSelect function to automatically fill in the selected drug
const handleDrugSelect = (drug) => {
  setSelectedDrug(drug);
  setQuantityToSell(1); // Default quantity to 1
  setSearchQuery(''); // Clear search query
  setIsSellButtonActive(false); // Set button to inactive on drug selection
};



  const handleRemoveSellItem = (index) => {
    const updatedSellItems = sellItems.filter((_, idx) => idx !== index);
    setSellItems(updatedSellItems);
    setTotalAmount(totalAmount - sellItems[index].amount);
  };

  const handleConfirmTransaction = () => {
    setShowTransactionPrompt(true);
  };

  const handleCancelTransaction = () => {
    setShowTransactionPrompt(false);
  };

  const handleConfirmSale = async () => {
    // Disable the button and change its text to "Please wait..."
    setConfirmClicked(true);
    setConfirmButtonText('Please wait...');
  
    // Fetch token from URL query parameters
    const params = new URLSearchParams(window.location.search);
    const tokenFromUrl = params.get('token');
  
    const currentDate = new Date().toLocaleDateString('en-UG', {
      timeZone: 'Africa/Kampala'
    });
    const currentTime = new Date().toLocaleTimeString('en-US', {
      timeZone: 'Africa/Kampala'
    });
  
    const saleData = {
      sales: sellItems.map(item => ({
        drug: item.drug,
        packaging: item.packaging,
        quantitySold: item.quantity,
        amount: item.amount,
        sellingPrice: item.amount / item.quantity,
      })),
      totalAmount: totalAmount,
      employeeName: employeeName,
      currentDate: currentDate,
      currentTime: currentTime,
      token: tokenFromUrl,
    };
  
    try {
      const response = await fetch(urls.selldrugs, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(saleData),
      });
  
      if (response.ok) {
        const responseData = await response.json();
        if (responseData.message === "Drugs sold successfully!") {
          // Fetch clinic details for the receipt with POST and token in the body
          const clinicResponse = await fetch(urls.fetchclinicdetails, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              token: tokenFromUrl // Include the token in the request body
            })
          });
  
          if (clinicResponse.ok) {
            const clinicDetails = await clinicResponse.json();
  
            if (clinicDetails && sellItems) {
              setReceiptDetails({
                clinicName: clinicDetails.clinic_name,
                district: clinicDetails.district,
                town: clinicDetails.sub_county,
                ownersContact: clinicDetails.owners_contact,
                drugsSold: sellItems.map(item => ({
                  drug: item.drug,
                  quantity: item.quantity,
                  packaging: item.packaging,
                  unitPrice: item.amount / item.quantity,
                  amount: item.amount,
                })),
                totalAmount: totalAmount,
                employeeName: employeeName,
                date: currentDate,
                time: currentTime,
              });
              setShowTransactionPrompt(false);
              setTimeout(() => setShowReceiptModal(true), 300); // Adjust the delay if necessary
  
              // Send the same saleData to urls.assignAverage
              const averageResponse = await fetch(urls.assignAverage, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify(saleData)
              });
  
              if (averageResponse.ok) {
                const averageResponseData = await averageResponse.json();
                // Log the response to the console (no toast)
                console.log('Average response:', averageResponseData);
              } else {
                console.error('Failed to assign average');
              }
            } else {
              console.error('sellItems is undefined or clinic details are missing');
            }
          } else {
            console.error('Failed to fetch clinic details');
          }
  
          setResponseMessage(null);
        } else {
          setResponseMessage({
            type: "error-message",
            text: `Hi ${employeeName}, your transaction has failed. Please confirm that the quantity of drugs you want to sell are really available on the shelves. Also check your network connection.`
          });
        }
        // Clear cart and reset state
        setSellItems([]);
        setTotalAmount(0);
      } else {
        setResponseMessage({
          type: "error-message",
          text: `Hi ${employeeName}, your transaction has failed. Please confirm that the quantity of items you want to sell are really available on the shelves. Also check your network connection.`
        });
      }
    } finally {
      // Re-enable the button and reset text
      setConfirmClicked(false);
      setConfirmButtonText('Confirm Transaction');
      // Clear the response message and close the transaction prompt after 10 seconds
      setTimeout(() => {
        setResponseMessage(null);
      }, 7000);
    }
  };
  
  const handleUnitPriceChange = (index, value) => {
    const updatedItems = [...sellItems];
    const newPrice = parseFloat(value) || 0; // Convert input to a number
    updatedItems[index].unitPrice = newPrice; // Update unit price
    updatedItems[index].amount = newPrice * updatedItems[index].quantity; // Recalculate amount
    setSellItems(updatedItems); // Update state with new items
    calculateTotalAmount(updatedItems); // Recalculate total amount
  };
  
  const handleSelectedDrugPriceChange = (e) => {
    const newPrice = parseFloat(e.target.value) || 0; // Convert input to a number
    setSelectedDrug((prevDrug) => ({
      ...prevDrug,
      Selling_Price: newPrice,
    }));
  };
  

  const handleSellingPriceChange = (value) => {
    const updatedDrug = { ...selectedDrug, Selling_Price: parseFloat(value) || 0 };
    setSelectedDrug(updatedDrug);
  };

  const handleSellButtonClick = () => {
    if (selectedDrug) {
      const newItem = {
        drug: selectedDrug.Drug,
        packaging: selectedDrug.Packaging,
        quantity: quantityToSell,
        unitPrice: selectedDrug.Selling_Price,
        amount: quantityToSell * selectedDrug.Selling_Price,
      };
      const updatedItems = [...sellItems, newItem];
      setSellItems(updatedItems);
      calculateTotalAmount(updatedItems);
  
      setSelectedDrug(null); // Clear selected drug after adding to cart
      setQuantityToSell(1); // Reset quantity
  
      setIsSellButtonActive(true); // Activate the Confirm Transaction button
    }
  };
  

  const calculateTotalAmount = (items) => {
    const total = items.reduce((sum, item) => sum + item.amount, 0);
    setTotalAmount(total);
  };

  const handleQuantityChange = (index, newQuantity) => {
    const updatedItems = [...sellItems];
    updatedItems[index].quantity = Number(newQuantity);
    updatedItems[index].amount = updatedItems[index].quantity * updatedItems[index].unitPrice;
    setSellItems(updatedItems);
    calculateTotalAmount(updatedItems);
  };
 
  return (
    <div style={{ fontFamily: 'Arial, sans-serif' }} className="container-selling-drugs">
      <Topbar token={urlToken} />
      <MissingDrugs token={urlToken} />
      {/* Header section with dropdown buttons */}
      <div className="headerx">
        <div className="dropdown">
          <Link to={`/remove-drugs/?token=${tokenFromUrl}`}>Non-Sale Stock Removal</Link>
        </div>
        <div className="dropdown">
          <Link to={`/dispensed-and-sold/?token=${tokenFromUrl}`}>Sold Drugs Overview</Link>
        </div>
        <div className="dropdown">
          <Link to={`/removed-drugs-equipment/?token=${tokenFromUrl}`}>Removed Drugs Overview</Link>
        </div>
      </div>

      <div className="content">
        <div style={{ display: 'flex' }}>
          <div className="container-sell-drugs">
            <h2>Drugs in Cart</h2>
            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
              <thead>
                <tr>
                  <th style={{ width: '200px' }}>Drugs</th>
                  <th style={{ width: '150px' }}>Quantity</th>
                  <th style={{ width: '120px' }}>Unit Price</th> {/* Removed vertical line */}
                  <th style={{ width: '120px' }}>Amount</th>
                  <th style={{ width: '150px' }}>Action</th> {/* Decreased width */}
                </tr>
              </thead>
              <tbody>
                {sellItems.map((item, index) => (
                  <tr key={index}>
                    <td style={{ fontSize: '14px' }}>
                      {item.drug} {item.packaging}
                    </td>
                    <td>
                      <input
                        type="number"
                        value={item.quantity}
                        onChange={(e) => handleQuantityChange(index, e.target.value)}
                        className="input-field"
                        style={{ width: '70px', padding: '5px' }}
                        onWheel={(e) => e.preventDefault()} // Prevent scroll changes
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        value={Number(item.unitPrice)}
                        onChange={(e) => handleUnitPriceChange(index, e.target.value)}
                        className="input-field"
                        style={{ width: '70px', padding: '5px', border: 'none', textAlign: 'right' }}
                        onWheel={(e) => e.preventDefault()} // Prevent scroll changes
                      />
                    </td>
                    <td>{Math.floor(item.quantity * item.unitPrice)}</td> {/* Removed decimal places */}
                    <td>
                      <button onClick={() => handleRemoveSellItem(index)} className="remove-button">
                        X
                      </button>
                    </td>
                  </tr>
                ))}
                <tr>
                  <td style={{ fontSize: '14px' }}>
                    {selectedDrug ? `${selectedDrug.Drug} ${selectedDrug.Packaging}` : ''}
                  </td>
                  <td>
                    <input
                      type="number"
                      value={quantityToSell !== 0 ? quantityToSell : ''}
                      onChange={(e) => setQuantityToSell(e.target.value ? Number(e.target.value) : 0)}
                      className="input-field"
                      style={{ width: '70px', padding: '5px' }}
                      onWheel={(e) => e.preventDefault()} // Prevent scroll changes
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      value={selectedDrug && !isNaN(Number(selectedDrug.Selling_Price)) 
                            ? Number(selectedDrug.Selling_Price) 
                            : '0'}
                      onChange={(e) => setSelectedDrug(prev => ({ ...prev, Selling_Price: e.target.value }))}
                      className="input-field"
                      style={{ width: '70px', padding: '5px', border: 'none', textAlign: 'right' }}
                      onWheel={(e) => e.preventDefault()} // Prevent scroll changes
                    />
                  </td>
                  <td>{selectedDrug && !isNaN(Number(selectedDrug.Selling_Price)) 
                        ? Math.floor(quantityToSell * Number(selectedDrug.Selling_Price)) 
                        : ''}</td> {/* Removed decimal places */}
                  <td>
                    <button 
                      onClick={handleSellButtonClick} 
                      disabled={!selectedDrug} 
                      style={{ padding: '8px', margin: '5px', width: '100%' }}
                    >
                      <FontAwesomeIcon icon={faShoppingCart} /> Add 
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="total-amount-container" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '-15px' }}>
              <div>Total Amount: UGX {Math.floor(totalAmount)}</div> {/* Removed decimal places */}
              <button
                onClick={handleConfirmTransaction}
                disabled={!isSellButtonActive || confirmClicked}
                className="confirm-button"
                style={{ padding: '8px', marginLeft: '20px' }}
              >
                <FontAwesomeIcon icon={faCheck} /> {confirmButtonText}
              </button>
            </div>
          </div>

          <div className="container-sell-drugs2">
            <input
              className="selldrugs-search-input"
              type="text"
              placeholder="Search Drugs... Click on the drugs you want to sell"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <table style={{ width: '100%' }}>
              <thead>
                <tr>
                  <th>Drugs</th>
                  <th>Quantity</th>
                  <th>Packaging</th>
                  <th>Selling Price</th>
                </tr>
              </thead>
              <tbody>
                {stockData
                  .filter(drug => drug.Drug.toLowerCase().includes(searchQuery.toLowerCase()))
                  .map((drug, index) => (
                    <tr key={index} onClick={() => handleDrugSelect(drug)}>
                      <td>{drug.Drug}</td>
                      <td>{drug.Quantity}</td>
                      <td>{drug.Packaging}</td>
                      <td>{drug.Selling_Price}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>

        {showTransactionPrompt && (
          <div className="modal-overlay">
            <div className="modal-content">
              {responseMessage && responseMessage.type === "success-message" ? (
                <div className={responseMessage.type}>
                  {responseMessage.text}
                </div>
              ) : (
                <>
                  <div className="transaction-prompt">
                    <div className="transaction-details">
                      <h2>Transaction Details</h2>
                      {sellItems.map((item, index) => (
                        <div key={index}>{item.quantity} {item.packaging} {item.drug} {item.amount}</div>
                      ))}
                      <div>Total Bill: UGX {totalAmount}</div>
                      <div>Served by: {employeeName}</div>
                    </div>
                    <div className="transaction-buttons">
                      <button onClick={handleCancelTransaction}>
                        <FontAwesomeIcon icon={faTimes} /> Cancel Transaction
                      </button>
                      <button onClick={handleConfirmSale} disabled={confirmClicked}>
                        <FontAwesomeIcon icon={faCheck} /> {confirmButtonText}
                      </button>
                    </div>
                  </div>
                  {responseMessage && (
                    <div className={responseMessage.type}>
                      {responseMessage.text}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        )}

        {showReceiptModal && receiptDetails && (
          <ReceiptModal
            receiptDetails={receiptDetails}
            onClose={() => setShowReceiptModal(false)}
            
          />
        )}
      </div>
    </div>
  );
};

export default Selldrugs;