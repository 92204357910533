import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import { urls } from "./config.dev";

const AnnualAssessment = ({ token, onClose }) => {
  const [year, setYear] = useState(new Date().getFullYear());
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [clinicProAssessment, setClinicProAssessment] = useState(null); // State for Clinic Pro Assessment data
  const [selectedMetrics, setSelectedMetrics] = useState({
    sales: true,
    expenses: true,
    profits: true,
  });

  const fetchAnnualData = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(urls.fetchannualdata, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token, year }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch data.");
      }

      const result = await response.json();
      setData(result);

      // Once we have the annual data, send it to the AI endpoint
      await sendToAIannualAssessment(result);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const sendToAIannualAssessment = async (annualData) => {
    try {
      const response = await fetch(urls.AIannualassessment, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token,
          year,
          annualData, // Include the annual data here
        }),
      });

      if (!response.ok) {
        throw new Error("Failed make employee and financial assesment, please try again.");
      }

      const aiResponse = await response.json();
      setClinicProAssessment(aiResponse); // Store the response from the AI endpoint
    } catch (err) {
      setError(`Error with AI assessment: ${err.message}`);
    }
  };

  useEffect(() => {
    fetchAnnualData();
  }, [year]);

  const handleMetricChange = (metric) => {
    setSelectedMetrics((prevMetrics) => ({
      ...prevMetrics,
      [metric]: !prevMetrics[metric],
    }));
  };

  const graphData = data
    ? {
        labels: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ],
        datasets: [
          selectedMetrics.sales && {
            label: "Sales",
            data: Object.values(data.sales),
            borderColor: "#4CAF50",
            backgroundColor: "rgba(76, 175, 80, 0.2)",
            fill: true,
          },
          selectedMetrics.expenses && {
            label: "Expenses",
            data: Object.values(data.expenses),
            borderColor: "#F44336",
            backgroundColor: "rgba(244, 67, 54, 0.2)",
            fill: true,
          },
          selectedMetrics.profits && {
            label: "Profits",
            data: Object.values(data.profits),
            borderColor: "#2196F3",
            backgroundColor: "rgba(33, 150, 243, 0.2)",
            fill: true,
          },
          selectedMetrics.costOfDrugs && {
            label: "Cost of Drugs",
            data: Object.values(data.cost_of_drugs_sold),
            borderColor: "#FF9800",
            backgroundColor: "rgba(255, 152, 0, 0.2)",
            fill: true,
          },
        ].filter(Boolean),
      }
    : null;
  
    const formatResponse = (responseText) => {
      // First, escape any HTML tags to avoid showing raw HTML to the user
      const sanitizedText = responseText
          .replace(/&/g, "&amp;") // Escape '&'
          .replace(/</g, "&lt;") // Escape '<'
          .replace(/>/g, "&gt;"); // Escape '>'
  
      // Then apply transformations for specific formatting
      return sanitizedText
          .replace(/\n/g, "<br />") // Replace newlines with <br />
          .replace(/\\n/g, "<br />") // Handle escaped newlines
          .replace(/\*{2}(.*?)\*{2}/g, "<b>$1</b>"); // Replace text between double asterisks with bold text
  };
  
      
  
  return (
    <div style={styles.overlay}>
      <div style={styles.modal}>
        <button style={styles.closeButton} onClick={onClose}>
          &times;
        </button>
        <h1 style={styles.heading}>Annual Assessment</h1>
        <div style={styles.inputContainer}>
          <label htmlFor="year" style={styles.label}>
            Year:
          </label>
          <input
            type="number"
            id="year"
            value={year}
            onChange={(e) => setYear(e.target.value)}
            style={styles.input}
          />
       </div>
      {loading && <p style={styles.loadingText}>Loading data...</p>}
      {error && <p style={styles.errorText}>{error}</p>}
      <div style={styles.contentContainer}>
        {graphData && (
          <div style={styles.chartContainer}>
            <Line
              data={graphData}
              options={{
                responsive: true,
                plugins: {
                  legend: {
                    position: "top",
                  },
                  tooltip: {
                    mode: "index",
                    intersect: false,
                  },
                },
                scales: {
                  x: {
                    beginAtZero: true,
                  },
                  y: {
                    beginAtZero: true,
                  },
                },
              }}
            />
          </div>
        )}
        <div style={styles.sidebar}>
          <h2>Metrics</h2>
          <label>
            <input
              type="checkbox"
              checked={selectedMetrics.sales}
              onChange={() => handleMetricChange("sales")}
            />
            Sales
          </label>
          <label>
            <input
              type="checkbox"
              checked={selectedMetrics.expenses}
              onChange={() => handleMetricChange("expenses")}
            />
            Expenses
          </label>
          <label>
            <input
              type="checkbox"
              checked={selectedMetrics.profits}
              onChange={() => handleMetricChange("profits")}
            />
            Profits
          </label>
          <label>
            <input
              type="checkbox"
              checked={selectedMetrics.costOfDrugs}
              onChange={() => handleMetricChange("costOfDrugs")}
            />
            Cost of Drugs
          </label>
        </div>
        </div>
        
        {clinicProAssessment && (
      <div style={styles.assessmentContainer}>
        <h2>Clinic Pro Assessment</h2>

        {/* Financial Analysis Section */}
        {clinicProAssessment.financialAnalysis && (
          <div style={styles.section}>
            <h3 style={styles.heading}>Financial Analysis</h3>
            <div
              dangerouslySetInnerHTML={{
                __html: formatResponse(clinicProAssessment.financialAnalysis),
              }}
              style={{
                whiteSpace: "pre-wrap",
                wordWrap: "break-word",
                maxHeight: "300px",
                overflowY: "auto",
              }}
            />
          </div>
        )}

        {/* Employee Analysis Section */}
        {clinicProAssessment.employeeAnalysis && (
          <div style={styles.section}>
            <h3 style={styles.heading}>Employee Analysis</h3>
            <div
              dangerouslySetInnerHTML={{
                __html: formatResponse(clinicProAssessment.employeeAnalysis),
              }}
              style={{
                whiteSpace: "pre-wrap",
                wordWrap: "break-word",
                maxHeight: "300px",
                overflowY: "auto",
              }}
            />
          </div>
        )}
 </div>
    )}
 

      </div>
    </div>
  );
};

const styles = {
  overlay: {
    position: "fixed",
    top: "20px",
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1000,
  },
  modal: {
    backgroundColor: "#fff",
    padding: "20px",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    width: "100%",
    maxWidth: "1600px",
    height: "95%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    position: "relative",
    overflowY: "auto",
  },
  closeButton: {
    position: "absolute",
    top: "10px",
    right: "10px",
    background: "none",
    border: "none",
    fontSize: "30px",
    color: "#333",
    cursor: "pointer",
  },
  heading: {
    fontSize: "24px",
    marginBottom: "20px",
  },
  inputContainer: {
    marginBottom: "20px",
    textAlign: "center",
  },
  label: {
    marginRight: "10px",
  },
  input: {
    padding: "5px",
    border: "1px solid #ccc",
    borderRadius: "4px",
  },
  loadingText: {
    color: "#007BFF",
    marginBottom: "20px",
  },
  errorText: {
    color: "#F44336",
    marginBottom: "20px",
  },
  contentContainer: {
    display: "flex",
    width: "100%",
    height: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  chartContainer: {
    flex: 1,
    marginRight: "20px",
  },
  sidebar: {
    width: "250px",
    padding: "10px",
    borderLeft: "2px solid #ccc",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  assessmentContainer: {
    marginTop: "30px",
    width: "100%",
    padding: "10px",
    backgroundColor: "#f7f7f7",
    borderRadius: "5px",
    border: "1px solid #ccc",
  },
};

export default AnnualAssessment;
