import React, { useState, useEffect } from 'react';
import { urls } from './config.dev'; // Import the backend URLs
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';


const ObstetricHistoryPrompt = (props) => {
  const { first_name, last_name, maternity_id, onClose } = props;
  const [isLoading, setIsLoading] = useState(false);
  // State to store form data
  const [formData, setFormData] = useState(
    Array.from({ length: 10 }, () => ({
      pregnancy: '',
      year: '',
      below12Weeks: false,
      above12Weeks: false,
      premature: false,
      fullTerm: false,
      thirdStage: false,
      purePerium: false,
      aliveSB_NND: '',
      sex: '',
      birthWeight: '',
      immun: false,
      healthConditions: '',
    }))
  );

  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state

  // Fetch obstetric history on mount
  useEffect(() => {
    const fetchHistory = async () => {
      try {
        const response = await fetch(urls.FetchObsHistory, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ maternity_id }),
        });
        const data = await response.json();

        if (response.ok && data.data && data.data.length > 0) {
          // Map data to the form structure and fill remaining rows with empty rows
          const filledData = [
            ...data.data.map((item) => ({
              pregnancy: item.pregnancy_number || '',
              year: item.year || '',
              below12Weeks: item.below12Weeks || false,
              above12Weeks: item.above12Weeks || false,
              premature: item.premature || false,
              fullTerm: item.fullTerm || false,
              thirdStage: item.thirdStage || false,
              purePerium: item.purePerium || false,
              aliveSB_NND: item.aliveSB_NND || '',
              sex: item.sex || '',
              birthWeight: item.birthWeight || '',
              immun: item.immun || false,
              healthConditions: item.healthConditions || '',
            })),
            ...Array.from({ length: 10 - data.data.length }, () => ({
              pregnancy: '',
              year: '',
              below12Weeks: false,
              above12Weeks: false,
              premature: false,
              fullTerm: false,
              thirdStage: false,
              purePerium: false,
              aliveSB_NND: '',
              sex: '',
              birthWeight: '',
              immun: false,
              healthConditions: '',
            })),
          ];
          setFormData(filledData);
        } else if (data.error === 'No records found for the provided maternity_id') {
          // Handle no records scenario: Display 10 empty rows
          setFormData(
            Array.from({ length: 10 }, () => ({
              pregnancy: '',
              year: '',
              below12Weeks: false,
              above12Weeks: false,
              premature: false,
              fullTerm: false,
              thirdStage: false,
              purePerium: false,
              aliveSB_NND: '',
              sex: '',
              birthWeight: '',
              immun: false,
              healthConditions: '',
            }))
          );
        } else {
          setError('Unexpected response from the server.');
        }
      } catch (err) {
        setError('Failed to fetch obstetric history. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchHistory();
  }, [maternity_id]);

  // Handle change for form inputs
  const handleChange = (index, event) => {
    const { name, value, type, checked } = event.target;
    const newFormData = [...formData];
    if (type === 'checkbox') {
      newFormData[index][name] = checked;
    } else {
      newFormData[index][name] = value;
    }
    setFormData(newFormData);
  };

  // Handle submission of data
  const handleSubmit = async () => {
    setIsLoading(true); // Start the spinner
    try {
      const filteredData = formData.filter((row) => {
        const hasAnyValue = Object.values(row).some((value) => value !== '' && value !== false);
        return hasAnyValue;
      });
  
      const invalidRows = filteredData.filter((row) => !row.pregnancy || !row.year);
      if (invalidRows.length > 0) {
        toast.error('All rows must include a Pregnancy and Year value.', {
          position: 'top-right',
        });
        setIsLoading(false); // Stop the spinner
        return;
      }
  
      const payload = filteredData.map((row) => ({
        pregnancy: row.pregnancy || '',
        year: row.year || '',
        below12Weeks: row.below12Weeks || false,
        above12Weeks: row.above12Weeks || false,
        premature: row.premature || false,
        fullTerm: row.fullTerm || false,
        thirdStage: row.thirdStage || false,
        purePerium: row.purePerium || false,
        aliveSB_NND: row.aliveSB_NND || '',
        sex: row.sex || '',
        birthWeight: row.birthWeight || '',
        immun: row.immun || false,
        healthConditions: row.healthConditions || '',
      }));
  
      const response = await fetch(urls.updateobshistory, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ maternity_id, formData: payload }),
      });
  
      if (response.ok) {
        toast.success('Obstetric History updated successfully!', {
          position: 'top-right',
        });
      } else {
        toast.error('Failed to update Obstetric History.', {
          position: 'top-right',
        });
      }
    } catch (error) {
      console.error('Error updating obstetric history:', error);
      toast.error('An error occurred while updating obstetric history.', {
        position: 'top-right',
      });
    } finally {
      setIsLoading(false); // Stop the spinner
    }
  };
  if (loading) {
    return <div>Loading obstetric history...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div style={overlayStyle}>
      <div style={containerStyle}>
        <h2 style={headingStyle}>
          Obstetric History for {first_name} {last_name}
        </h2>
        <button
          onClick={onClose}
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            padding: "5px 10px",
            backgroundColor: "transparent",
            color: "#dc3545",
            border: "none",
            fontSize: "20px",
            cursor: "pointer",
          }}
        >
          &times;
        </button>
        <div style={tableContainerStyle}>
          <table style={tableStyle}>
            <thead>
              <tr>
                <th rowSpan="2" style={headerStyle}>Pregnancy</th>
                <th rowSpan="2" style={headerStyle}>Year</th>
                <th colSpan="2" style={headerStyle}>Abortions</th>
                <th colSpan="4" style={headerStyle}>Types of Deliveries</th>
                <th colSpan="5" style={headerStyle}>Child</th>
              </tr>
              <tr>
                <th style={headerStyle}>Below 12 Weeks</th>
                <th style={headerStyle}>Above 12 Weeks</th>
                <th style={headerStyle}>Pre-Mature</th>
                <th style={headerStyle}>Full Term</th>
                <th style={headerStyle}>Third Stage</th>
                <th style={headerStyle}>Pure Perium</th>
                <th style={headerStyle}>Alive SB/NND</th>
                <th style={headerStyle}>Sex</th>
                <th style={headerStyle}>Birth Weight</th>
                <th style={headerStyle}>Immun.</th>
                <th style={headerStyle}>Health Conditions</th>
              </tr>
            </thead>
            <tbody>
              {formData.map((data, index) => (
                <tr key={index}>
                  <td style={cellStyle}>
                    <input type="text" name="pregnancy" value={data.pregnancy} onChange={(e) => handleChange(index, e)} style={inputStyle} />
                  </td>
                  <td style={cellStyle}>
                    <input type="text" name="year" value={data.year} onChange={(e) => handleChange(index, e)} style={inputStyle} />
                  </td>
                  <td style={cellStyle}>
                    <input type="checkbox" name="below12Weeks" checked={data.below12Weeks} onChange={(e) => handleChange(index, e)} />
                  </td>
                  <td style={cellStyle}>
                    <input type="checkbox" name="above12Weeks" checked={data.above12Weeks} onChange={(e) => handleChange(index, e)} />
                  </td>
                  <td style={cellStyle}>
                    <input type="checkbox" name="premature" checked={data.premature} onChange={(e) => handleChange(index, e)} />
                  </td>
                  <td style={cellStyle}>
                    <input type="checkbox" name="fullTerm" checked={data.fullTerm} onChange={(e) => handleChange(index, e)} />
                  </td>
                  <td style={cellStyle}>
                    <input type="checkbox" name="thirdStage" checked={data.thirdStage} onChange={(e) => handleChange(index, e)} />
                  </td>
                  <td style={cellStyle}>
                    <input type="checkbox" name="purePerium" checked={data.purePerium} onChange={(e) => handleChange(index, e)} />
                  </td>
                  <td style={cellStyle}>
                    <input type="text" name="aliveSB_NND" value={data.aliveSB_NND} onChange={(e) => handleChange(index, e)} style={inputStyle} />
                  </td>
                  <td style={cellStyle}>
                    <input type="text" name="sex" value={data.sex} onChange={(e) => handleChange(index, e)} style={inputStyle} />
                  </td>
                  <td style={cellStyle}>
                    <input type="text" name="birthWeight" value={data.birthWeight} onChange={(e) => handleChange(index, e)} style={inputStyle} />
                  </td>
                  <td style={cellStyle}>
                    <input type="checkbox" name="immun" checked={data.immun} onChange={(e) => handleChange(index, e)} />
                  </td>
                  <td style={cellStyle}>
                    <input type="text" name="healthConditions" value={data.healthConditions} onChange={(e) => handleChange(index, e)} style={inputStyle} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div style={buttonContainerStyle}>
        <button
  onClick={handleSubmit}
  style={buttonStyle}
  disabled={isLoading} // Disable button when loading
>
  {isLoading ? (
    <span>
      <FontAwesomeIcon icon={faSpinner} spin style={{ marginRight: '5px' }} />
      Submitting...
    </span>
  ) : (
    'Submit'
  )}
</button>


          <button onClick={onClose} style={{ backgroundColor: 'red', color: 'white', border: 'none', padding: '10px 20px', cursor: 'pointer' }}>
  Close
</button>

        </div>
      </div>
    </div>
  );
};

// Styles
const overlayStyle = {
  position: 'fixed',
  top: '0',
  left: '0',
  width: '100vw',
  height: '100vh',
  backgroundColor: 'rgba(0, 0, 0, 1)', // Fully black background
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: '1000', // Ensure it appears on top of other content
};

const containerStyle = {
  position: 'relative',
  background: '#ffffff',
  padding: '30px',
  borderRadius: '8px',
  width: '120vw',
  maxWidth: '1600px',
  height: '80vh',
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',
};
const headingStyle = {
  textAlign: 'center',
  fontSize: '18px',
};

const tableContainerStyle = {
  width: '100%',
  overflowX: 'auto',
  flex: 1,  // Ensures table container takes remaining space
};

const tableStyle = {
  width: '100%',
  borderCollapse: 'collapse',
  fontSize: '12px',
};

const headerStyle = {
  border: '1px solid black',
  padding: '4px',
  textAlign: 'center',
  backgroundColor: '#f0f0f0',
  fontWeight: 'bold',
  fontSize: '12px',
};

const cellStyle = {
  border: '1px solid black',
  padding: '4px',
  textAlign: 'center',
};

const inputStyle = {
  width: '100%',
  fontSize: '12px',
};

const commentsSectionStyle = {
  marginTop: '20px',
};

const textAreaStyle = {
  width: '100%',
  height: '100px',
  marginTop: '10px',
  fontSize: '12px',
};

const buttonContainerStyle = {
  marginTop: '20px',
};

const buttonStyle = {
  fontSize: '14px',
  padding: '10px 20px',
  backgroundColor: '#4CAF50',
  color: 'white',
  border: 'none',
  borderRadius: '5px',
  cursor: 'pointer',
};

export default ObstetricHistoryPrompt;
