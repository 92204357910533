import React, { useState, useEffect, useRef } from 'react';
import { urls } from './config.dev';
import { ToastContainer, toast } from 'react-toastify'; // Import toast functions
import 'react-toastify/dist/ReactToastify.css'; // Import toast styles
import './TreatmentChatModal.css';

const cellStyle = {
    padding: '10px',
    border: '1px solid #ddd',
};

const drugNameCellStyle = {
    ...cellStyle,
    width: '40%',
};

const drugQuantityCellStyle = {
    ...cellStyle,
    width: '10%',
};

const nextDoseTimeCellStyle = {
    ...cellStyle,
    width: '25%',
};

function GiveFPmodal({ clientDetails, onClose }) {
    const [treatmentPlanRows, setTreatmentPlanRows] = useState([{ drug: '', packaging: '', quantity: '', nextDoseDate: '', nextDoseTime: '' }]);
    const [drugSuggestions, setDrugSuggestions] = useState([]);
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(null);
    const [submitting, setSubmitting] = useState(false);
    const [nextDose, setNextDose] = useState('');
    const [token, setToken] = useState(clientDetails.token); // Get token from client details
    const [fpId, setFpId] = useState(clientDetails.fp_id); // Get FP ID from client details
    const suggestionsRef = useRef(null);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [selectedDrugIndex, setSelectedDrugIndex] = useState(null);
    const quantityRefs = useRef([]);
    const [isScrolling, setIsScrolling] = useState(false);
   
    const [nextDoseDate, setNextDoseDate] = useState('');


    // Handle wheel event for scrolling
    const handleWheel = (e) => {
        setIsScrolling(true);
        e.preventDefault();
    };

    const handleWheelEnd = () => {
        setIsScrolling(false);
    };

    useEffect(() => {
        window.addEventListener('wheel', handleWheel, { passive: false });
        window.addEventListener('wheel', handleWheelEnd, { passive: false });

        return () => {
            window.removeEventListener('wheel', handleWheel);
            window.removeEventListener('wheel', handleWheelEnd);
        };
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (suggestionsRef.current && !suggestionsRef.current.contains(event.target)) {
                setShowSuggestions(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    useEffect(() => {
        const fetchDrugs = async () => {
            try {
                const response = await fetch(urls.fetchdispensary2, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ token })
                });

                if (!response.ok) throw new Error('Error fetching drugs');

                const data = await response.json();
                setDrugSuggestions(data);
            } catch (error) {
                console.error('Error fetching drugs:', error);
            }
        };
        fetchDrugs();
    }, [token]);

    const handleDrugInputChange = (index, event) => {
        const value = event.target.value;
        
        const updatedRows = [...treatmentPlanRows];
        updatedRows[index].drug = value;
        setTreatmentPlanRows(updatedRows);
    
        if (!value) {
            setFilteredSuggestions([]);
            setActiveSuggestionIndex(null);
            return;
        }
    
        const filtered = drugSuggestions.filter(drug =>
            drug.Drug.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredSuggestions(filtered);
        setActiveSuggestionIndex(index);
        setShowSuggestions(true);
    };

    const handleSuggestionClick = (index, suggestion) => {
        const updatedRows = [...treatmentPlanRows];
        updatedRows[index].drug = suggestion.Drug;
        updatedRows[index].packaging = suggestion.Packaging;
        updatedRows[index].quantity = '';
        setTreatmentPlanRows(updatedRows);
        setFilteredSuggestions([]);
        setActiveSuggestionIndex(null);
        setSelectedDrugIndex(index);

        // Focus on the quantity input of the selected row
        if (quantityRefs.current[index]) {
            quantityRefs.current[index].focus();
        }
    };

    const handleDrugQuantityChange = (index, e) => {
        const { value } = e.target;
        const newQuantity = parseInt(value, 10) || 0;

        // Update the quantity in the state
        const updatedRows = [...treatmentPlanRows];
        updatedRows[index].quantity = newQuantity;
        setTreatmentPlanRows(updatedRows);

        // If Enter key is pressed
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevent default Enter key behavior (form submit)
            addNewRow();
            // Focus on the "Drug Name" input in the new row
            const nextIndex = index + 1;
            if (quantityRefs.current[nextIndex]) {
                const nextInput = quantityRefs.current[nextIndex].previousElementSibling; // Get the previous sibling (Drug Name input)
                if (nextInput) {
                    nextInput.focus(); // Focus on the Drug Name input
                }
            }
        }
    };

    const addNewRow = () => {
        const lastRow = treatmentPlanRows[treatmentPlanRows.length - 1];
        
        // Check if the current row's required fields are filled
        if (!lastRow.drug || !lastRow.quantity) {
            toast.error('Please fill out the drug and quantity fields before adding a new row.');
            return; // Prevent adding a new row if fields are incomplete
        }
        
        // Add a new row
        const newRow = { drug: '', packaging: '', quantity: '', nextDoseDate: '', nextDoseTime: '' };
        setTreatmentPlanRows([...treatmentPlanRows, newRow]);

        // Focus on the "Drug Name" input in the newly created row
        // Ensure that the new row index is correct
        const nextRowIndex = treatmentPlanRows.length;
        setTimeout(() => {
            const newDrugInput = document.querySelector(`.treatment-chat-table tr:nth-child(${nextRowIndex + 2}) .drug-chat-input`);
            if (newDrugInput) {
                newDrugInput.focus();
            }
        }, 0); // Use setTimeout to ensure the new row is rendered before trying to focus
    };

    const removeRow = (index) => {
        if (treatmentPlanRows.length > 1) {
            const updatedRows = treatmentPlanRows.filter((_, i) => i !== index);
            setTreatmentPlanRows(updatedRows);
        }
    };

    const handleTreatmentPlanSubmit = async () => {
        let errorMessage = '';
    
        // Show initial toast message to inform user
        toast.info(`Hello ${clientDetails.first_name}, please wait, we are submitting your family planning drugs.`);
    
        // Wait for 2 seconds before proceeding with the rest of the function
        await new Promise(resolve => setTimeout(resolve, 2000));
    
        // Check if all table cells are filled
        const incompleteRows = treatmentPlanRows.filter(row =>
            !row.drug || !row.quantity
        );
    
        if (incompleteRows.length > 0) {
            if (errorMessage) errorMessage += ' ';
            errorMessage += 'Some rows are incomplete. Please fill out all drug and quantity fields.';
        }
    
        // Ensure expiry date is filled if "On a specific date" is selected
        if (nextDose === 'On a specific date' && !nextDoseDate) {
            if (errorMessage) errorMessage += ' ';
            errorMessage += 'Please select the next appointment date.';
        }
    
        if (errorMessage) {
            toast.error(errorMessage, { autoClose: 60000 }); // Toast duration set to 60 seconds
            return;
        }
    
        setSubmitting(true);
    
        // Include the token, FP ID, and expiry date in the payload
        const payload = {
            fp_id: clientDetails.fp_id, // Family Planning ID
            first_name: clientDetails.first_name, // Client first name
            last_name: clientDetails.last_name.trim(), // Client last name (trimmed to remove extra spaces)
            full_name: `${clientDetails.first_name.trim()} ${clientDetails.last_name.trim()}`,
            age: clientDetails.age, // Client age
            sex: clientDetails.sex, // Client sex
            phone_number: clientDetails.phone_number, // Client phone number
            method: clientDetails.method, // Family Planning method
            treatment_plan: treatmentPlanRows, // Treatment plan details
            token, // Add token here
            next_appointment_date: nextDose === 'On a specific date' ? nextDoseDate : null, // Add next appointment date
        };
    
        try {
            const response = await fetch(urls.submitFPdrugs, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(payload),
            });
    
            const data = await response.json(); // Parse JSON body
    
            if (!response.ok) {
                // If response is not ok, throw the error message from the response
                const backendErrorMessage = data.error || 'Something went wrong.';
                throw new Error(backendErrorMessage);
            }
    
            if (data.success) {
                // Wait for 2 seconds before showing success toast
                setTimeout(async () => {
                    toast.success('Success! Family planning drugs submitted.');
    
                    // Send the same payload to urls.assignAverage
                    try {
                        const assignResponse = await fetch(urls.assignAverage3, {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify(payload),
                        });
    
                        const assignData = await assignResponse.json();
    
                        console.log('Response from assignAverage:', assignData);
                    } catch (assignError) {
                        console.error('Error sending payload to assignAverage:', assignError);
                    }
    
                    // Delay closing the modal by 5 seconds
                    setTimeout(() => {
                        onClose();
                    }, 5000);
                }, 2000);
            }
        } catch (error) {
            console.error('Error submitting treatment plan:', error);
            // Wait for 2 seconds before showing the actual error toast
            setTimeout(() => {
                toast.error(`Error: ${error.message}`);
            }, 2000);
        } finally {
            setSubmitting(false);
        }
    };
    
    return (
        <div
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100vw',
                height: '100vh',
                backgroundColor: 'rgba(0, 0, 0, 0.8)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 1000,
            }}
        >
            <div
                style={{
                    position: 'relative',
                    width: '90%',
                    maxWidth: '1200px',
                    height: '90%',
                    backgroundColor: 'white',
                    borderRadius: '0px',
                    overflowY: 'auto',
                    padding: '20px',
                    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.3)',
                }}
            >
                <ToastContainer />
                <h3 style={{ textAlign: 'center', marginBottom: '20px' }}>
                    Record Drugs You Have Used During Family Planning Provision
                </h3>

                <p><strong>Client Name:</strong> {clientDetails.first_name} {clientDetails.last_name}</p>
                <p><strong>Age:</strong> {clientDetails.age}</p>
                <p><strong>Sex:</strong> {clientDetails.sex}</p>
                <p><strong>Phone Number:</strong> {clientDetails.phone_number}</p>
                <p><strong>Family Planning Method Given:</strong> {clientDetails.method}</p>

                <div>
                    <p style={{ fontSize: 'small', color: 'red', marginBottom: '13px' }}>
                        Please chat everything you have given to this patient, however small, be it a syringe or cannula, to enable the system to make more accurate calculations.
                    </p>
                </div>

                <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '20px' }}>
                    <thead>
                        <tr>
                            <th style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>Drug Name</th>
                            <th style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>Packaging</th>
                            <th style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>Quantity</th>
                            <th style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {treatmentPlanRows.map((row, index) => {
                            const isDrugSelected = index === selectedDrugIndex;
                            const quantityInputStyle = {
                                pointerEvents: isDrugSelected ? 'auto' : 'none',
                                opacity: isDrugSelected ? 1 : 0.8,
                            };

                            return (
                                <tr key={index}>
                                    <td style={{ position: 'relative', padding: '10px', borderBottom: '1px solid #ddd' }}>
                                        <input
                                            type="text"
                                            style={{ width: '100%', padding: '5px' }}
                                            value={row.drug}
                                            onChange={e => handleDrugInputChange(index, e)}
                                            onFocus={() => setShowSuggestions(true)}
                                        />
                                        {index === activeSuggestionIndex && filteredSuggestions.length > 0 && showSuggestions && (
                                            <div style={{ position: 'absolute', top: '100%', left: 0, width: '100%', backgroundColor: 'white', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)', zIndex: 10 }}>
                                                <ul style={{ listStyle: 'none', margin: 0, padding: 0 }}>
                                                    {filteredSuggestions.map((suggestion, i) => (
                                                        <li
                                                            key={i}
                                                            style={{ padding: '10px', cursor: 'pointer' }}
                                                            onClick={() => handleSuggestionClick(index, suggestion)}
                                                        >
                                                            {suggestion.Drug} - {suggestion.Packaging} (Available: {suggestion.Quantity})
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        )}
                                    </td>
                                    <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>{row.packaging}</td>
                                    <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>
                                        <input
                                            type="number"
                                            style={{ width: '100%', padding: '5px', ...quantityInputStyle }}
                                            value={row.quantity}
                                            onChange={e => handleDrugQuantityChange(index, e)}
                                            ref={el => quantityRefs.current[index] = el}
                                        />
                                    </td>
                                    <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>
                                        <button
                                            type="button"
                                            style={{ marginRight: '5px', padding: '5px 10px', cursor: 'pointer' }}
                                            onClick={addNewRow}
                                            disabled={treatmentPlanRows.length >= 10}
                                        >
                                            +
                                        </button>
                                        <button
                                            type="button"
                                            style={{ padding: '5px 10px', cursor: 'pointer', backgroundColor: 'red', color: 'white' }}
                                            onClick={() => removeRow(index)}
                                        >
                                            x
                                        </button>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>

                <div>
                    <h3 style={{ fontSize: '14px', marginBottom: '10px' }}>
                        If the family planning method given has an expiry date, please select the next appointment date for this client
                    </h3>
                    <label style={{ display: 'block', marginBottom: '10px' }}>
                        <input
                            type="radio"
                            name="Expiry date"
                            value="On a specific date"
                            checked={nextDose === 'On a specific date'}
                            onChange={() => setNextDose('On a specific date')}
                        />
                        On a specific date
                    </label>
                    {nextDose === 'On a specific date' && (
                        <div>
                            <label style={{ display: 'block', marginBottom: '10px' }}>
                                Date:
                                <input
                                    type="date"
                                    style={{ marginLeft: '10px' }}
                                    value={nextDoseDate}
                                    onChange={(e) => setNextDoseDate(e.target.value)}
                                />
                            </label>
                        </div>
                    )}
                </div>

                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                    <button
                        type="button"
                        style={{ padding: '10px 20px', marginRight: '10px', cursor: 'pointer', backgroundColor: '#007BFF', color: 'white', border: 'none', borderRadius: '5px' }}
                        onClick={handleTreatmentPlanSubmit}
                        disabled={submitting}
                    >
                        {submitting ? 'Submitting...' : 'Submit Chat'}
                    </button>
                    <button
                        type="button"
                        style={{ padding: '10px 20px', cursor: 'pointer', backgroundColor: 'red', color: 'white', border: 'none', borderRadius: '5px' }}
                        onClick={onClose}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
}

export default GiveFPmodal;
