import React, { useState, useEffect } from 'react';
import { urls } from './config.dev';
import './Patientappointments.css';
import { faCalendarPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';

function PatientAppointments() {
  const [appointments, setAppointments] = useState([]);
  const [newAppointment, setNewAppointment] = useState({
    patientName: '',
    appointmentReason: '',
    appointmentMessage: '',
    appointmentDate: ''
  });
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [suggestions, setSuggestions] = useState([]);
  const [showScheduleAppointmentPrompt, setShowScheduleAppointmentPrompt] = useState(false);
  const [searchError, setSearchError] = useState('');
  const [confirmingAppointment, setConfirmingAppointment] = useState(false);
  const [showAllAppointments, setShowAllAppointments] = useState(false); // State to toggle between seeing all appointments and only for today
  const navigate = useNavigate();
  const [employeeName, setEmployeeName] = useState('');


  useEffect(() => {
    const fetchTokenAndCheckSecurity = async () => {
      try {
        const params = new URLSearchParams(window.location.search);
        const tokenFromUrl = params.get('token');
  
        console.log('URL Token:', tokenFromUrl);
  
        const securityResponse = await fetch(urls.security, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token: tokenFromUrl }),
        });
  
        if (securityResponse.ok) {
          const securityData = await securityResponse.json();
  
          if (securityData.message === 'Session valid') {
            // Assuming you have a setEmployeeName function and similar for other needed data
            setEmployeeName(securityData.employee_name);
            localStorage.setItem('token', securityData.clinic_session_token);
            fetchAppointments(tokenFromUrl);
          } else if (securityData.error === 'Session expired') {
            navigate(`/dashboard?token=${securityData.clinic_session_token}`);
          } else {
            navigate('/login');
          }
        } else {
          throw new Error('Failed to perform security check');
        }
      } catch (error) {
        console.error('Error performing security check:', error);
        navigate('/login');
      }
    };
  
    fetchTokenAndCheckSecurity();
  }, [navigate]);
  

  const fetchAppointments = async () => {
    try {
      const params = new URLSearchParams(window.location.search);
      const tokenFromUrl = params.get('token');
  
      let url = showAllAppointments ? `${urls.appointments}?filter=all` : urls.appointments;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ token: tokenFromUrl })  // Include token in the body
      });
  
      if (response.ok) {
        const data = await response.json();
        setAppointments(data); // Handle appointments data
      } else {
        throw new Error('Failed to fetch appointments');
      }
    } catch (error) {
      console.error('Error fetching appointments:', error);
    }
  };
  

  useEffect(() => {
    fetchAppointments();
  }, [showAllAppointments]); // Refetch appointments when the toggle state changes

  // Rest of the code remains the same

  // Function to toggle between seeing all appointments and only appointments for today
  const toggleAppointments = () => {
    setShowAllAppointments(!showAllAppointments);
  };


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewAppointment(prevState => ({
      ...prevState,
      [name]: value
    }));
    if (name === 'patientName') {
      fetchSuggestions(value);
    }
  };

  const fetchSuggestions = async (partialName) => {
    try {
        const params = new URLSearchParams(window.location.search);
        const tokenFromUrl = params.get('token'); // Fetch the token from the URL

        // Log the values to ensure they are correct before sending the request
        console.log('name:', partialName);
        console.log('token:', tokenFromUrl);

        // Construct the URL with query parameters
        const url = `${urls.suggest}?name=${encodeURIComponent(partialName)}&token=${encodeURIComponent(tokenFromUrl)}`;

        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (response.ok) {
            const data = await response.json();
            if (data.message) {
                setSearchError(data.message);
                setSuggestions([]);
            } else {
                setSearchError('');
                setSuggestions(data);
            }
        } else {
            throw new Error('Failed to fetch suggestions');
        }
    } catch (error) {
        console.error('Error fetching suggestions:', error);
    }
};



  const handleSelectSuggestion = (suggestion) => {
    setSelectedPatient(suggestion);
    setNewAppointment({
      patientName: `${suggestion.first_name} ${suggestion.last_name}`,
      appointmentReason: '',
      appointmentMessage: '',
      appointmentDate: '' // Clear appointment date when a suggestion is selected
    });
    setSuggestions([]);
  };

  const handleConfirmAppointment = async () => {
    // Check if all fields are filled
    if (
      !newAppointment.patientName ||
      !newAppointment.appointmentReason ||
      !newAppointment.appointmentMessage ||
      !newAppointment.appointmentDate
    ) {
      alert('Please fill in all fields before confirming the appointment.');
      return;
    }
  
    // Validate date format (YYYY-MM-DD)
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
    if (!dateRegex.test(newAppointment.appointmentDate)) {
      alert('Invalid date format. Please use YYYY-MM-DD format.');
      return;
    }
  
    setConfirmingAppointment(true); // Start confirming appointment
  
    try {
      const params = new URLSearchParams(window.location.search);
      const tokenFromUrl = params.get('token'); // Fetch the token from the URL
  
      const payload = {
        ...newAppointment,
        phoneNumber: selectedPatient.phone_number,
        age: selectedPatient.age,
        sex: selectedPatient.sex,
        religion: selectedPatient.religion,
        dob: selectedPatient.dob,
        token: tokenFromUrl // Include the token in the payload
      };
  
      const response = await fetch(urls.confirmappointment, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      });
  
      if (response.ok) {
        // Appointment confirmed successfully
        const data = await response.json();
        // Show alert with message received from the server
        alert(data.message);
        // Reset newAppointment state to clear the form
        setNewAppointment({
          patientName: '',
          appointmentReason: '',
          appointmentMessage: '',
          appointmentDate: ''
        });
        // Refetch appointments to update the list
        fetchAppointments();
        // Hide the schedule appointment prompt
        setShowScheduleAppointmentPrompt(false);
      } else {
        throw new Error('Failed to schedule appointment');
      }
    } catch (error) {
      console.error('Error confirming appointment:', error);
    } finally {
      setConfirmingAppointment(false); // End confirming appointment
    }
  };
  
  return (
    <div>
      <button onClick={() => setShowScheduleAppointmentPrompt(true)} style={{ padding: '10px 20px', backgroundColor: '#007bff', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer', marginRight: '10px' }}>
        <FontAwesomeIcon icon={faCalendarPlus} /> Schedule New Appointment
      </button>
      <button onClick={toggleAppointments} style={{ padding: '10px 20px', backgroundColor: '#28a745', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>
        {showAllAppointments ? 'See Only for Today' : 'See All Appointments'}
      </button>
  
      {showScheduleAppointmentPrompt && (
        <div style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 999 }}>
          <div style={{ backgroundColor: '#fff', padding: '20px', borderRadius: '8px', width: '60%', maxWidth: '800px', boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)' }}>
            <div className="transaction-prompt">
              <h2>Schedule New Appointment</h2>
              <input 
                type="text" 
                name="patientName" 
                placeholder="Patient Name" 
                value={newAppointment.patientName} 
                onChange={handleInputChange} 
                style={{ width: '100%', padding: '8px', marginBottom: '10px', borderRadius: '5px', border: '1px solid #ddd' }}
              />
              <ul>
                {searchError ? (
                  <li>{searchError}</li>
                ) : (
                  suggestions.map((suggestion, index) => (
                    <li key={index} onClick={() => handleSelectSuggestion(suggestion)} style={{ padding: '8px', cursor: 'pointer', borderBottom: '1px solid #ddd' }}>
                      {`${suggestion.first_name} ${suggestion.last_name}`}
                    </li>
                  ))
                )}
              </ul>
  
              {selectedPatient && (
                <div>
                  <label htmlFor="phoneNumber" style={{ display: 'block', marginTop: '10px' }}>Phone Number:</label>
                  <input 
                    type="text" 
                    id="phoneNumber" 
                    name="phone_number" 
                    placeholder="Phone Number" 
                    value={selectedPatient.phone_number} 
                    readOnly 
                    style={{ width: '100%', padding: '8px', marginBottom: '10px', borderRadius: '5px', border: '1px solid #ddd' }}
                  />
                  <label htmlFor="age" style={{ display: 'block', marginTop: '10px' }}>Age:</label>
                  <input 
                    type="text" 
                    id="age" 
                    name="age" 
                    placeholder="Age" 
                    value={selectedPatient.age} 
                    readOnly 
                    style={{ width: '100%', padding: '8px', marginBottom: '10px', borderRadius: '5px', border: '1px solid #ddd' }}
                  />
                  <label htmlFor="sex" style={{ display: 'block', marginTop: '10px' }}>Sex:</label>
                  <input 
                    type="text" 
                    id="sex" 
                    name="sex" 
                    placeholder="Sex" 
                    value={selectedPatient.sex} 
                    readOnly 
                    style={{ width: '100%', padding: '8px', marginBottom: '10px', borderRadius: '5px', border: '1px solid #ddd' }}
                  />
                  <label htmlFor="religion" style={{ display: 'block', marginTop: '10px' }}>Religion:</label>
                  <input 
                    type="text" 
                    id="religion" 
                    name="religion" 
                    placeholder="Religion" 
                    value={selectedPatient.religion} 
                    readOnly 
                    style={{ width: '100%', padding: '8px', marginBottom: '10px', borderRadius: '5px', border: '1px solid #ddd' }}
                  />
                  <label htmlFor="dob" style={{ display: 'block', marginTop: '10px' }}>Date of Birth:</label>
                  <input 
                    type="text" 
                    id="dob" 
                    name="dob" 
                    placeholder="Date of Birth" 
                    value={selectedPatient.dob} 
                    readOnly 
                    style={{ width: '100%', padding: '8px', marginBottom: '10px', borderRadius: '5px', border: '1px solid #ddd' }}
                  />
                </div>
              )}
  
              <input 
                type="text" 
                name="appointmentReason" 
                placeholder="Appointment Reason" 
                value={newAppointment.appointmentReason} 
                onChange={handleInputChange} 
                style={{ width: '100%', padding: '8px', marginBottom: '10px', borderRadius: '5px', border: '1px solid #ddd' }}
              />
              <input 
                type="date" 
                name="appointmentDate" 
                placeholder="Appointment Date" 
                value={newAppointment.appointmentDate} 
                onChange={handleInputChange} 
                style={{ width: '100%', padding: '8px', marginBottom: '10px', borderRadius: '5px', border: '1px solid #ddd' }}
              />
              <textarea 
                name="appointmentMessage" 
                placeholder="Appointment Message" 
                value={newAppointment.appointmentMessage} 
                onChange={handleInputChange}
                style={{ width: '100%', padding: '8px', marginBottom: '10px', borderRadius: '5px', border: '1px solid #ddd' }}
              ></textarea>
  
              <div className="button-group" style={{ display: 'flex', justifyContent: 'space-between', marginTop: '16px' }}>
                <button onClick={() => setShowScheduleAppointmentPrompt(false)} style={{ padding: '10px 20px', backgroundColor: '#dc3545', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>
                  <FontAwesomeIcon icon={faTimes} /> Cancel
                </button>
                {confirmingAppointment ? (
                  <span>Please wait...</span>
                ) : (
                  <button onClick={handleConfirmAppointment} style={{ padding: '10px 20px', backgroundColor: '#007bff', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>
                    Confirm Appointment
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
  
      <div>
        <h2>PATIENTS/CLIENTS WITH APPOINTMENTS TODAY</h2>
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '16px', padding: '20px' }}>
  {appointments.map((appointment) => (
    <div 
      key={appointment.appointment_id} 
      style={{
        backgroundColor: '#fff', 
        border: '1px solid #ddd', 
        borderRadius: '8px', 
        padding: '16px', 
        width: '30%', 
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)', 
        transition: 'transform 0.2s ease-in-out',
        maxHeight: '400px', // Set a fixed max height
        overflowY: 'auto'  // Allow scrolling when content exceeds the height
      }}
    >
      <div style={{ fontSize: '1.2em', fontWeight: 'bold', marginBottom: '10px' }}>
        {`${appointment.first_name} ${appointment.last_name}`}
      </div>
      <div>
        <p><strong>Sex:</strong> {appointment.sex}</p>
        <p><strong>Age:</strong> {appointment.age}</p>
        <p><strong>Phone Number:</strong> {appointment.phone_number}</p>
        <p><strong>Appointment Date:</strong> {appointment.date_of_appointment}</p>
        <p><strong>Reason:</strong> {appointment.appointment_reason}</p>
        <p><strong>Message:</strong> {appointment.appointment_message}</p>
        <p><strong>Reminded:</strong> {appointment.reminded}</p>
      </div>
    </div>
  ))}
</div>

      </div>
    </div>
  );
  
}

export default PatientAppointments;

