import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { urls } from "./config.dev"; // Import the backend URLs

function MissingDrugs({ token }) {
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchMissingDrugs = () => {
      fetch(urls.fetchmissingdrugs, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.missing_drugs && data.missing_drugs.length > 0) {
            displayMissingDrugsToast(data.missing_drugs);
            setError(false);
          }
          // If `missing_drugs` is empty, do nothing (no toast)
        })
        .catch((err) => {
          console.error("Error fetching missing drugs:", err);
          setError(true);
          toast.error("Something went wrong. Please try again.", {
            position: "bottom-right",
            autoClose: 5000,
            closeOnClick: true,
          });
        });
    };

    fetchMissingDrugs();
  }, [token]);

  const displayMissingDrugsToast = (drugs) => {
    const toastMessage = (
      <div style={{ maxHeight: "300px", overflowY: "auto", whiteSpace: "normal" }}>
        <strong>
          The following drugs are either out of stock or soon getting out of stock:
        </strong>
        <div style={{ marginTop: "10px", paddingLeft: "20px" }}>
          {drugs.map((drug, index) => (
            <div key={index} style={{ marginBottom: "5px" }}>
              {drug.total_quantity === 0 ? (
                <>
                  <strong>{drug.packaging}</strong> of{" "}
                  <strong>{drug.drug_name}</strong> is completely out of stock!
                </>
              ) : (
                <>
                  Only <strong>{drug.total_quantity}</strong> of{" "}
                  <strong>{drug.packaging}</strong> of{" "}
                  <strong>{drug.drug_name}</strong> is remaining!
                </>
              )}
            </div>
          ))}
        </div>
      </div>
    );

    toast.warning(toastMessage, {
      position: "bottom-right",
      autoClose: 5000,
      closeOnClick: true,
      bodyClassName: "toast-body-custom", // Apply custom class for body styling
    });
  };

  return (
    <div>
      <ToastContainer
        toastStyle={{
          backgroundColor: "#ff8c00", // Orange background for better contrast
          color: "#fff", // White text for readability
          fontSize: "16px", // Make the text slightly larger
          maxWidth: "400px", // Adjust the width of the toast
        }}
      />
    </div>
  );
}

export default MissingDrugs;
