import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Topbar.css';
import axios from 'axios';
import { urls } from './config.dev';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faTimes, faSignOutAlt, faStar as faSolidStar, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import NetworkStrength from './NetworkStrength';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'; 
function getStartOfMonth() {
  const today = new Date();
  return new Date(today.getFullYear(), today.getMonth(), 1).toISOString().split('T')[0];
}

function getEndOfMonth() {
  const today = new Date();
  return new Date(today.getFullYear(), today.getMonth() + 1, 0).toISOString().split('T')[0];
}
const CriticalNotification = () => {
  return (
    <div className="critical-notification">
      <FontAwesomeIcon icon={faExclamationTriangle} />
      <span>CRITICAL Patient Waiting</span>
    </div>
  );
};

function Topbar({ token }) {
  const [permissions, setPermissions] = useState([]);
  const [performanceData, setPerformanceData] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState('');
  const [message, setMessage] = useState('');
  const [dataAvailable, setDataAvailable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isRadiographerDropdownOpen, setIsRadiographerDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const [isCashierDropdownOpen, setIsCashierDropdownOpen] = useState(false);
  const [isDoctorDropdownOpen, setIsDoctorDropdownOpen] = useState(false);
  const [isCritical, setIsCritical] = useState(false);
  const [appointments, setAppointments] = useState([]);
  const [currentAppointmentIndex, setCurrentAppointmentIndex] = useState(0);
  const [displayDuration, setDisplayDuration] = useState(60); // seconds
  const [displayingReminder, setDisplayingReminder] = useState(false);
const toggleCashierDropdown = (e) => {
  e.stopPropagation(); // Prevent click from propagating to button
  setIsCashierDropdownOpen(!isCashierDropdownOpen);
};

const handleCashierNavigation = () => {
  navigate(`/sales?token=${token}`);
};

const handleSalesPageNavigation = () => {
  navigate(`/salespage?token=${token}`);
};

const handleAwaitingPaymentsNavigation = () => {
  navigate(`/awaitingpayments?token=${token}`);
};
// Event handler to toggle the Doctor dropdown
const toggleDoctorDropdown = (e) => {
  e.stopPropagation(); // Prevent click from propagating to button
  setIsDoctorDropdownOpen(!isDoctorDropdownOpen);
};

// Event handlers for Doctor dropdown options
const handleDoctorNavigation = () => {
  navigate(`/access-doctors-room?token=${token}`);
};

const handleAttendToPatientNavigation = () => {
  navigate(`/attend-to-new-patient?token=${token}`);
};

const handleViewExistingFilesNavigation = () => {
  navigate(`/patientfiles?token=${token}`);
};
// Add state to control Lab dropdown visibility
const [isLabDropdownOpen, setIsLabDropdownOpen] = useState(false);

// Event handler to toggle the Lab dropdown
const toggleLabDropdown = (e) => {
  e.stopPropagation(); // Prevent click from propagating to button
  setIsLabDropdownOpen(!isLabDropdownOpen);
};

// Event handlers for Lab dropdown options
const handleLabNavigation = () => {
  navigate(`/access-laboratory?token=${token}`);
};

const handlePendingLabTestsNavigation = () => {
  navigate(`/lab?token=${token}`);
};
// Add state to control Nurse dropdown visibility
const [isNurseDropdownOpen, setIsNurseDropdownOpen] = useState(false);

// Event handler to toggle the Nurse dropdown
const toggleNurseDropdown = (e) => {
  e.stopPropagation(); // Prevent click from propagating to button
  setIsNurseDropdownOpen(!isNurseDropdownOpen);
};

// Event handlers for Nurse dropdown options
const handleNurseNavigation = () => {
  navigate(`/access-nurse?token=${token}`);
};

const handlePatientFilesNavigation = () => {
  navigate(`/patientfiles2?token=${token}`);
};

// Add state to control Dispensary dropdown visibility
const [isDispensaryDropdownOpen, setIsDispensaryDropdownOpen] = useState(false);

// Event handler to toggle the Dispensary dropdown
const toggleDispensaryDropdown = (e) => {
  e.stopPropagation(); // Prevent click from propagating to button
  setIsDispensaryDropdownOpen(!isDispensaryDropdownOpen);
};

// Event handlers for Dispensary dropdown options
const handleDispensaryNavigation = () => {
  navigate(`/selldrugs?token=${token}`);
};

const handleNonSaleStockRemovalNavigation = () => {
  navigate(`/remove-drugs?token=${token}`);
};

const handleSoldDrugsOverviewNavigation = () => {
  navigate(`/dispensed-and-sold?token=${token}`);
};

const handleNonSoldRemovedDrugsOverviewNavigation = () => {
  navigate(`/removed-drugs-equipment?token=${token}`);
};
const [isHomeDropdownOpen, setIsHomeDropdownOpen] = useState(false);

// Event handler to toggle the Home dropdown
const toggleHomeDropdown = (e) => {
  e.stopPropagation(); // Prevent click from propagating to button
  setIsHomeDropdownOpen(!isHomeDropdownOpen);
};


  const startDate = getStartOfMonth();
  const endDate = getEndOfMonth();

  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        const response = await fetch(urls.fetchpermissions, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token }),
        });

        if (response.ok) {
          const data = await response.json();
          setPermissions(data.permissions || []);
          fetchPerformanceData(token, data.name);
        } else {
          console.error('Failed to fetch permissions');
        }
      } catch (error) {
        console.error('Error fetching permissions:', error);
      }
    };

    fetchPermissions();
  }, [token]);

  const fetchPerformanceData = async (token, employeeName) => {
    try {
      setLoading(true);
      const response = await fetch(urls.fetchperformance, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token,
          startDate,
          endDate,
          section: 'all',
        }),
      });

      const data = await response.json();

      if (data.error) {
        setMessage(data.error);
        setDataAvailable(false);
      } else {
        data.sort((a, b) => b.percentage - a.percentage);

        const loggedEmployee = data.find(emp => emp.employee_name === employeeName);
        const employeePosition = data.findIndex(emp => emp.employee_name === employeeName) + 1;

        if (loggedEmployee) {
          let stars = 0;
          if (employeePosition === 1) stars = 5;
          else if (employeePosition === 2) stars = 4;
          else if (employeePosition === 3) stars = 3;
          else if (employeePosition === 4) stars = 2;
          else if (employeePosition === 5) stars = 1;

          setSelectedEmployee(loggedEmployee.employee_name);
          setMessage(stars);
          setDataAvailable(true);
        } else {
          setMessage('Logged-in employee not found in the performance data');
          setDataAvailable(false);
        }
      }
    } catch (error) {
      console.error('Error fetching performance data:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchHomeToken = async () => {
    try {
      const response = await fetch(urls.dashboardtoken, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token }),
      });

      if (response.ok) {
        const data = await response.json();
        return data.token;
      } else {
        console.error('Failed to fetch home token');
        return null;
      }
    } catch (error) {
      console.error('Error fetching home token:', error);
      return null;
    }
  };

  const handleHomeNavigation = async () => {
    const newToken = await fetchHomeToken();
    if (newToken) {
      navigate(`/dashboard?token=${newToken}`);
    }
  };

  const handleLogout = async () => {
    try {
      const response = await fetch(urls.logout, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token }),
      });

      if (response.ok) {
        navigate('/login');
      } else {
        console.error('Failed to log out');
      }
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const permissionRoutes = {
    cashier: '/sales',
    billing: '/credits',
    'drug-shelves': '/selldrugs',
    radiographer: '/access-radiographer',
    store: '/store',
    doctor: '/access-doctors-room',
    lab: '/access-laboratory',
    nurse: '/access-nurse',
    'drug-settings': '/manageDrugs',
  };

  const permissionLabels = {
    cashier: 'Cashier',
    billing: 'Billing',
    'drug-shelves': 'Dispensary',
    radiographer: 'Radiographer',
    store: 'Store',
    doctor: 'Doctor',
    lab: 'Lab',
    nurse: 'Nurse',
    'drug-settings': 'Drug Settings',
  };

  const permissionMap = {
    cashier: 'sales',
    'drug-shelves': 'selldrugs',
    radiographer: 'access-radiographer',
    store: 'store',
    doctor: 'access-doctors-room',
    lab: 'access-laboratory',
    nurse: 'access-nurse',
    'drug-settings': 'managedrugs',
  };

  const handleNavigation = (path) => {
    navigate(`${path}?token=${token}`);
  };

  const handlePerformanceClick = () => {
    navigate(`/employeePerformance?token=${token}`);
  };

  const handleRadiographerClick = () => {
    navigate('/access-radiographer?token=${token}');
  };

  const toggleRadiographerDropdown = (e) => {
    e.stopPropagation(); // Prevent click from propagating to button
    setIsRadiographerDropdownOpen(!isRadiographerDropdownOpen);
  };

  const handleRadiographerNavigation = () => {
    navigate(`/access-radiographer?token=${token}`);
  };

  const handleRadiologyNavigation = () => {
    navigate(`/radiology?token=${token}`);
  };
  useEffect(() => {
    let notificationInterval, displayNotification;
  
    const checkCriticalPatients = () => {
      if (permissions.includes('access-doctors-room')) {
        fetch(urls.checkcritical, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token }),
        })
          .then(response => response.json())
          .then(data => {
            if (data.response === 'yes') {
              setIsCritical(true);
            } else {
              setIsCritical(false);
              clearInterval(notificationInterval); // Stop checking if the response is different
              clearInterval(displayNotification);  // Also stop the notification display
            }
          })
          .catch(error => console.error('Error:', error));
      }
    };
  
    // Initial check
    checkCriticalPatients();
  
    // Set intervals for updating notification and checking the backend
    notificationInterval = setInterval(() => {
      checkCriticalPatients();
    }, 10000); // Check every 10 seconds
  
    // Logic for showing and hiding the notification
    const toggleNotification = () => {
      setIsCritical(prev => !prev);
    };
  
    displayNotification = setInterval(() => {
      if (isCritical) {
        toggleNotification();
        setTimeout(() => {
          toggleNotification();
        }, 5000); // Hide after 5 seconds
      }
    }, 10000); // Show and hide every 10 seconds
  
    return () => {
      clearInterval(notificationInterval);
      clearInterval(displayNotification);
    };
  }, [permissions, token, isCritical]);
  
  useEffect(() => {
    const fetchAppointments = async () => {
        try {
            // Get the current time in Kampala timezone
            const now = new Date();
            const kampalaTime = new Date(
                now.toLocaleString("en-US", { timeZone: "Africa/Kampala" })
            );

            // Check if the time is between 9 PM and 6 AM
            const hours = kampalaTime.getHours();
            if (hours >= 21 || hours < 6) {
                console.log("It is between 9 PM and 6 AM in Kampala. Backend call skipped.");
                return;
            }

            const response = await fetch(urls.appointments, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ token }), // Include the token from the props
            });

            if (response.ok) {
                const data = await response.json();
                // Check if data contains valid appointments
                if (data && data.length > 0) {
                    setAppointments(data);

                    // Check if the first appointment has not been reminded yet
                    if (data[0].reminded === "no") {
                        setDisplayingReminder(true);
                    }

                    // Store the total number of reminders from the backend response
                    setTotalReminders(data.filter(app => app.reminded === "no").length);
                } else {
                    console.warn("No valid appointment data received.");
                }
            } else {
                throw new Error('Failed to fetch appointments');
            }
        } catch (error) {
            console.error('Error fetching appointments:', error);
        }
    };

    fetchAppointments();
}, [urls.appointments, token]);


const handleReminderClick = async () => {
  const currentAppointment = appointments[currentAppointmentIndex]; // Get the current appointment
  const confirmed = window.confirm(`Are you sure you have contacted ${currentAppointment.first_name} ${currentAppointment.last_name}?`);
  
  if (confirmed) {
      try {
          const response = await fetch(urls.deletereminder, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json', // Ensures the payload is JSON
              },
              body: JSON.stringify({
                  appointment_id: currentAppointment.appointment_id, // Include the appointment_id
                  token, // Include the token from the parent component
              }), // Sending data as JSON in the body
          });

          // Check if the response is successful
          if (response.ok) {
              // Update appointments state to remove the contacted reminder
              setAppointments(prev => prev.filter(app => app.appointment_id !== currentAppointment.appointment_id));

              // Hide the reminder after successful deletion
              setDisplayingReminder(false);

              // Update total reminders count
              setTotalReminders(prev => prev - 1);
          } else {
              console.error('Failed to delete reminder:', response);
          }
      } catch (error) {
          console.error('Error deleting reminder:', error);
      }
  }
};


// Add a state to hold the total number of reminders
const [totalReminders, setTotalReminders] = useState(0);

 
  
  return (
    <div className="topbar">
         <NetworkStrength />
       <div>
      {/* Render the notification if critical */}
      {isCritical && <CriticalNotification />}
    </div>
      {/* Home Button with Dropdown */}
      <div className="topbar-dropdown">
        <button
          className="topbar-button"
          onClick={handleHomeNavigation}
        >
          Home
          <FontAwesomeIcon
            icon={faCaretDown}
            className="topbar-button-icon"
            onClick={toggleHomeDropdown}
          />
        </button>

        {/* Dropdown Menu for Home Button */}
        {isHomeDropdownOpen && (
          <div className="dropdown-menu">
            <button onClick={handleLogout} className="dropdown-item">
              <FontAwesomeIcon icon={faSignOutAlt} className="logout-icon" />
              Logout
            </button>
          </div>
        )}
      </div>

      {Object.keys(permissionRoutes).map((key) => {
        if (key === 'appointments') return null;

        const isActive =
          key === 'billing' ||
    
          (permissionMap[key] && permissions.includes(permissionMap[key]));
          return (
            key !== 'performance' && (
              <div key={key} className="topbar-dropdown">
                {key === 'radiographer' ? (
                <button
                className={`topbar-button ${isActive ? '' : 'inactive'}`}
                onClick={isActive ? handleRadiographerNavigation : null}
                disabled={!isActive}
              >
                {permissionLabels[key] || 'Unknown'}
                <FontAwesomeIcon
                  icon={isActive ? faCaretDown : faLock} // Conditionally render icon
                  className={`topbar-button-icon ${!isActive ? 'red-icon' : ''}`} 
                  onClick={isActive ? toggleRadiographerDropdown : null} // Only attach the onClick if active
                />
              </button>
                    ) :key === 'drug-shelves' ? (
                    <button
                      className={`topbar-button ${isActive ? '' : 'inactive'}`}
                      onClick={isActive ? handleDispensaryNavigation : null}
                      disabled={!isActive}
                      >
                      {permissionLabels[key] || 'Unknown'}
                      <FontAwesomeIcon
                        icon={isActive ? faCaretDown : faLock} // Conditionally render icon
                        className={`topbar-button-icon ${!isActive ? 'red-icon' : ''}`} 
                        
                        onClick={isActive ? toggleDispensaryDropdown : null} // Only attach the onClick if active
                      />
                    </button>
                  ) :key === 'nurse' ? (
                    <button
                      className={`topbar-button ${isActive ? '' : 'inactive'}`}
                      onClick={isActive ? handleNurseNavigation : null}
                      disabled={!isActive}
                      >
                      {permissionLabels[key] || 'Unknown'}
                      <FontAwesomeIcon
                        icon={isActive ? faCaretDown : faLock} // Conditionally render icon
                        className={`topbar-button-icon ${!isActive ? 'red-icon' : ''}`} 
                        onClick={isActive ? toggleNurseDropdown : null} // Only attach the onClick if active
                      />
                    </button>
                ) : key === 'cashier' ? (
                  <button
                    className={`topbar-button ${isActive ? '' : 'inactive'}`}
                    onClick={isActive ? handleCashierNavigation : null}
                    disabled={!isActive}
                  >
                    {permissionLabels[key] || 'Unknown'}
  <FontAwesomeIcon
    icon={isActive ? faCaretDown : faLock} // Conditionally render icon
    className={`topbar-button-icon ${!isActive ? 'red-icon' : ''}`} 
    onClick={isActive ? toggleCashierDropdown : null} // Only attach the onClick if active
  />
                  </button>
                ) : key === 'doctor' ? (
                  <button
                    className={`topbar-button ${isActive ? '' : 'inactive'}`}
                    onClick={isActive ? handleDoctorNavigation : null}
                    disabled={!isActive}
                    >
                    {permissionLabels[key] || 'Unknown'}
                    <FontAwesomeIcon
                      icon={isActive ? faCaretDown : faLock} // Conditionally render icon
                      className={`topbar-button-icon ${!isActive ? 'red-icon' : ''}`} 
                      onClick={isActive ? toggleDoctorDropdown : null} // Only attach the onClick if active
                    />
                  </button>
                ) : key === 'lab' ? (
                  <button
                    className={`topbar-button ${isActive ? '' : 'inactive'}`}
                    onClick={isActive ? handleLabNavigation : null}
                    disabled={!isActive}
                    >
                    {permissionLabels[key] || 'Unknown'}
                    <FontAwesomeIcon
                      icon={isActive ? faCaretDown : faLock} // Conditionally render icon
                      className={`topbar-button-icon ${!isActive ? 'red-icon' : ''}`} 
                      onClick={isActive ? toggleLabDropdown : null} // Only attach the onClick if active
                    />
                  </button>
                ) : (
                  <button
                    className={`topbar-button ${isActive ? '' : 'inactive'}`}
                    onClick={() =>
                      isActive && handleNavigation(permissionRoutes[key])
                    }
                    disabled={!isActive}
                  >
                    {permissionLabels[key] || 'Unknown'}
                    {!isActive && (
                      <FontAwesomeIcon
                        icon={faLock}
                        className="topbar-button-icon red-icon"
                      />
                    )}
                  </button>
                )}
                
        
                {key === 'radiographer' && isRadiographerDropdownOpen && (
                  <div className="dropdown-menu">
                    <button onClick={handleRadiologyNavigation} className="dropdown-item">
                      Pending Radiology Exams
                    </button>
                  </div>
                )}
        
                {key === 'cashier' && isCashierDropdownOpen && (
                  <div className="dropdown-menu">
                    <button onClick={handleSalesPageNavigation} className="dropdown-item">
                      Sales Page
                    </button>
                    <button onClick={handleAwaitingPaymentsNavigation} className="dropdown-item">
                      Pending Payments
                    </button>
                  </div>
                )}
        
                {key === 'doctor' && isDoctorDropdownOpen && (
                  <div className="dropdown-menu">
                    <button onClick={handleAttendToPatientNavigation} className="dropdown-item">
                      Attend to Patient
                    </button>
                    <button onClick={handleViewExistingFilesNavigation} className="dropdown-item">
                      Existing Files
                    </button>
                  </div>
                )}
        
                {key === 'lab' && isLabDropdownOpen && (
                  <div className="dropdown-menu">
                    <button onClick={handlePendingLabTestsNavigation} className="dropdown-item">
                      Pending Lab Tests
                    </button>
                  </div>
                )}
                {key === 'nurse' && isNurseDropdownOpen && (
          <div className="dropdown-menu">
            <button onClick={handlePatientFilesNavigation} className="dropdown-item">
              Patient Files
            </button>
          </div>
        )}
            {key === 'drug-shelves' && isDispensaryDropdownOpen && (
          <div className="dropdown-menu">
            <button onClick={handleNonSaleStockRemovalNavigation} className="dropdown-item">
              Non Sale Stock Removal
            </button>
            <button onClick={handleSoldDrugsOverviewNavigation} className="dropdown-item">
              Sold Drugs Overview
            </button>
            <button onClick={handleNonSoldRemovedDrugsOverviewNavigation} className="dropdown-item">
              Non Sold Removed Drugs Overview
            </button>
          </div>
        )}
      </div>
    )
  );
})}
      {dataAvailable && (
        <div className="performance-section" onClick={handlePerformanceClick}>
          <h3 style={{ fontSize: '14px' }}>{selectedEmployee}</h3>

          <div className="stars">
  {Array.from({ length: 5 }).map((_, index) => (
    <svg
      key={index}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={`star-icon ${index < message ? 'filled' : 'empty'}`}
    >
      <path
        d="M12 2.5l3.09 6.26L22 9.27l-5 4.87L18.18 22 12 18.77 5.82 22 7 14.14l-5-4.87 6.91-1.01L12 2.5z"
      />
    </svg>
  ))}
</div>


        </div>
        
      )}
      <button 
  className="topbar-button logout" 
  onClick={handleLogout} 
  style={{ display: 'none' }}
>
  <FontAwesomeIcon icon={faSignOutAlt} className="logout-icon" />
  Logout
</button>
{displayingReminder && appointments.length > 0 && (
    <div className="reminder" key={appointments[currentAppointmentIndex].appointment_id}>
        <FontAwesomeIcon 
            icon={faTimes} 
            className="close-icon" 
            onClick={() => setDisplayingReminder(false)} 
        />
        
        <div className="navigation">
            {/* Left Arrow */}
            <button 
                className="arrow-button left-arrow" 
                onClick={() => setCurrentAppointmentIndex((prevIndex) => 
                    prevIndex > 0 ? prevIndex - 1 : appointments.length - 1 // Loop to the last reminder if on the first
                )}
            >
                ←
            </button>

            <p>
                <strong>Reminder:</strong> Please contact the client, <strong>{appointments[currentAppointmentIndex].first_name} {appointments[currentAppointmentIndex].last_name}</strong>.
                <br />
                <strong>Reason:</strong> {appointments[currentAppointmentIndex].appointment_reason}
                <br />
                <strong>Message:</strong> {appointments[currentAppointmentIndex].appointment_message}
                <br />
                <strong>Patients Contact:</strong> {appointments[currentAppointmentIndex].phone_number}
                <br />
                <strong>Date of Appointment:</strong> {appointments[currentAppointmentIndex].date_of_appointment}
            </p>

            {/* Right Arrow */}
            <button 
                className="arrow-button right-arrow" 
                onClick={() => setCurrentAppointmentIndex((prevIndex) => 
                    (prevIndex + 1) % appointments.length // Loop to the first reminder if on the last
                )}
            >
                →
            </button>
        </div>

        <button onClick={handleReminderClick}>
            Click here if the reminder was already made
        </button>

        {totalReminders > 1 && (
            <p>{totalReminders - 1} more reminders left.</p>
        )}
    </div>
)}


    </div>
    
    
  );
}

export default Topbar;
