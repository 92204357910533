import React, { useEffect, useState, useRef  } from 'react';
import { urls } from './config.dev';  // Import the backend URLs
import './AntenatalPrompt.css'; // Import the external CSS file
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ObstetricHistoryPrompt from './ObstetricHistoryPrompt';
import AntenatalProgressPrompt from './AntenatalProgressPrompt';
import PartogramPrompt from './PartogramPrompt';



const AntenatalPrompt = (props) => {
   // Log all props data
   console.log("All props data in AntenatalPrompt:", props);
  const [loading, setLoading] = useState(true); // Correct placement of useState
 
  const intervalRef = useRef(null);

  const [formData, setFormData] = useState({
    name: `${props.first_name} ${props.last_name}`,
    age: props.age || '',
    address: props.address || '',
    phoneNumber: props.phone_number || '',
    clinicName: props.clinicName || '',
    religion: '',
    medicalHistory: { 
      rheumaticFever: false,
      cardiacDisease: false,
      kidneyDisease: false,
      hypertension: false,
      tuberculosis: false,
      asthma: false,
      sexuallyTransmittedDiseases: false,
      sickleCellDisease: false,
      epilepsy: false,
      diabetes: false
    },
    pelvicExam: { 
      vulva: '',
      vagina: '',
      cervix: '',
      moniliasis: '' // For moniliasis description
    },
    familyHistory: { 
      diabetes: false,
      hypertension: false,
      sickleCellDisease: false,
      epilepsy: false,
      twins: false // For maternal family side twins
    },
    obgynHistory: { 
      ectopicPregnancy: false,
      dAndC: false,  // D&C
      cesareanSection: false,  // Cesarean Section
      vacuumExtraction: false,  // Vacuum Extraction
      forcepsExtraction: false,  // Forceps Extraction
      retainedPlacenta: false,  // Retained Placenta
      pph: false  // Postpartum Hemorrhage
    },
    surgicalHistory: {  // New Surgical History section
      operations: false,
      bloodTransfusion: false,
      skeletalDeformity: false,
      pelvisFemurFeatures: false  // For typing feature
    },
    socialHistory: { 
      smoking: '',
      alcohol: '',
      healthOfHusband: '' // For typed input on husband's health
    },
    presentPregnancy: { 
      firstDayOfLNMP: '',  // First day of LNMP
      EDD: '',  // Estimated Due Date
      periodOfGestation: '',  // Period of gestation
      hospitalizationDetails: '',  // Hospitalization details if applicable
      bleeding: '',  // Any bleeding
      vomiting: '',  // Any vomiting
      feversCoughFluWeightLoss: ''  // Any fevers, cough, flu, weight loss, or other disease
    },
    menstrualHistory: { 
      cycleLength: '',  // Length of menses
      amountOfFlow: '',  // Amount of flow (dropdown)
      familyPlanningUsed: '',  // Any family planning used (text input)
      neverUsedFamilyPlanningReason: ''  // Reason for not using family planning
    },
    physicalExam: { 
      height: '',
      temperature: '',
      weight: '',
      pulse: '',
      oralThrush: '',
      teeth: '',
      neck: '',
      breasts: '',
      legs: '',
      deformities: '',
      lymphGlands: '',
      herpesZoster: '',
      nutritionalStatus: '',
      anaemia: '',
      eyes: '',
      mucousMembranes: '',
      nails: '',
      palms: '',
      heart: '',
      lungs: ''
    },
    otherPersonalDetails: {
      occupation: '',
      education: '',
      tribe: '',
      maritalStatus: '',  // Single, Married, Widow
      nextOfKin: '',  // Name of next of kin
      relationshipWithNextOfKin: '',  // Relationship with next of kin
      nextOfKinOccupation: '',  // Occupation of next of kin
      nextOfKinPhone: '',  // Phone number of next of kin
      deliveryLocation: '',  // Where will the mother deliver
      postDeliveryLocation: '',  // Where will she go after delivery
      gravida: '',  // Number of pregnancies
      para: '',  // Number of live births
      abortions: '',  // Number of abortions
      bloodGroup: '',  // Blood group
      rhesus: '',  // Rhesus factor
    },
  });
 
  useEffect(() => { 
    const fetchAntenatalDetails = async () => {
      try {
        // Fetch antenatal details with maternity_id
        const response = await fetch(urls.AntenatalDetails, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ maternity_id: props.maternity_id }),
        });
  
        if (response.ok) {
          const data = await response.json();
          
          // Check if data is empty or no results were found
          if (data.message && data.message === 'No data found for the provided Maternity ID') {
            // Populate with initial present data if no data found
            setFormData((prevData) => ({
              ...prevData,
            }));
          } else {
            // Manually set each individual field to match the response data
            setFormData((prevData) => ({
              ...prevData,
              medicalHistory: {
                ...prevData.medicalHistory,
                diabetes: data.medicalHistory.diabetes,
                hypertension: data.medicalHistory.hypertension,
                sickleCellDisease: data.medicalHistory.sickleCellDisease,
                epilepsy: data.medicalHistory.epilepsy,
             
                rheumaticFever: data.medicalHistory.rheumaticFever,
                cardiacDisease: data.medicalHistory.cardiacDisease,
                kidneyDisease: data.medicalHistory.kidneyDisease,
                asthma: data.medicalHistory.asthma,
                sexuallyTransmittedDiseases: data.medicalHistory.sexuallyTransmittedDiseases,
                tuberculosis: data.medicalHistory.tuberculosis,
              },
              pelvicExam: {
                ...prevData.pelvicExam,
                vulva: data.pelvicExam.vulva,
                vagina: data.pelvicExam.vagina,
                cervix: data.pelvicExam.cervix,
                moniliasis: data.pelvicExam.moniliasis,
              },
              familyHistory: {
                ...prevData.familyHistory,
                diabetes: data.familyHistory.diabetes,
                hypertension: data.familyHistory.hypertension,
                sickleCellDisease: data.familyHistory.sickleCellDisease,
                epilepsy: data.familyHistory.epilepsy,
                twins: data.familyHistory.twins === 1, // Map twins field to boolean
              },
              obgynHistory: {
                ...prevData.obgynHistory,
                ectopicPregnancy: data.obgynHistory.ectopicPregnancy,
                dAndC: data.obgynHistory.dAndC,
                cesareanSection: data.obgynHistory.cesareanSection,
                vacuumExtraction: data.obgynHistory.vacuumExtraction,
                forcepsExtraction: data.obgynHistory.forcepsExtraction,
                retainedPlacenta: data.obgynHistory.retainedPlacenta,
                pph: data.obgynHistory.pph,
              },
              surgicalHistory: {
                ...prevData.surgicalHistory,
                operations: data.surgicalHistory.operations,
                bloodTransfusion: data.surgicalHistory.bloodTransfusion,
                skeletalDeformity: data.surgicalHistory.skeletalDeformity,
                pelvisFemurFeatures: data.surgicalHistory.pelvisFemurFeatures,
              },
              socialHistory: {
                ...prevData.socialHistory,
                smoking: data.socialHistory.smoking,
                alcohol: data.socialHistory.alcohol,
                healthOfHusband: data.socialHistory.healthOfHusband,
              },
              presentPregnancy: {
                ...prevData.presentPregnancy,
                firstDayOfLNMP: data.presentPregnancy.firstDayOfLNMP,
                EDD: data.presentPregnancy.EDD,
                periodOfGestation: data.presentPregnancy.periodOfGestation,
                hospitalizationDetails: data.presentPregnancy.hospitalizationDetails,
                bleeding: data.presentPregnancy.bleeding,
                vomiting: data.presentPregnancy.vomiting,
                feversCoughFluWeightLoss: data.presentPregnancy.feversCoughFluWeightLoss,
              },
              menstrualHistory: {
                ...prevData.menstrualHistory,
                cycleLength: data.menstrualHistory.cycleLength,
                amountOfFlow: data.menstrualHistory.amountOfFlow,
                familyPlanningUsed: data.menstrualHistory.familyPlanningUsed,
                neverUsedFamilyPlanningReason: data.menstrualHistory.neverUsedFamilyPlanningReason,
              },
              physicalExam: {
                ...prevData.physicalExam,
                height: data.physicalExam.height,
                weight: data.physicalExam.weight,
                temperature: data.physicalExam.temperature,
                pulse: data.physicalExam.pulse,
                oralThrush: data.physicalExam.oralThrush,
                teeth: data.physicalExam.teeth,
                neck: data.physicalExam.neck,
                breasts: data.physicalExam.breasts,
                eyes: data.physicalExam.eyes,
                nails: data.physicalExam.nails,
                mucousMembranes: data.physicalExam.mucousMembranes,
                palms: data.physicalExam.palms,
                lymphGlands: data.physicalExam.lymphGlands,
                nutritionalStatus: data.physicalExam.nutritionalStatus,
                anaemia: data.physicalExam.anaemia,
                heart: data.physicalExam.heart,
                lungs: data.physicalExam.lungs,
                deformities: data.physicalExam.deformities,
                legs: data.physicalExam.legs,
                herpesZoster: data.physicalExam.herpesZoster,
              },
              otherPersonalDetails: {
                ...prevData.otherPersonalDetails,
                occupation: data.otherPersonalDetails.occupation,
                education: data.otherPersonalDetails.education,
                tribe: data.otherPersonalDetails.tribe,
                maritalStatus: data.otherPersonalDetails.maritalStatus,
                nextOfKin: data.otherPersonalDetails.nextOfKin,
                relationshipWithNextOfKin: data.otherPersonalDetails.relationshipWithNextOfKin,
                nextOfKinOccupation: data.otherPersonalDetails.nextOfKinOccupation,
                nextOfKinPhone: data.otherPersonalDetails.nextOfKinPhone,
                deliveryLocation: data.otherPersonalDetails.deliveryLocation,
                postDeliveryLocation: data.otherPersonalDetails.postDeliveryLocation,
                gravida: data.otherPersonalDetails.gravida,
                para: data.otherPersonalDetails.para,
                abortions: data.otherPersonalDetails.abortions,
                bloodGroup: data.otherPersonalDetails.bloodGroup,
                rhesus: data.otherPersonalDetails.rhesus,
              },
            }));
          }
        } else {
          console.error('Error fetching antenatal details: ', response.statusText);
          setLoading(false);
        }
      } catch (error) {
        console.error('Error fetching antenatal details:', error);
        setLoading(false);
      }
    };
  
    fetchAntenatalDetails();
  }, [props.maternity_id]);
  
  
    // Store the received props data in a state variable ANCdetails
  const [ANCdetails, setANCdetails] = useState({
    first_name: props.first_name,
    last_name: props.last_name,
    age: props.age,
    address: props.address,
    phone_number: props.phone_number,
    clinicName: props.clinicName,
    employeeName: props.employeeName,
    maternity_id: props.maternity_id,
    status: props.status,
    style: props.style,
    tokenFromUrl: props.tokenFromUrl
  });

  // Log ANCdetails whenever it changes
  useEffect(() => {
    console.log("ANCdetails state data:", ANCdetails);
  }, [ANCdetails]);  // Runs when ANCdetails state changes

  const handleChange = (section, field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [section]: { ...prevData[section], [field]: value },
    }));
  };

  const handleSubmit = async () => {
    try {
        // Prepare the main payload (excluding socialHistory)
        const mainPayload = {
            familyHistory: formData.familyHistory,
            medicalHistory: formData.medicalHistory,
            menstrualHistory: formData.menstrualHistory,
            obgynHistory: formData.obgynHistory,
            otherPersonalDetails: formData.otherPersonalDetails,
            pelvicExam: formData.pelvicExam,
            physicalExam: formData.physicalExam,
            presentPregnancy: formData.presentPregnancy,
            surgicalHistory: formData.surgicalHistory,
            maternity_id: props.maternity_id,
            token: props.tokenFromUrl,
        };

        // Submit main payload
        const mainResponse = await fetch(urls.submitantenataldetails, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(mainPayload),
        });

        if (!mainResponse.ok) {
            toast.error('Failed to submit antenatal details.');
            return;
        }

        // Prepare the payload for socialHistory
        const socialHistoryPayload = {
            socialHistory: formData.socialHistory,
            maternity_id: props.maternity_id,
            token: props.tokenFromUrl,
        };

        // Submit socialHistory
        const socialHistoryResponse = await fetch(urls.updateSocialHistory, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(socialHistoryPayload),
        });

        if (!socialHistoryResponse.ok) {
            toast.error('Failed to submit social history.');
            return;
        }

        // Make a call to urls.ANCpoints1
        const ancPointsPayload = {
            token: props.tokenFromUrl,
            maternity_id: props.maternity_id,
        };

        const ancPointsResponse = await fetch(urls.ANCpoints1, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(ancPointsPayload),
        });

        // Log the response from urls.ANCpoints1
        const ancPointsData = await ancPointsResponse.json();
        console.log('Response from ANCpoints1:', ancPointsData);

        // Show success toast after all requests succeed
        toast.success('Antenatal details updated successfully!');
    } catch (error) {
        toast.error('Error submitting antenatal details.');
        console.error('Error submitting antenatal details:', error);
    }
};

  useEffect(() => {
    // Start interval to submit the form every 10 seconds
    intervalRef.current = setInterval(() => {
      handleSubmit();
    }, 100000);
  
    // Cleanup interval on component unmount
    return () => clearInterval(intervalRef.current);
  }, [formData, props.maternity_id, props.tokenFromUrl]);
  
  const handleClose = () => {
    if (props.onClose) {
      props.onClose();
    }
  };
  const [activePrompt, setActivePrompt] = useState(null); // Tracks which prompt is active

  const handleButtonClick = (promptType) => {
    setActivePrompt(promptType);
  };
  const handleClosePrompt = () => {
    setActivePrompt(null); // Close the active prompt
  };
  const closeButtonStyle = {
    fontSize: '16px',
    padding: '10px 20px',
    backgroundColor: '#f44336',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  };
  return (
    <div className="antenatal-card-container" style={{ width: '100%' }}>
      {/* Close Button */}
 
      <ToastContainer />
      
  
      {/* Button Group */}
      <div className="button-group">
        <button
          className="prompt-button"
          onClick={() => handleButtonClick('obstetricHistory')}
        >
          Obstetric History
        </button>
        <button
          className="prompt-button"
          onClick={() => handleButtonClick('antenatalProgress')}
        >
          Antenatal Progress Examination
        </button>
        <button
          className="prompt-button"
          onClick={() => handleButtonClick('partogram')}
        >
          Partogram
        </button>
      </div>

  {/* Dynamic Prompts with onClose */}
  {activePrompt === 'obstetricHistory' && (
  <ObstetricHistoryPrompt
    {...props} // Spread all props
    onClose={(data) => {
      console.log("Data sent from ObstetricHistoryPrompt:", data); // Log the data
      handleClosePrompt(data); // Pass onClose
    }}
  />
)}
 {activePrompt === 'antenatalProgress' && (
  <AntenatalProgressPrompt
    first_name={ANCdetails.first_name}
    last_name={ANCdetails.last_name}
    age={ANCdetails.age}
    address={ANCdetails.address}
    phone_number={ANCdetails.phone_number}
    clinicName={ANCdetails.clinicName}
    employeeName={ANCdetails.employeeName}
    maternity_id={ANCdetails.maternity_id}
    status={ANCdetails.status}
    style={ANCdetails.style}
    tokenFromUrl={ANCdetails.tokenFromUrl}
    onClose={(data) => {
      // Log the data that is passed to AntenatalProgressPrompt
      console.log("Data being sent to AntenatalProgressPrompt:", ANCdetails);
      console.log("Data sent from AntenatalProgressPrompt (onClose handler):", data); // Data received when prompt is closed
      handleClosePrompt(data); // Handle onClose
    }}
  />
)}
{activePrompt === 'partogram' && (
  <PartogramPrompt
    first_name={ANCdetails.first_name}
    last_name={ANCdetails.last_name}
    age={ANCdetails.age}
    address={ANCdetails.address}
    phone_number={ANCdetails.phone_number}
    clinicName={ANCdetails.clinicName}
    employeeName={ANCdetails.employeeName}
    maternity_id={ANCdetails.maternity_id}
    status={ANCdetails.status}
    style={ANCdetails.style}
    tokenFromUrl={ANCdetails.tokenFromUrl}
    onClose={(data) => {
      console.log("Data being sent to PartogramPrompt:", ANCdetails); // Log ANCdetails
      console.log("Data sent from PartogramPrompt:", data); // Log onClose data
      handleClosePrompt(data); // Handle onClose
    }}
  />
)}

  {/* Title */}
<h2 
  className="form-title" 
  style={{
    fontSize: '32px', 
    fontFamily: 'Roboto, sans-serif', // Changed to a modern, clean font
    fontWeight: 'bold', 
    color: '#000', 
    textAlign: 'center',
    marginTop: '30px', // Added space on top
    marginBottom: '20px', // Added space at the bottom
  }}
>
  Antenatal Card
</h2>
<button
          onClick={handleClose}
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            padding: "5px 10px",
            backgroundColor: "transparent",
            color: "#dc3545",
            border: "none",
            fontSize: "20px",
            cursor: "pointer",
          }}
        >
          &times;
        </button>
{/* Patient Information */}
<section
  style={{
    padding: '20px',
    width: '100%',
    backgroundColor: '#f9f9f9',
    border: '1px solid #ccc',
    borderRadius: '0px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  }}
>
  


  <h3
    style={{
      width: '100%',
      textAlign: 'center',
      fontSize: '24px',
      fontWeight: 'bold',
      marginBottom: '20px',
      color: '#333',
    }}
  >
    Patient Information
  </h3>
  <div style={{ width: '48%', marginBottom: '15px' }}>
    <label
      style={{
        display: 'block',
        fontWeight: 'bold',
        marginBottom: '5px',
        color: '#555',
      }}
    >
      Name
    </label>
    <input
      style={{
        width: '100%',
        padding: '10px',
        fontSize: '16px',
        border: '1px solid #ccc',
        borderRadius: '5px',
        backgroundColor: '#e9ecef',
        cursor: 'not-allowed',
        boxSizing: 'border-box',
      }}
      placeholder="Name"
      value={formData.name}
      readOnly
    />
  </div>
  <div style={{ width: '48%', marginBottom: '15px' }}>
    <label
      style={{
        display: 'block',
        fontWeight: 'bold',
        marginBottom: '5px',
        color: '#555',
      }}
    >
      Age
    </label>
    <input
      style={{
        width: '100%',
        padding: '10px',
        fontSize: '16px',
        border: '1px solid #ccc',
        borderRadius: '5px',
        backgroundColor: '#e9ecef',
        cursor: 'not-allowed',
        boxSizing: 'border-box',
      }}
      placeholder="Age"
      value={formData.age}
      readOnly
    />
  </div>
  <div style={{ width: '48%', marginBottom: '15px' }}>
    <label
      style={{
        display: 'block',
        fontWeight: 'bold',
        marginBottom: '5px',
        color: '#555',
      }}
    >
      Address
    </label>
    <input
      style={{
        width: '100%',
        padding: '10px',
        fontSize: '16px',
        border: '1px solid #ccc',
        borderRadius: '5px',
        backgroundColor: '#e9ecef',
        cursor: 'not-allowed',
        boxSizing: 'border-box',
      }}
      placeholder="Address"
      value={formData.address}
      readOnly
    />
  </div>
  <div style={{ width: '48%', marginBottom: '15px' }}>
    <label
      style={{
        display: 'block',
        fontWeight: 'bold',
        marginBottom: '5px',
        color: '#555',
      }}
    >
      Religion
    </label>
    <input
      style={{
        width: '100%',
        padding: '10px',
        fontSize: '16px',
        border: '1px solid #ccc',
        borderRadius: '5px',
        backgroundColor: '#e9ecef',
        cursor: 'not-allowed',
        boxSizing: 'border-box',
      }}
      placeholder="Religion"
      value={formData.religion}
      readOnly
    />
  </div>
  <div style={{ width: '48%', marginBottom: '15px' }}>
    <label
      style={{
        display: 'block',
        fontWeight: 'bold',
        marginBottom: '5px',
        color: '#555',
      }}
    >
      Phone Number
    </label>
    <input
      style={{
        width: '100%',
        padding: '10px',
        fontSize: '16px',
        border: '1px solid #ccc',
        borderRadius: '5px',
        backgroundColor: '#e9ecef',
        cursor: 'not-allowed',
        boxSizing: 'border-box',
      }}
      placeholder="Phone Number"
      value={formData.phoneNumber}
      readOnly
    />
  </div>
</section>

<section className="section" style={{ padding: '20px', border: '1px solid #ccc', borderRadius: '0px', backgroundColor: '#fafafa', boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',  width: '100%' }}>
  <h3 className="heading" style={{ fontSize: '1.5em', color: '#333', marginBottom: '15px' }}>Other Personal Details</h3>
  
  {[
    { label: 'Occupation', field: 'occupation', placeholder: 'Describe occupation' },
    { label: 'Education', field: 'education', placeholder: 'Describe education level' },
    { label: 'Tribe', field: 'tribe', placeholder: 'Describe tribe' },
    { label: 'Marital Status (Single, Married, Widow)', field: 'maritalStatus', placeholder: 'Describe marital status' },
    { label: 'Next of Kin', field: 'nextOfKin', placeholder: 'Name of next of kin' },
    { label: 'Relationship with Next of Kin', field: 'relationshipWithNextOfKin', placeholder: 'Describe relationship with next of kin' },
    { label: 'Next of Kin\'s Occupation', field: 'nextOfKinOccupation', placeholder: 'Occupation of next of kin' },
    { label: 'Next of Kin\'s Phone Number', field: 'nextOfKinPhone', placeholder: 'Phone number of next of kin' },
    { label: 'Where Will You Deliver', field: 'deliveryLocation', placeholder: 'Describe where you will deliver' },
    { label: 'Where Will You Go After Delivery', field: 'postDeliveryLocation', placeholder: 'Where will you go after delivery' },
    { label: 'Gravida (Number of Pregnancies)', field: 'gravida', placeholder: 'Enter number of pregnancies' },
    { label: 'Para (Number of Live Births)', field: 'para', placeholder: 'Enter number of live births' },
    { label: 'Abortions', field: 'abortions', placeholder: 'Enter number of abortions' },
    { label: 'Blood Group', field: 'bloodGroup', placeholder: 'Enter blood group' },
    { label: 'Rhesus Factor', field: 'rhesus', placeholder: 'Enter rhesus factor' },
  ].map(({ label, field, placeholder }) => (
    <label key={field} className="input-label" style={{ display: 'block', marginBottom: '15px' }}>
      <span style={{ display: 'block', marginBottom: '5px', color: '#555', fontWeight: 'bold' }}>{label}:</span>
      <textarea
        value={formData.otherPersonalDetails[field]}
        onChange={(e) => handleChange('otherPersonalDetails', field, e.target.value)}
        placeholder={placeholder}
        style={{
          width: '100%',
          padding: '10px',
          border: '1px solid #ccc',
          borderRadius: '4px',
          fontFamily: 'Arial, sans-serif',
          fontSize: '1rem',
        }}
      />
    </label>
  ))}

 
</section>

<section
  className="section"
  style={{
    padding: '30px',
    border: '1px solid #e0e0e0',
    borderRadius: '0px',
    backgroundColor: '#fafafa',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
    width: '100%',
    margin: '0', // Ensure it fills the entire screen width
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    
  }}
>
  <h3
    className="heading"
    style={{
      color: '#2c3e50',
      marginBottom: '20px',
      fontSize: '1.6em',
      fontWeight: '600',
      textAlign: 'left', // Align heading to the left
      textTransform: 'uppercase',
      paddingLeft: '20px', // Add some padding to the left for better spacing
    }}
  >
    Previous Illness
  </h3>

  <div
    className="checkbox-container"
    style={{
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: '20px', // Align the content uniformly
    }}
  >
    {Object.keys(formData.medicalHistory).map((condition) => (
      <label
        key={condition}
        className="checkbox"
        style={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: '12px',
          fontSize: '1rem',
          color: '#34495e',
        }}
      >
        <input
          type="checkbox"
          checked={formData.medicalHistory[condition]}
          onChange={(e) => handleChange('medicalHistory', condition, e.target.checked)}
          style={{
            accentColor: '#e74c3c',
            marginRight: '12px',
            width: '18px',
            height: '18px',
          }}
        />
        <span
          className="checkbox-label"
          style={{
            fontFamily: 'Arial, sans-serif',
            fontWeight: '400',
            fontSize: '1rem',
            color: '#34495e',
          }}
        >
          {condition.split(/(?=[A-Z])/).join(' ')}
        </span>
      </label>
    ))}
  </div>

 
</section>


<section
  className="section"
  style={{
    padding: '30px',
    border: '1px solid #e0e0e0',
    borderRadius: '0px',
    backgroundColor: '#fafafa',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
    width: '100%',
    margin: '0', // Ensures the section spans the full width
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
  }}
>
  <h3
    className="heading"
    style={{
      color: '#2c3e50',
      marginBottom: '20px',
      fontSize: '1.6em',
      fontWeight: '600',
      textAlign: 'left', // Left-align heading for better readability
      textTransform: 'uppercase',
      paddingLeft: '20px', // Align with other elements
    }}
  >
    OB/GYN History
  </h3>

  <div
    className="checkbox-container"
    style={{
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: '20px', // Ensures alignment with the heading
    }}
  >
    {Object.keys(formData.obgynHistory).map((condition) => (
      <label
        key={condition}
        className="checkbox"
        style={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: '12px',
          fontSize: '1rem',
          color: '#34495e',
        }}
      >
        <input
          type="checkbox"
          checked={formData.obgynHistory[condition]}
          onChange={(e) => handleChange('obgynHistory', condition, e.target.checked)}
          style={{
            accentColor: '#e74c3c', // Red checkbox accent color
            marginRight: '12px',
            width: '18px',
            height: '18px',
          }}
        />
        <span
          className="checkbox-label"
          style={{
            fontFamily: 'Arial, sans-serif',
            fontWeight: '400',
            fontSize: '1rem',
            color: '#34495e',
          }}
        >
          {condition.split(/(?=[A-Z])/).join(' ')} {/* Converts camelCase to readable words */}
        </span>
      </label>
    ))}
  </div>

 
</section>

<section
  className="section"
  style={{
    padding: '30px',
    border: '1px solid #e0e0e0',
    borderRadius: '0px',
    backgroundColor: '#fafafa',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
    width: '100%',
    margin: '0', // Ensures the section spans the full width
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
  }}
>
  <h3
    className="heading"
    style={{
      color: '#2c3e50',
      marginBottom: '20px',
      fontSize: '1.6em',
      fontWeight: '600',
      textAlign: 'left', // Left-align heading for better readability
      textTransform: 'uppercase',
      paddingLeft: '20px', // Align with other elements
    }}
  >
    Surgical History
  </h3>

  <div
    className="checkbox-container"
    style={{
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: '20px', // Ensures alignment with the heading
    }}
  >
    {Object.keys(formData.surgicalHistory).map((condition) => (
      <label
        key={condition}
        className="checkbox"
        style={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: '12px',
          fontSize: '1rem',
          color: '#34495e',
        }}
      >
        <input
          type="checkbox"
          checked={formData.surgicalHistory[condition]}
          onChange={(e) => handleChange('surgicalHistory', condition, e.target.checked)}
          style={{
            accentColor: '#e74c3c', // Red checkbox accent color
            marginRight: '12px',
            width: '18px',
            height: '18px',
          }}
        />
        <span
          className="checkbox-label"
          style={{
            fontFamily: 'Arial, sans-serif',
            fontWeight: '400',
            fontSize: '1rem',
            color: '#34495e',
          }}
        >
          {condition.split(/(?=[A-Z])/).join(' ')} {/* Converts camelCase to readable words */}
        </span>
      </label>
    ))}
  </div>

 
</section>


{/* Social History */}
<section
  className="section"
  style={{
    padding: '30px',
    border: '1px solid #e0e0e0',
    borderRadius: '00px',
    backgroundColor: '#fafafa',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
    width: '100%',
    margin: '0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
  }}
>
  <h3
    className="heading"
    style={{
      color: '#2c3e50',
      marginBottom: '20px',
      fontSize: '1.6em',
      fontWeight: '600',
      textAlign: 'left',
      textTransform: 'uppercase',
      paddingLeft: '20px', // Align with other elements
    }}
  >
    Social History
  </h3>

  <div
    className="checkbox-container"
    style={{
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: '20px', // Align with other elements
    }}
  >
    <label
      className="checkbox"
      style={{
        display: 'flex',
        alignItems: 'center',
        marginBottom: '12px',
        fontSize: '1rem',
        color: '#34495e',
      }}
    >
      <input
        type="checkbox"
        checked={formData.socialHistory.smoking}
        onChange={(e) => handleChange('socialHistory', 'smoking', e.target.checked)}
        style={{
          accentColor: '#e74c3c', // Red checkbox accent color
          marginRight: '12px',
          width: '18px',
          height: '18px',
        }}
      />
      <span
        className="checkbox-label"
        style={{
          fontFamily: 'Arial, sans-serif',
          fontWeight: '400',
          fontSize: '1rem',
          color: '#34495e',
        }}
      >
        Smoking
      </span>
    </label>

    <label
      className="checkbox"
      style={{
        display: 'flex',
        alignItems: 'center',
        marginBottom: '12px',
        fontSize: '1rem',
        color: '#34495e',
      }}
    >
      <input
        type="checkbox"
        checked={formData.socialHistory.alcohol}
        onChange={(e) => handleChange('socialHistory', 'alcohol', e.target.checked)}
        style={{
          accentColor: '#e74c3c', // Red checkbox accent color
          marginRight: '12px',
          width: '18px',
          height: '18px',
        }}
      />
      <span
        className="checkbox-label"
        style={{
          fontFamily: 'Arial, sans-serif',
          fontWeight: '400',
          fontSize: '1rem',
          color: '#34495e',
        }}
      >
        Alcohol
      </span>
    </label>
  </div>

  <div
    className="textarea-container"
    style={{
      paddingLeft: '20px', // Align with other elements
      marginBottom: '20px',
    }}
  >
    <label
      className="input-label"
      style={{
        fontSize: '1rem',
        color: '#34495e',
        fontWeight: '500',
      }}
    >
      Health of Husband:
    </label>
    <textarea
      value={formData.socialHistory.healthOfHusband}
      onChange={(e) => handleChange('socialHistory', 'healthOfHusband', e.target.value)}
      placeholder="Describe health of husband"
      style={{
        width: '100%',
        padding: '10px',
        marginTop: '8px',
        fontSize: '1rem',
        borderRadius: '5px',
        border: '1px solid #ddd',
        fontFamily: 'Arial, sans-serif',
        color: '#34495e',
        boxSizing: 'border-box',
      }}
    />
  </div>

  
</section>

<section
  className="section"
  style={{
    padding: '30px',
    border: '1px solid #e0e0e0',
    borderRadius: '0px',
    backgroundColor: '#fafafa',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
    width: '100%',
    margin: '0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
  }}
>
  <h3
    className="heading"
    style={{
      color: '#000000',
      marginBottom: '10px',
      fontSize: '1.2em',
      fontWeight: '600',
      textAlign: 'left', // Aligns the heading to the left for readability
      textTransform: 'uppercase',
      paddingLeft: '20px',
    }}
  >
    Family History
  </h3>

  <div
    className="checkbox-container"
    style={{
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: '20px', // Aligning checkboxes with the heading
    }}
  >
    {Object.keys(formData.familyHistory).map((condition) => (
      <label
        key={condition}
        className="checkbox"
        style={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: '10px',
          fontSize: '1rem',
          color: '#34495e',
        }}
      >
        <input
          type="checkbox"
          checked={formData.familyHistory[condition]}
          onChange={(e) => handleChange('familyHistory', condition, e.target.checked)}
          style={{
            accentColor: 'red', // Change checkbox color to red when checked
            marginRight: '8px', // Space between checkbox and label
            width: '18px',
            height: '18px',
          }}
        />
        <span
          className="checkbox-label"
          style={{
            fontFamily: 'Arial, sans-serif',
            fontWeight: '400',
            fontSize: '1rem',
            color: 'black',
          }}
        >
          {condition.split(/(?=[A-Z])/).join(' ')} {/* Converts camelCase to readable words */}
        </span>
      </label>
    ))}
  </div>

  

</section>

      {/* Menstrual and Contraceptive History */}
      <section
  className="section"
  style={{
    padding: '30px',
    border: '1px solid #e0e0e0',
    borderRadius: '0px',
    backgroundColor: '#fafafa',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
    width: '100%',
    margin: '0', // Ensures the section spans the full width
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
  }}
>
  <h3
    className="heading"
    style={{
      color: '#2c3e50',
      marginBottom: '20px',
      fontSize: '1.6em',
      fontWeight: '600',
      textAlign: 'left', // Left-align heading for better readability
      textTransform: 'uppercase',
      paddingLeft: '20px', // Align with other elements
    }}
  >
    Menstrual and Contraceptive History
  </h3>

  <label
    className="input-label"
    style={{
      display: 'block',
      marginBottom: '16px',
      fontSize: '1rem',
      color: '#34495e',
      paddingLeft: '20px', // Align with other elements
    }}
  >
    Length of Menses (Number of Days):
    <input
      type="text"
      value={formData.menstrualHistory.cycleLength}
      onChange={(e) => handleChange('menstrualHistory', 'cycleLength', e.target.value)}
      placeholder="Enter the number of days and/or description"
      style={{
        marginLeft: '10px',
        padding: '8px',
        borderRadius: '5px',
        border: '1px solid #e0e0e0',
        width: '100%',
        fontSize: '1rem',
      }}
    />
  </label>

  <label
    className="input-label"
    style={{
      display: 'block',
      marginBottom: '16px',
      fontSize: '1rem',
      color: '#34495e',
      paddingLeft: '20px',
    }}
  >
    Amount of Flow:
    <select
      value={formData.menstrualHistory.amountOfFlow}
      onChange={(e) => handleChange('menstrualHistory', 'amountOfFlow', e.target.value)}
      style={{
        marginLeft: '10px',
        padding: '8px',
        borderRadius: '0px',
        border: '1px solid #e0e0e0',
        width: '100%',
        fontSize: '1rem',
      }}
    >
      <option value="">Select Amount of Flow</option>
      <option value="noMenses">No Menses At All</option>
      <option value="normal">Normal</option>
      <option value="mild">Mild</option>
      <option value="moderate">Moderate</option>
      <option value="heavy">Heavy</option>
    </select>
  </label>

  <label
    className="input-label"
    style={{
      display: 'block',
      marginBottom: '16px',
      fontSize: '1rem',
      color: '#34495e',
      paddingLeft: '20px',
    }}
  >
    Any Family Planning Ever Used:
    <textarea
      value={formData.menstrualHistory.familyPlanningUsed}
      onChange={(e) => handleChange('menstrualHistory', 'familyPlanningUsed', e.target.value)}
      placeholder="Describe if any family planning was ever used"
      style={{
        marginLeft: '10px',
        padding: '8px',
        borderRadius: '5px',
        border: '1px solid #e0e0e0',
        width: '100%',
        height: '80px',
        fontSize: '1rem',
      }}
    />
  </label>

  {formData.menstrualHistory.familyPlanningUsed === '' && (
    <label
      className="input-label"
      style={{
        display: 'block',
        marginBottom: '16px',
        fontSize: '1rem',
        color: '#34495e',
        paddingLeft: '20px',
      }}
    >
      If Never Used Any Family Planning, Describe Why:
      <textarea
        value={formData.menstrualHistory.neverUsedFamilyPlanningReason}
        onChange={(e) => handleChange('menstrualHistory', 'neverUsedFamilyPlanningReason', e.target.value)}
        placeholder="Describe why family planning was never used"
        style={{
          marginLeft: '10px',
          padding: '8px',
          borderRadius: '5px',
          border: '1px solid #e0e0e0',
          width: '100%',
          height: '80px',
          fontSize: '1rem',
        }}
      />
    </label>
  )}

 
   
</section>

      {/* Present Pregnancy */}
      <section
  className="section"
  style={{
    padding: '30px',
    border: '1px solid #e0e0e0',
    borderRadius: '0px',
    backgroundColor: '#fafafa',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
    width: '100%',
    margin: '0', // Ensures the section spans the full width
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
  }}
>
  <h3
    className="heading"
    style={{
      color: '#2c3e50',
      marginBottom: '20px',
      fontSize: '1.6em',
      fontWeight: '600',
      textAlign: 'left', // Left-align heading for better readability
      textTransform: 'uppercase',
      paddingLeft: '20px', // Align with other elements
    }}
  >
    Present Pregnancy
  </h3>

  {['firstDayOfLNMP', 'EDD', 'periodOfGestation'].map((field) => (
    <label
      key={field}
      className="input-label"
      style={{
        marginBottom: '16px',
        fontSize: '1rem',
        color: '#34495e',
        paddingLeft: '20px', // Align with heading and other elements
      }}
    >
      {field === 'firstDayOfLNMP' && 'First Day of LNMP (Last Normal Menstrual Period):'}
      {field === 'EDD' && 'EDD (Estimated Due Date):'}
      {field === 'periodOfGestation' && 'Period of Gestation:'}
      <input
        type="text"
        value={formData.presentPregnancy[field]}
        onChange={(e) => handleChange('presentPregnancy', field, e.target.value)}
        placeholder={`Enter ${field.split(/([A-Z])/).join(' ').toLowerCase()}`}
        style={{
          marginTop: '8px',
          padding: '8px',
          fontSize: '1rem',
          border: '1px solid #ccc',
          borderRadius: '5px',
          width: '100%',
        }}
      />
    </label>
  ))}

  {['hospitalizationDetails', 'bleeding', 'vomiting', 'feversCoughFluWeightLoss'].map((field) => (
    <label
      key={field}
      className="input-label"
      style={{
        marginBottom: '16px',
        fontSize: '1rem',
        color: '#34495e',
        paddingLeft: '20px',
      }}
    >
      {field === 'hospitalizationDetails' && 'Has the Mother Ever Been Hospitalized with This Pregnancy? (If yes, provide details):'}
      {field === 'bleeding' && 'Any Bleeding:'}
      {field === 'vomiting' && 'Any Vomiting:'}
      {field === 'feversCoughFluWeightLoss' && 'Any Fevers, Cough, Flu, Weight Loss, or Any Other Disease:'}
      <textarea
        value={formData.presentPregnancy[field]}
        onChange={(e) => handleChange('presentPregnancy', field, e.target.value)}
        placeholder={`Describe ${field.split(/([A-Z])/).join(' ').toLowerCase()}`}
        style={{
          marginTop: '8px',
          padding: '8px',
          fontSize: '1rem',
          border: '1px solid #ccc',
          borderRadius: '5px',
          width: '100%',
          height: '80px',
        }}
      />
    </label>
  ))}

 
</section>

    
     {/* Physical Examination Section */}
<section
  className="section"
  style={{
    padding: '30px',
    border: '0px solid #e0e0e0',
    borderRadius: '10px',
    backgroundColor: '#fafafa',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
    width: '100%',
    margin: '0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
  }}
>
  <h3
    className="heading"
    style={{
      color: '#2c3e50',
      marginBottom: '20px',
      fontSize: '1.6em',
      fontWeight: '600',
      textAlign: 'left',
      textTransform: 'uppercase',
      paddingLeft: '20px',
    }}
  >
    Physical Examination
  </h3>

  {['height', 'temperature', 'weight', 'pulse', 'oralThrush', 'teeth', 'neck', 'breasts', 'legs', 'deformities', 'lymphGlands', 'herpesZoster', 'nutritionalStatus', 'anaemia', 'eyes', 'mucousMembranes', 'nails', 'palms', 'heart', 'lungs'].map((field) => (
    <label
      key={field}
      className="input-label"
      style={{
        marginBottom: '15px',
        fontSize: '1rem',
        color: '#34495e',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {field.split(/(?=[A-Z])/).join(' ')}:
      {['height', 'temperature', 'weight', 'pulse'].includes(field) ? (
        <input
          type="text"
          value={formData.physicalExam[field]}
          onChange={(e) => handleChange('physicalExam', field, e.target.value)}
          placeholder={`Enter ${field.split(/(?=[A-Z])/).join(' ').toLowerCase()}`}
          style={{
            marginTop: '8px',
            padding: '8px',
            border: '1px solid #e0e0e0',
            borderRadius: '5px',
            fontSize: '1rem',
            color: '#34495e',
          }}
        />
      ) : (
        <textarea
          value={formData.physicalExam[field]}
          onChange={(e) => handleChange('physicalExam', field, e.target.value)}
          placeholder={`Describe ${field.split(/(?=[A-Z])/).join(' ').toLowerCase()}`}
          style={{
            marginTop: '8px',
            padding: '8px',
            border: '1px solid #e0e0e0',
            borderRadius: '5px',
            fontSize: '1rem',
            color: '#34495e',
            minHeight: '80px',
            resize: 'vertical',
          }}
        />
      )}
    </label>
  ))}

 
</section>

{/* Pelvic Examination Section */}
<section
  className="section"
  style={{
    padding: '30px',
    border: '1px solid #e0e0e0',
    borderRadius: '0px',
    backgroundColor: '#fafafa',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
    width: '100%',
    margin: '0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
  }}
>
  <h3
    className="heading"
    style={{
      color: '#2c3e50',
      marginBottom: '20px',
      fontSize: '1.6em',
      fontWeight: '600',
      textAlign: 'left',
      textTransform: 'uppercase',
      paddingLeft: '20px',
    }}
  >
    Pelvic Examination
  </h3>

  {['vulva', 'vagina', 'cervix', 'moniliasis'].map((field) => (
    <label
      key={field}
      className="input-label"
      style={{
        marginBottom: '15px',
        fontSize: '1rem',
        color: '#34495e',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {field.split(/(?=[A-Z])/).join(' ')}:
      <textarea
        value={formData.pelvicExam[field]}
        onChange={(e) => handleChange('pelvicExam', field, e.target.value)}
        placeholder={`Describe ${field.split(/(?=[A-Z])/).join(' ').toLowerCase()}`}
        style={{
          marginTop: '8px',
          padding: '8px',
          border: '1px solid #e0e0e0',
          borderRadius: '5px',
          fontSize: '1rem',
          color: '#34495e',
          minHeight: '80px',
          resize: 'vertical',
        }}
      />
    </label>
  ))}

 
</section>

<button
  className="submit-button"
  onMouseOver={(e) => e.target.style.backgroundColor = '#45a049'}
  onMouseOut={(e) => e.target.style.backgroundColor = '#4CAF50'}
  onClick={handleSubmit}
  style={{
    transition: 'transform 0.1s ease-in-out',
    backgroundColor: '#4CAF50', // Initial color
  }}
  onMouseDown={(e) => e.target.style.transform = 'scale(0.98)'} // Scale down on press
  onMouseUp={(e) => e.target.style.transform = 'scale(1)'} // Return to normal size
>
  Submit Antenatal Details
</button>


      {/* Close button at the bottom */}
      <button type="button" style={closeButtonStyle} onClick={handleClose}>Close</button>
    </div>
  );
};
export default AntenatalPrompt;
