import React, { useState, useEffect } from 'react';
import './Statistics.css'; // Your CSS styles for the Statistics page
import { urls } from './config.dev';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify'; // Import ToastContainer and toast
import 'react-toastify/dist/ReactToastify.css'; // Import styles for react-toastify
import AnnualAsessment from './AnnualAsessment';
import { useLocation } from "react-router-dom"; // To access the URL

function getTokenFromUrlOrLocalStorage() {
  const urlParams = new URLSearchParams(window.location.search);
  const tokenFromUrl = urlParams.get('token');
  return tokenFromUrl || localStorage.getItem('token');
}

function Statistics() {
  const navigate = useNavigate();
  const [month, setMonth] = useState(new Date().getMonth() + 1); // Default to current month
  const [year, setYear] = useState(new Date().getFullYear()); // Default to current year
  const [statisticsData, setStatisticsData] = useState(null);
  const [token, setToken] = useState(null); // Declare 'token' and 'setToken' using useState

  const [loading, setLoading] = useState(false); // Loading state
  const [isAnnualAsessmentOpen, setIsAnnualAsessmentOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    // Extract the token from the URL query parameters
    const params = new URLSearchParams(location.search);
    const urlToken = params.get("token");
    setToken(urlToken); // Save the token in state
  }, [location]);


  

    const openAnnualAsessment = () => {
        setIsAnnualAsessmentOpen(true);
    };

    
    const closeAnnualAsessment = () => {
        setIsAnnualAsessmentOpen(false);
    };


  const performSecurityCheck = async (token) => {
    try {
      const securityResponse = await fetch(urls.security, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token }),
      });

      if (securityResponse.ok) {
        const securityData = await securityResponse.json();
        if (securityData.message === 'Session valid') {
          fetchPerformanceData(token);
        } else if (securityData.error === 'Session expired') {
          navigate(`/dashboard?token=${securityData.clinic_session_token}`);
        } else {
          navigate('/login');
        }
      } else {
        throw new Error('Failed to perform security check');
      }
    } catch (error) {
      console.error('Error performing security check:', error);
      navigate('/login');
    }
  };

  const fetchPerformanceData = async (token) => {
    try {
      setLoading(true); // Set loading state to true when the request starts
      const payload = {
        token,
        month: month,
        year: year,
      };

      const response = await fetch(`${urls.statistics}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const data = await response.json();
        if (data.message === "No data found for the requested month and year.") {
          toast.error('No data found for the requested month and year.'); // Show toast notification
        } else {
          setStatisticsData(data);
        }
      } else {
        throw new Error('Failed to fetch statistics data');
      }
    } catch (error) {
      console.error('Error fetching statistics datas:', error);
      toast.error('Error fetching statistics data! It might be that the data you are trying to fetch dosent exist or try checking your internet connection');
    } finally {
      setLoading(false); // Set loading state to false when the request is finished
    }
  };

  useEffect(() => {
    const token = getTokenFromUrlOrLocalStorage();
    performSecurityCheck(token);
  }, [month, year]);

  const calculateTotals = () => {
    if (!statisticsData) return;

    let totalDayShiftSales = 0;
    let totalNightShiftSales = 0;
    let totalDayShiftExpenses = 0;
    let totalNightShiftExpenses = 0;
    let totalCostOfProduction = 0;

    statisticsData.sales.forEach(({ DayShiftSales, NightShiftSales }) => {
      totalDayShiftSales += parseFloat(DayShiftSales || 0); // Consider null as 0
      totalNightShiftSales += parseFloat(NightShiftSales || 0); // Consider null as 0
    });

    statisticsData.expenses.forEach(({ DayShiftExpenses, NightShiftExpenses }) => {
      totalDayShiftExpenses += parseFloat(DayShiftExpenses || 0); // Consider null as 0
      totalNightShiftExpenses += parseFloat(NightShiftExpenses || 0); // Consider null as 0
    });

    statisticsData.cop.forEach(({ TotalCOP }) => {
      totalCostOfProduction += parseFloat(TotalCOP || 0); // Consider null as 0
    });

    const totalSales = totalDayShiftSales + totalNightShiftSales;
    const totalExpenses = totalDayShiftExpenses + totalNightShiftExpenses;
    const estimatedProfit = totalSales - totalCostOfProduction;

    const lastDate = statisticsData.sales[statisticsData.sales.length - 1].ShiftDate;
    const daysWithData = parseInt(lastDate.split('-')[2]);

    const averageSales = totalSales / daysWithData;
    const averageExpenses = totalExpenses / daysWithData;
    const averageProfit = estimatedProfit / daysWithData;
    const averageCostOfProduction = totalCostOfProduction / daysWithData;

    const roundToNearestHundred = (num) => Math.round(num / 100) * 100;

    return {
      totalDayShiftSales: roundToNearestHundred(totalDayShiftSales),
      totalNightShiftSales: roundToNearestHundred(totalNightShiftSales),
      totalSales: roundToNearestHundred(totalSales),
      totalDayShiftExpenses: roundToNearestHundred(totalDayShiftExpenses),
      totalNightShiftExpenses: roundToNearestHundred(totalNightShiftExpenses),
      totalExpenses: roundToNearestHundred(totalExpenses),
      totalCostOfProduction: roundToNearestHundred(totalCostOfProduction),
      estimatedProfit: roundToNearestHundred(estimatedProfit),
      averageSales: roundToNearestHundred(averageSales),
      averageExpenses: roundToNearestHundred(averageExpenses),
      averageProfit: roundToNearestHundred(averageProfit),
      averageCostOfProduction: roundToNearestHundred(averageCostOfProduction),
      totalCostOfDrugsUsed: roundToNearestHundred(totalCostOfProduction - totalExpenses),
    };
  };

  const totals = calculateTotals();

  const handleMonthChange = (e) => {
    setMonth(e.target.value);
  };

  const handleYearChange = (e) => {
    setYear(e.target.value);
  };

  const handlePrint = async () => {
    const payload = {
      token,
      month: month,
      year: year,
    };
  
    try {
      const response = await fetch(`${urls.printstatistics}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
  
      if (response.ok) {
        // Convert response to a Blob
        const blob = await response.blob();
        const url = URL.createObjectURL(blob); // Create an object URL from the Blob
        window.open(url, '_blank'); // Open the Blob in a new window
      } else {
        console.error('Failed to fetch statistics:', response.statusText); // Handle non-OK response
      }
    } catch (error) {
      console.error('Error generating PDF:', error); // Handle any fetch or blob conversion errors
    }
  };
  

  return (
    <div className="statistics-page">
      <ToastContainer /> {/* Add ToastContainer to render the toasts */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between", // Align the heading on the left and the selection on the right
          alignItems: "center", // Ensure vertical alignment is consistent
          padding: "10px",
        }}
      >
        <h1 style={{ textTransform: 'uppercase', fontSize: '1.9em' }}>
          Statistics for {new Date(year, month - 1).toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}
        </h1>
        <div
          className="month-year-selection"
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            padding: "10px",
            border: "1px solid #ddd",
            borderRadius: "5px",
            backgroundColor: "#f9f9f9",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            maxWidth: "300px",
            margin: "0", // Remove extra margin here
          }}
        >
          <select
            value={month}
            onChange={handleMonthChange}
            style={{
              padding: "8px",
              border: "1px solid #ccc",
              borderRadius: "5px",
              fontSize: "16px",
              flexGrow: 1,
            }}
          >
            <option value={1}>January</option>
            <option value={2}>February</option>
            <option value={3}>March</option>
            <option value={4}>April</option>
            <option value={5}>May</option>
            <option value={6}>June</option>
            <option value={7}>July</option>
            <option value={8}>August</option>
            <option value={9}>September</option>
            <option value={10}>October</option>
            <option value={11}>November</option>
            <option value={12}>December</option>
          </select>
          <input
            type="number"
            value={year}
            onChange={handleYearChange}
            style={{
              padding: "8px",
              border: "1px solid #ccc",
              borderRadius: "5px",
              fontSize: "16px",
              width: "80px",
              textAlign: "center",
            }}
          />
        </div>
      </div>
  
      {loading ? (
        <div>Loading...</div> // Show loading state
      ) : statisticsData ? (
        <div>
         
  
          <table className="statistics-table">
            <thead>
              <tr>
                <th>Date</th>
                <th>Day Shift Sales</th>
                <th>Night Shift Sales</th>
                <th>Day Shift Expenses</th>
                <th>Night Shift Expenses</th>
                <th>Cost of Production</th>
              </tr>
            </thead>
            <tbody>
              {statisticsData.sales.map(({ ShiftDate, DayShiftSales, NightShiftSales }, index) => {
                const dayShiftExpenses = statisticsData.expenses.find(exp => exp.ShiftDate === ShiftDate)?.DayShiftExpenses || 0;
                const nightShiftExpenses = statisticsData.expenses.find(exp => exp.ShiftDate === ShiftDate)?.NightShiftExpenses || 0;
                const totalCOP = statisticsData.cop.find(cop => cop.date === ShiftDate)?.TotalCOP || 0;
  
                return (
                  <tr key={index}>
                    <td>{ShiftDate}</td>
                    <td>{parseFloat(DayShiftSales || 0).toFixed(0)}</td>
                    <td>{parseFloat(NightShiftSales || 0).toFixed(0)}</td>
                    <td>{parseFloat(dayShiftExpenses).toFixed(0)}</td>
                    <td>{parseFloat(nightShiftExpenses).toFixed(0)}</td>
                    <td>{parseFloat(totalCOP).toFixed(0)}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
  
          <div
            style={{
              fontFamily: "Arial, sans-serif",
              margin: "0 auto",
              padding: "20px",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              backgroundColor: "#f8f9fa",
              boxSizing: "border-box",
            }}
          >
            {/* Individual Section Container */}
            <div
              style={{
                flex: "0 1 calc(48% - 20px)", // Two sections per row with spacing
                margin: "10px",
                padding: "20px",
                backgroundColor: "#fff",
                border: "1px solid #ddd",
                borderRadius: "10px",
                boxShadow: "0px 2px 4px rgba(0,0,0,0.1)",
                boxSizing: "border-box",
              }}
            >
              <h2
                style={{
                  textAlign: "center",
                  color: "#333",
                  marginBottom: "20px",
                  textTransform: "uppercase",
                }}
              >
                Sales Analysis
              </h2>
              <p><strong>Total sales during day shift:</strong> UGX {totals.totalDayShiftSales}</p>
              <p><strong>Total sales during night shift:</strong> UGX {totals.totalNightShiftSales}</p>
              <p><strong>Total sales for both shifts:</strong> UGX {totals.totalSales}</p>
              <p><strong>Average sales:</strong> UGX {totals.averageSales}</p>
              <p style={{ color: "#28a745", fontWeight: "bold" }}>
                This means your medical center sells approximately UGX {totals.averageSales} per day.
              </p>
            </div>
  
            <div
              style={{
                flex: "0 1 calc(48% - 20px)",
                margin: "10px",
                padding: "20px",
                backgroundColor: "#fff",
                border: "1px solid #ddd",
                borderRadius: "10px",
                boxShadow: "0px 2px 4px rgba(0,0,0,0.1)",
                boxSizing: "border-box",
              }}
            >
              <h2
                style={{
                  textAlign: "center",
                  color: "#333",
                  marginBottom: "20px",
                  textTransform: "uppercase",
                }}
              >
                Expenses Analysis
              </h2>
              <p><strong>Total day shift expenses:</strong> UGX {totals.totalDayShiftExpenses}</p>
              <p><strong>Total night shift expenses:</strong> UGX {totals.totalNightShiftExpenses}</p>
              <p><strong>Total expenses for both shifts:</strong> UGX {totals.totalExpenses}</p>
              <p><strong>Average expenses:</strong> UGX {totals.averageExpenses}</p>
              <p style={{ color: "#dc3545", fontWeight: "bold" }}>
                This means your medical center spends approximately UGX {totals.averageExpenses} per day.
              </p>
            </div>
  
            <div
              style={{
                flex: "0 1 calc(48% - 20px)",
                margin: "10px",
                padding: "20px",
                backgroundColor: "#fff",
                border: "1px solid #ddd",
                borderRadius: "10px",
                boxShadow: "0px 2px 4px rgba(0,0,0,0.1)",
                boxSizing: "border-box",
              }}
            >
              <h2
                style={{
                  textAlign: "center",
                  color: "#333",
                  marginBottom: "20px",
                  textTransform: "uppercase",
                }}
              >
                Cost of Production
              </h2>
              <p><strong>Total cost of drugs used:</strong> UGX {totals.totalCostOfDrugsUsed}</p>
              <p><strong>Total expenses:</strong> UGX {totals.totalExpenses}</p>
              <p><strong>Total cost of production:</strong> UGX {totals.totalCostOfProduction}</p>
              <p><strong>Average cost of production:</strong> UGX {totals.averageCostOfProduction}</p>
              <p style={{ color: "#17a2b8", fontWeight: "bold" }}>
                This means your medical center's average cost of production is approximately UGX {totals.averageCostOfProduction} per day.
              </p>
            </div>
  
            <div
              style={{
                flex: "0 1 calc(48% - 20px)",
                margin: "10px",
                padding: "20px",
                backgroundColor: "#fff",
                border: "1px solid #ddd",
                borderRadius: "10px",
                boxShadow: "0px 2px 4px rgba(0,0,0,0.1)",
                boxSizing: "border-box",
              }}
            >
              <h2
                style={{
                  textAlign: "center",
                  color: "#333",
                  marginBottom: "20px",
                  textTransform: "uppercase",
                }}
              >
                Profit Analysis
              </h2>
              <p><strong>Total profit:</strong> UGX {totals.estimatedProfit}</p>
              <p><strong>Average profit:</strong> UGX {totals.averageProfit}</p>
              <p style={{ color: "#28a745", fontWeight: "bold" }}>
                This means your medical center's average profit is approximately UGX {totals.averageProfit} per day.
              </p>
            </div>
            <button
  onClick={(e) => {
    e.preventDefault(); // Ensures proper behavior for touch devices
    openAnnualAsessment();
  }}
  style={{
    backgroundColor: "green", // Green background
    color: "white", // White text color
    border: "none", // No border
    padding: "10px 15px", // Padding
    borderRadius: "5px", // Rounded corners
    cursor: "pointer", // Pointer cursor
    flex: "1", // Make button flexible
    zIndex: 1, // Ensure button is on top of other elements
    touchAction: "manipulation", // Optimize touch behavior
  }}
>
  Annual Assessment
</button>

<button
  onClick={(e) => {
    e.preventDefault(); // Ensures proper behavior for touch devices
    handlePrint();
  }}
  style={{
    backgroundColor: "blue", // Blue background
    color: "white", // White text color
    border: "none", // No border
    padding: "10px 15px", // Padding
    borderRadius: "5px", // Rounded corners
    cursor: "pointer", // Pointer cursor
    flex: "1", // Make button flexible
    marginLeft: "10px", // Space between buttons
    zIndex: 1, // Ensure button is on top of other elements
    touchAction: "manipulation", // Optimize touch behavior
  }}
>
  Print
</button>


{isAnnualAsessmentOpen && (
<AnnualAsessment

token={token} // Pass token to the modal
onClose={closeAnnualAsessment} // Pass the onClose function
/>
)}


          </div>
        </div>
      ) : (
        <div>No data available</div>
      )}
    </div>
  );
}  
export default Statistics;
