import React, { useState, useEffect } from 'react';
import './UpdateStocksModal.css'; // Import CSS file for styling
import { urls } from './config.dev';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import toastify styles

function UpdateStocksModal({ isOpen, onClose, selectedDrug, token, refreshDrugs }) {
  const [drugDetails, setDrugDetails] = useState({
    drugName: '',
    packaging: '',
    warningPoint: '',
    costPrice: '',
    sellingPrice: '',
  });
  const [dispensaryStock, setDispensaryStock] = useState(0);
  const [storeStock, setStoreStock] = useState(0);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isUpdatingStock, setIsUpdatingStock] = useState(false);

  useEffect(() => {
    if (selectedDrug) {
      setDrugDetails({
        drugName: selectedDrug.drug_name,
        packaging: selectedDrug.packaging,
        warningPoint: selectedDrug.warning_point,
        costPrice: selectedDrug.cost_price,
        sellingPrice: selectedDrug.selling_price,
      });

      // Fetch initial drug quantities
      fetch(urls.fetchdrugquantity, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          drugName: selectedDrug.drug_name,
          packaging: selectedDrug.packaging,
          token,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          setDispensaryStock(data.dispensaryStock || 0);
          setStoreStock(data.storeStock || 0);
        })
        .catch((error) =>
          toast.error('Error fetching drug quantities: ' + error.message)
        );
    }
  }, [selectedDrug, token]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDrugDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleStockChange = (e) => {
    const { name, value } = e.target;
    if (name === 'dispensaryStock') {
      setDispensaryStock(value);
    } else if (name === 'storeStock') {
      setStoreStock(value);
    }
  };

  const handleUpdateDrug = () => {
    setIsUpdating(true);

    if (
      !drugDetails.drugName ||
      !drugDetails.packaging ||
      !drugDetails.warningPoint ||
      !drugDetails.costPrice ||
      !drugDetails.sellingPrice
    ) {
      toast.error('Please fill in all fields.');
      setIsUpdating(false);
      return;
    }

    const payload = {
      oldDetails: {
        drugName: selectedDrug.drug_name,
        packaging: selectedDrug.packaging,
        warningPoint: selectedDrug.warning_point,
        costPrice: selectedDrug.cost_price,
        sellingPrice: selectedDrug.selling_price,
      },
      newDetails: { ...drugDetails },
      token,
    };

    fetch(urls.updatedrugdetails, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          toast.success('Drug details updated successfully!');
          refreshDrugs(); // Refresh the list of drugs
        } else {
          toast.error(`Error: ${data.message || 'Failed to update drug details'}`);
        }
      })
      .catch((error) => {
        toast.error('An unexpected error occurred. Please try again later.');
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };

  const handleUpdateStock = () => {
    setIsUpdatingStock(true);
  
    // Include costPrice and sellingPrice in the payload
    fetch(urls.updatestockfigures, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        drugName: drugDetails.drugName,
        packaging: drugDetails.packaging,
        token,
        dispensaryStock,
        storeStock,
        costPrice: drugDetails.costPrice, // Added costPrice
        sellingPrice: drugDetails.sellingPrice, // Added sellingPrice
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          toast.success('Stock figures and prices updated successfully!');
        } else {
          toast.error(`Error: ${data.message || 'Failed to update stock figures and prices'}`);
        }
      })
      .catch((error) => {
        toast.error('An unexpected error occurred. Please try again later.');
      })
      .finally(() => {
        setIsUpdatingStock(false);
      });
  };
  
  const isDetailsChanged = () => {
    return (
      drugDetails.drugName !== selectedDrug.drug_name ||
      drugDetails.packaging !== selectedDrug.packaging ||
      drugDetails.warningPoint !== selectedDrug.warning_point ||
      drugDetails.costPrice !== selectedDrug.cost_price ||
      drugDetails.sellingPrice !== selectedDrug.selling_price
    );
  };

  const isStockChanged = () => {
    return (
      dispensaryStock !== (selectedDrug?.dispensaryStock || 0) ||
      storeStock !== (selectedDrug?.storeStock || 0)
    );
  };

  return (
    isOpen && (
      <div className="update-stocks-modal-overlay">
        <div className="update-stocks-modal-content">
          <FontAwesomeIcon
            icon={faTimes}
            className="update-stocks-close-icon"
            onClick={onClose}
          />
          <h2>Update Drug Stock</h2>
          <div className="update-stocks-modal-body">
            {/* Form Inputs */}
            <div className="update-stocks-form-group">
              <label htmlFor="drugName">Drug Name</label>
              <input
                type="text"
                id="drugName"
                name="drugName"
                className="update-stocks-form-input"
                placeholder="Drug Name"
                value={drugDetails.drugName}
                onChange={handleInputChange}
              />
            </div>
            <div className="update-stocks-form-group">
              <label htmlFor="packaging">Packaging</label>
              <select
                id="packaging"
                name="packaging"
                className="update-stocks-form-select"
                value={drugDetails.packaging}
                onChange={handleInputChange}
              >
                <option value="">Select Packaging</option>
                <option value="Tablets">Tablets</option>
                <option value="Capsules">Capsules</option>
                <option value="Syrups">Syrups</option>
                <option value="Syringes">Syringes</option>
                <option value="Rolls">Rolls</option>
                <option value="Bottles">Bottles</option>
                <option value="Cannulas">Cannulas</option>
                <option value="Strips">Strips</option>
                <option value="Packets">Packets</option>
                <option value="Vials">Vials</option>
                <option value="Ampules">Ampules</option>
                <option value="Pieces">Pieces</option>
                <option value="Sackets">Sackets</option>
              </select>
            </div>
            <div className="update-stocks-form-group">
              <label htmlFor="warningPoint">Minimum Point</label>
              <input
                type="number"
                id="warningPoint"
                name="warningPoint"
                className="update-stocks-form-input"
                placeholder="Warning Point"
                value={drugDetails.warningPoint}
                onChange={handleInputChange}
              />
            </div>
            <div className="update-stocks-form-group">
              <label htmlFor="costPrice">Cost Price</label>
              <input
                type="number"
                id="costPrice"
                name="costPrice"
                className="update-stocks-form-input"
                placeholder="Cost Price"
                value={drugDetails.costPrice}
                onChange={handleInputChange}
              />
            </div>
            <div className="update-stocks-form-group">
              <label htmlFor="sellingPrice">Selling Price</label>
              <input
                type="number"
                id="sellingPrice"
                name="sellingPrice"
                className="update-stocks-form-input"
                placeholder="Selling Price"
                value={drugDetails.sellingPrice}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="update-stocks-modal-footer">
            <button
              onClick={handleUpdateDrug}
              disabled={!isDetailsChanged() || isUpdating}
              className="update-stocks-update-button"
            >
              {isUpdating ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Update Drug Details'}
            </button>
          </div>
          {/* Stock Info */}
          <div className="update-stocks-stock-info">
            <div className="update-stocks-form-group">
              <label htmlFor="dispensaryStock">Dispensary Stock</label>
              <input
                type="text"
                id="dispensaryStock"
                name="dispensaryStock"
                className="update-stocks-form-input"
                placeholder="Dispensary Stock"
                value={dispensaryStock}
                onChange={handleStockChange}
              />
            </div>
            <div className="update-stocks-form-group">
              <label htmlFor="storeStock">Store Stock</label>
              <input
                type="text"
                id="storeStock"
                name="storeStock"
                className="update-stocks-form-input"
                placeholder="Store Stock"
                value={storeStock}
                onChange={handleStockChange}
              />
            </div>
            <button
              onClick={handleUpdateStock}
              disabled={!isStockChanged() || isUpdatingStock}
              className="update-stocks-update-button"
            >
              {isUpdatingStock ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Update Stock Figures'}
            </button>
          </div>
        </div>
        <ToastContainer />
      </div>
    )
  );
}

export default UpdateStocksModal;
