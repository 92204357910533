import React, { useEffect, useState } from 'react';

import { urls } from './config.dev';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AntenatalProgressPrompt = ({
  first_name,
  last_name,
  age,
  address,
  phone_number,
  clinicName,
  employeeName,
  maternity_id,
  status,
  tokenFromUrl,
  onClose
}) => {
  const [tableData, setTableData] = useState([...Array(10)].map(() => Array(17).fill('')));

  const [investigations, setInvestigations] = useState({
    bloodHb: '',
    rprVdrl: '',
    mp: '',
    hiv: '',
    others: '',
    xRay: ''
  });
  const [pelvicAssessment, setPelvicAssessment] = useState({
    diagonalConjugate: '',
    sacralCurve: '',
    ischialSpines: '',
    subpubicArch: '',
    ischialTuberosities: '',
    pelvisAssessment: ''
  });
  const [ultrasoundReports, setUltrasoundReports] = useState('');
  const [riskFactors, setRiskFactors] = useState('');
  const [treatment, setTreatment] = useState('');

  const handleInputChange = (rowIndex, colIndex, value) => {
    const updatedData = [...tableData];
    updatedData[rowIndex][colIndex] = value;
    setTableData(updatedData);
  };

  const handleInvestigationChange = (field, value) => {
    setInvestigations(prevState => ({
      ...prevState,
      [field]: value
    }));
  };

 
  const handlePelvicAssessmentChange = (assessment, value) => {
    setPelvicAssessment(prevState => ({
      ...prevState,
      [assessment]: value
    }));
  };
  
  const handleTextAreaChange = (setter) => (event) => {
    setter(event.target.value);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch antenatal data
        const antenatalResponse = await fetch(
          `${urls.fetchAntenatalData}?maternity_id=${maternity_id}&token=${tokenFromUrl}`
        );
        const antenatalResult = await antenatalResponse.json();
        if (antenatalResult.success) {
          const data = antenatalResult.data || [];
          const rowsNeeded = Math.max(10 - data.length, 0); // Ensure 10 rows
          const emptyRows = [...Array(rowsNeeded)].map(() => Array(17).fill(''));
          setTableData([...data, ...emptyRows]); // Combine fetched data with empty rows
        } else {
          // Fallback to 10 empty rows if backend fails
          setTableData([...Array(10)].map(() => Array(17).fill('')));
        }
  
        // Fetch investigations data
        const investigationsResponse = await fetch(
          `${urls.fetchMaternityInvestigations}?maternity_id=${maternity_id}&token=${tokenFromUrl}`
        );
        const investigationsResult = await investigationsResponse.json();
        if (investigationsResult.success) {
          const fetchedInvestigations = investigationsResult.data.investigations || {}; // Ensure proper nesting
          setInvestigations((prevState) => ({
            ...prevState,
            ...fetchedInvestigations, // Merge fetched data with existing state
          }));
        }
  
        // Fetch pelvic assessment data
        const pelvicAssessmentResponse = await fetch(
          `${urls.fetchMaternityPelvis}?maternity_id=${maternity_id}&token=${tokenFromUrl}`
        );
        const pelvicAssessmentResult = await pelvicAssessmentResponse.json();
        if (pelvicAssessmentResult.success) {
          setPelvicAssessment(pelvicAssessmentResult.data.pelvicAssessment || {});
          setUltrasoundReports(pelvicAssessmentResult.data.ultrasoundReports || '');
          setRiskFactors(pelvicAssessmentResult.data.riskFactors || '');
          setTreatment(pelvicAssessmentResult.data.treatment || '');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
  
        // Ensure fallback to default state for all data on error
        setTableData([...Array(10)].map(() => Array(17).fill(''))); // Fallback to 10 empty rows
        setInvestigations({});
        setPelvicAssessment({});
        setUltrasoundReports('');
        setRiskFactors('');
        setTreatment('');
      }
    };
  
    fetchData();
  }, [maternity_id, tokenFromUrl]);
  
      
  const handleSubmit = async (event) => {
    event.preventDefault();
  
    setLoading(true); // Show spinner
    const filteredAntenatalData = tableData.filter(row => row.some(cell => cell !== ""));
    
    const antenatalDataPayload = {
      maternity_id,
      employeeName,
      clinicName,
      antenatalData: filteredAntenatalData,
      token: tokenFromUrl,
    };
  
    const investigationsPayload = {
      maternity_id,
      investigations,
      token: tokenFromUrl,
    };
  
    const pelvicAssessmentPayload = {
      maternity_id,
      pelvicAssessment,
      riskFactors,
      treatment,
      ultrasoundReports,
      token: tokenFromUrl,
    };
  
    try {
      const antenatalResponse = await fetch(urls.submitAntenatalData, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(antenatalDataPayload),
      });
      const antenatalResult = await antenatalResponse.json();
  
      const investigationsResponse = await fetch(urls.submitMaternityInvestigations, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(investigationsPayload),
      });
      const investigationsResult = await investigationsResponse.json();
  
      const pelvicAssessmentResponse = await fetch(urls.submitMaternityPelvis, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(pelvicAssessmentPayload),
      });
      const pelvicAssessmentResult = await pelvicAssessmentResponse.json();
  
      if (
        antenatalResult.success &&
        investigationsResult.success &&
        pelvicAssessmentResult.success
      ) {
        toast.success('Data submitted successfully');
      } else {
        toast.error('Error submitting data');
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Error submitting data');
    } finally {
      setLoading(false); // Hide spinner
    }
  };
  const [loading, setLoading] = useState(false);

  // Styles
// Styles
const overlayStyle = {
  position: 'fixed',
  top: '0',
  left: '0',
  width: '100vw',
  height: '100vh',
  backgroundColor: '#000', // Fully black background
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1000,
};

const containerStyle = {
  position: 'relative',
  backgroundColor: '#fff',
  padding: '30px',
  borderRadius: '8px',
  width: '95vw', // Increased width to cover more screen
  maxWidth: '1500px', // Adjusted maximum width
  height: '85vh', // Slightly taller modal
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',
};

const headingStyle = {
  textAlign: 'center',
  fontSize: '22px', // Slightly larger font for better visibility
  marginBottom: '20px',
  fontWeight: 'bold', // Emphasize heading
};


  const tableContainerStyle = {
    width: '100%',
    overflowX: 'auto',
    flex: 1,
  };

  const tableStyle = {
    width: '100%',
    borderCollapse: 'collapse',
    fontSize: '12px',
  };

  const headerStyle = {
    border: '1px solid #ccc',
    padding: '8px',
    textAlign: 'center',
    backgroundColor: '#f0f0f0',
    fontWeight: 'bold',
  };

  const cellStyle = {
    border: '1px solid #ccc',
    padding: '8px',
    textAlign: 'center',
  };

  const inputStyle = {
    width: '100%',
    fontSize: '12px',
    padding: '5px',
    boxSizing: 'border-box',
  };

  const commentsSectionStyle = {
    marginTop: '20px',
  };

  const textAreaStyle = {
    width: '100%',
    height: '100px',
    marginTop: '10px',
    fontSize: '12px',
    padding: '8px',
    boxSizing: 'border-box',
  };

  const buttonContainerStyle = {
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'space-between',
  };

  const buttonStyle = {
    fontSize: '14px',
    padding: '10px 20px',
    backgroundColor: '#4CAF50',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  };

  const closeButtonStyle = {
    fontSize: '14px',
    padding: '10px 20px',
    backgroundColor: '#f44336',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  };

  return (
    <div style={overlayStyle}>
      <div style={containerStyle}>
        <h2 style={headingStyle}>Antenatal Progress Examination - {first_name} {last_name}</h2>
        <button
          onClick={onClose}
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            padding: "5px 10px",
            backgroundColor: "transparent",
            color: "#dc3545",
            border: "none",
            fontSize: "20px",
            cursor: "pointer",
          }}
        >
          &times;
        </button>
        <form onSubmit={handleSubmit}>
          <div style={tableContainerStyle}>
            <table style={tableStyle}>
              <thead>
                <tr>
                  {[
                    'Date', 'Weeks of Amenorrhoea', 'Fundal Height', 'Presentation', 'Position /He',
                    'Relation PP/Brim', 'Foetal Heart', 'Weight', 'HP', 'Varience/Oedema', 'Urine', 
                    'TT', 'IPT', 'Net Use', 'Complaints and Remarks', 'Return Date', 'Name of Examiner'
                  ].map((header, index) => (
                    <th key={index} style={headerStyle}>{header}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {tableData.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {row.map((cell, colIndex) => (
                      <td key={colIndex} style={cellStyle}>
                        <input
                          type="text"
                          value={cell}
                          onChange={(e) => handleInputChange(rowIndex, colIndex, e.target.value)}
                          style={inputStyle}
                        />
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div style={commentsSectionStyle}>
            <h3>Investigations</h3>
            <div style={{ marginTop: '10px', fontStyle: 'italic', fontSize: '6px', color: 'red' }}>
              <p><strong>NB:</strong> In case you want to make a request for any investigations, please go to triage and make the lab request, or ultrasound scan request.</p>
            </div>
            {['bloodHb', 'rprVdrl', 'mp', 'hiv', 'others', 'xRay'].map((investigation, index) => (
             <div key={index}>
             <h4 style={{ textTransform: 'capitalize' }}>
               {investigation.replace(/([A-Z])/g, ' $1').trim()}
             </h4>
             <textarea
               style={textAreaStyle}
               placeholder={`Enter details for ${investigation.replace(/([A-Z])/g, ' $1').trim()}...`}
               value={investigations[investigation]}
               onChange={(e) => handleInvestigationChange(investigation, e.target.value)}
               rows="3"
             />
           </div>
           
            ))}

            <h3>Pelvic Assessment - Done at 36 weeks</h3>
            {['diagonalConjugate', 'sacralCurve', 'ischialSpines', 'subpubicArch', 'ischialTuberosities', 'pelvisAssessment'].map((assessment, index) => (
              <div key={index}>
                <h4>{assessment.replace(/([A-Z])/g, ' $1').trim()}</h4>
                <textarea
                  style={textAreaStyle}
                  placeholder={`Enter details for ${assessment.replace(/([A-Z])/g, ' $1').trim()}...`}
                  value={pelvicAssessment[assessment]}
                  onChange={(e) => handlePelvicAssessmentChange(assessment, e.target.value)}
                  rows="3"
                />
              </div>
            ))}

            <div>
              <h3>Ultra Sound Reports & Dates:</h3>
              <textarea
                style={textAreaStyle}
                rows="3"
                value={ultrasoundReports}
                onChange={handleTextAreaChange(setUltrasoundReports)}
              />
              <h3>Risk Factors:</h3>
              <textarea
                style={textAreaStyle}
                rows="3"
                value={riskFactors}
                onChange={handleTextAreaChange(setRiskFactors)}
              />
              <div style={{ marginTop: '10px', fontStyle: 'italic', fontSize: '6px', color: 'red' }}>
              <p><strong>NB:</strong> For any treatment given , please proceed to the dispensing shelves , click non sale stock removal and record the drugs u have given</p>
            </div>
              <h3>Treatment:</h3>
              <textarea
                style={textAreaStyle}
                rows="3"
                value={treatment}
                onChange={handleTextAreaChange(setTreatment)}
              />
            </div>
          </div>

          <div style={buttonContainerStyle}>
          <button type="submit" style={buttonStyle} onClick={handleSubmit} disabled={loading}>
      {loading ? (
        <FontAwesomeIcon icon={faSpinner} spin style={{ marginRight: '8px' }} />
      ) : (
        "Submit"
      )}
    </button>
            <button type="button" style={closeButtonStyle} onClick={onClose}>Close</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AntenatalProgressPrompt;
